import React, { Component } from 'react';

// import clientimage from '../../../assets/images/profile-man.png';

// const props = {
//     arrows: true,
//     dots: false,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 2,
//     slidesToScroll: 1,
// };

class SingleQuestionReply extends Component { 
    constructor(props) {
        super();
        this.state = {
            sizeSmall: 'small',
            sizeLarge: 'large',
        }
    }
    

    render () {

        return (
            <div className="block question reply">
                <div className="img-block">
                    {/* <img src={clientimage} className="profile-image" alt="Profile Image" /> */}
                </div>
                <div className="text-block">
                    <div className="name">
                        <div className="text">Erica Jensen</div>
                    </div>
                    <div className="text">It’s on 5 Sep, 2020.</div>
                </div>
                <div className="added-block">
                    <span>
                        <i class="ico clock-grey"></i>
                        <span class="num">3 hrs ago</span>
                    </span>
                </div>
            </div>
        )
    }
}

export default SingleQuestionReply;