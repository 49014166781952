import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

// add form components
import { Tabs, Form, Input, InputNumber, Button, Select, Row, Col, DatePicker, Tag } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

import FeatherIcon from 'feather-icons-react';

import Loader from '../../components/Loader';
import Api from '../../api/Api';

const { Option } = Select;

class SocialAccounts extends Component { 
    constructor(props) {
        super();
        this.state = {
            account_token: '',
            updated: false,
            user: false,
            redirect: false,
            count_employment: 0,
            fb_required: false,
            linkedin_required: false,
            github_required: false,
        }
    }

    componentDidMount = () => {
        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('account_usertoken');

        if(account_token != ''){
            this.setState({account_token: account_token});
        }else{
            this.setState({redirect: true});
        }

        if(user){
            this.setState({user: true});
        }
    }

    triggerFbLink = () => {
        document.getElementById('fb_link').classList.remove('hidden');
        this.setState({fb_required: true});
    }

    triggerLinkedinLink = () => {
        document.getElementById('linkedin_link').classList.remove('hidden');
        this.setState({linkedin_required: true});
    }

    triggerGithubLink = () => {
        document.getElementById('github_link').classList.remove('hidden');
        this.setState({github_required: true});
    }

    render () {
        // if(!this.state.redirect){
        //     return <Redirect to='/login'/>;
        // }

        if(this.state.updated){
            return <Redirect to='/freelancer/update-profile-5'/>;
        }

        const layout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
        };

        const tailLayout = {
            wrapperCol: { span: 24 },
        };

        const validateMessages = {
            required: '${label} is required!',
            types: {
              email: '${label} is not valid!',
              number: '${label} is not a valid number!',
            },
            number: {
              range: '${label} must be between ${min} and ${max}',
            },
        };

        const onFinish = values => {
            var formData = new FormData();
            var _fb_link = document.getElementById('fb_link').value;
            var _linkedin_link = document.getElementById('linkedin_link').value;
            var _github_link = document.getElementById('github_link').value;

            formData.append('account_token', this.state.account_token);
            formData.append('fb_link', _fb_link);
            formData.append('linkedin_link', _linkedin_link);
            formData.append('github_link', _github_link);
            Object.keys(values).forEach(key => formData.append(key, values[key]))
            
            var that = this;

            Api.post('freelancer/update_profile4', formData, function(data){
                
                if(data.status == true){
                
                    localStorage.setItem('account_token', data.account_token);
                    localStorage.setItem('user', JSON.stringify(data.user));
                    
                    window.setTimeout(() => {
                        that.setState({loading: false});
                        that.setState({updated: true});
                    }, 1000);
                
                }else{
                    that.setState({loading: false});
                    that.setState({error_message: data.message});
                }
                window.setTimeout(() => {
                    that.setState({error_message: false});
                }, 10000);
            });
        }

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        return (

            <div className="update-profile-page d-flex">
                    
                <div className="profile-wrapper">
                    <Row>
                        <Col span={24}>
                            
                            <div className="profile-main-wrapper">

                                <div className="page-heading">Social Accounts</div>

                                <Form {...layout} name="update_profile_form" initialValues={{ remember: true }} validateMessages={validateMessages} onFinish={onFinish} onFinishFailed={onFinishFailed}>
                                    {/* <div className="d-flex w-100 justify-content-between" style={{padding:20, border:'1px solid #ccc'}}>
                                        <label htmlFor="fb_link"><FeatherIcon icon="facebook" /> Facebook</label>
                                        <div>
                                            <input type="hidden" className="input hidden fb-link" id="fb_link" value={this.state.user.fb_link} />
                                            <Button type="primary" onClick={this.triggerFbLink}>Connect</Button>
                                        </div>
                                        
                                    </div> */}

                                    <div className="d-flex w-100 justify-content-between mt-3" style={{padding:20, border:'1px solid #ccc'}}>
                                        <label htmlFor="linkedin_link"><FeatherIcon icon="linkedin" /> LinkedIn</label>
                                        <div>
                                            <input type="hidden" className="input hidden linkedin-link" id="linkedin_link" value={this.state.user.linkedin_link} />
                                            <Button type="primary" onClick={this.triggerLinkedinLink}>Connect</Button>
                                        </div>
                                        
                                    </div>
                                </Form>
                            </div>
                        </Col>

                        <Col span={24}>

                            <div className="d-flex justify-content-end">
                                <Button className="btn ml-10 mt-2" type="primary" htmlType="button" onClick={() => {

                                    this.props.jumpToNext();
                                }}>
                                    Continue
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default SocialAccounts;