import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { Form, Input, InputNumber, Button, Radio, Rate, Checkbox, DatePicker, Tabs } from 'antd';

import FeatherIcon from 'feather-icons-react';

import Main from '../../components/Main';

class PostProjectStepOne extends Component { 
    constructor(props) {
        super();
        this.state = {
            feature: false,
            urgent: false,
            nda: false,
            scouting: false,

            selected_options: [],

            disable_button: true
        }
    }

    updateSelected = (event, key) => {

        if(event){
                                    
            var selected_options = this.state.selected_options;
            selected_options.push(key);
            this.setState({selected_options: selected_options});
        }else{

            var selected_options = this.state.selected_options;
            if(selected_options.indexOf(key) > -1){
                selected_options.splice(selected_options.indexOf(key), 1);
            }
            this.setState({selected_options: selected_options});
        }

        if(this.state.selected_options.length > 0){

            this.setState({disable_button: false});
        }else{

            this.setState({disable_button: true});
        }
    }

    render () {

        return (

            <div className="mt-1">
                <div className="page-heading">Add a few extras</div>
                <div className="page-subheading">Get higher quality with less effort</div>

                <div className="card mt-2">
                    <form className="">

                        <div className="justify-center" style={{alignItems:'flex-start'}}>
                            <div className="d-flex form-item-block border-block extra-item">
                                <Form.Item name="" valuePropName="checked">
                                    <Checkbox onChange={(e) => {

                                        this.updateSelected(e.target.checked, 'feature');
                                    }}>Feature your project <span className="btn btn-xs btn-rounded btn-blue-bg">Featured</span></Checkbox>
                                    <div className="note">Attract more high quality bids by making your project stand out on the listing of 24hrs!</div>
                                </Form.Item>

                                <div className="price">$20</div>
                            </div>
                            <div className="d-flex form-item-block border-block extra-item">
                                <Form.Item name="" valuePropName="checked">
                                    <Checkbox onChange={(e) => {
                                        this.updateSelected(e.target.checked, 'nda');
                                    }}>Request for NDA (Non disclosure aggrement) <span className="btn btn-xs btn-rounded btn-sky-blue-bg">NDA</span></Checkbox>
                                    <div className="note">Attract proposals faster</div>
                                </Form.Item>

                                <div className="price">$34</div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-end buttons mt-3">
                            <Button className="btn btn-xs btn-pink-txt" type="secondary" onClick={() => {
                                this.props.skip()
                            }}>Skip</Button>
                            <Button type="primary" disabled={this.state.disable_button} onClick={() => {
                                this.props.onClick(this.state.selected_options)
                            }} className="btn ml-10">Proceed</Button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default PostProjectStepOne;