import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

export default class HeaderSearchRouter extends Component { 
    constructor(props) {
        super();
        this.state = {
            redirect: false,
            module: ''
        }
    }

    render () {
        
        if(this.props.keyword && (this.props.keyword !== 'undefined')){
            return <Redirect to={"/" + this.props.url + "/" + this.props.keyword} />;
        }else{
            return <Redirect to={"/" + this.props.url + "/"} />;
        }
    }
}