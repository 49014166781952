import React, { Component } from 'react';

// add form components
import { Dropdown, Button, Menu, Popconfirm } from 'antd';

import { DownOutlined } from '@ant-design/icons';

import Api from '../../api/Api';

class ProposalActions extends Component { 
    constructor(props) {
        super();
        this.state = {

            loading: false,
            action_menu_visible: false
        }
    }
    
    render () {

        return (

            <>
                {this.proposalActions()}
            </>
        )
    }

    proposalActions = () => {

        var project = this.props.project;
        var proposal = this.props.proposal;

        if(project && project.project_view == 1 && proposal){

            if(proposal.status == 0){

                const menu = (
                    <Menu className="workflow-action-menu">
                        <Menu.Item key="cancel">

                            {proposal.type == 'invoice'
                                ?
                                    <Popconfirm
                                        title="You really want to cancel your invoice?"
                                        onConfirm={() => {
                                            this.invoiceAction(proposal, 'cancel')
                                        }}
                                        cancelText="No"
                                    >
                                        <Button loading={this.props.loading}>Cancel Invoice</Button>
                                    </Popconfirm>
                                :
                                    <Popconfirm
                                        title="You really want to cancel your proposal?"
                                        onConfirm={() => {
                                            this.cancelProposal(proposal)
                                        }}
                                        cancelText="No"
                                    >
                                        <Button loading={this.props.loading}>Cancel Proposal</Button>
                                    </Popconfirm>
                            }
                        </Menu.Item>
                    </Menu>
                );
                
                return (
                    <Dropdown
                        className="workflow-action-dropdown"
                        overlay={menu}
                        trigger={['click']}
                        visible={this.state.action_menu_visible}
                        onVisibleChange={(e) => {
                            this.setState({action_menu_visible: e})
                        }}
                    >
                        <Button>
                            Actions <DownOutlined />
                        </Button>
                    </Dropdown>
                )
            }

            return <span>{this.invoiceStatus(proposal.status)}</span>
        }

        if(project && project.project_view == 0 && proposal){

            if(proposal.status == 0){

                if(proposal.type == 'invoice'){

                    var menu = (
                        <Menu className="workflow-action-menu">
                            <Menu.Item key="accept">
                                <Popconfirm
                                    title="You really want to pay this invoice?"
                                    onConfirm={() => {
                                        this.invoiceAction(proposal, 'pay');
                                    }}
                                    cancelText="No"
                                >
                                    <Button loading={this.props.loading}>Pay Invoice</Button>
                                </Popconfirm>
                            </Menu.Item>
                            <Menu.Item key="reject">
                                <Popconfirm
                                    title="You really want to reject this invoice?"
                                    onConfirm={() => {
                                        this.invoiceAction(proposal, 'reject')
                                    }}
                                    cancelText="No"
                                >
                                    <Button loading={this.props.loading}>Reject Invoice</Button>
                                </Popconfirm>
                            </Menu.Item>
                        </Menu>
                    );
                }else{

                    var menu = (
                        <Menu className="workflow-action-menu">
                            <Menu.Item key="accept">
                                <Popconfirm
                                    title="You really want to accept this proposal?"
                                    onConfirm={() => {
                                        this.acceptProposal(proposal)
                                    }}
                                    cancelText="No"
                                >
                                    <Button loading={this.props.loading}>Accept Proposal</Button>
                                </Popconfirm>
                            </Menu.Item>
                            <Menu.Item key="reject">
                                <Popconfirm
                                    title="You really want to reject this proposal?"
                                    onConfirm={() => {
                                        this.rejectProposal(proposal)
                                    }}
                                    cancelText="No"
                                >
                                    <Button loading={this.props.loading}>Reject Proposal</Button>
                                </Popconfirm>
                            </Menu.Item>
                        </Menu>
                    );
                }
                return (
                    <Dropdown
                        className="workflow-action-dropdown"
                        overlay={menu}
                        trigger={['click']}
                        visible={this.state.action_menu_visible}
                        onVisibleChange={(e) => {
                            this.setState({action_menu_visible: e})
                        }}
                    >
                        <Button>
                            Actions <DownOutlined />
                        </Button>
                    </Dropdown>
                )
            }

            return <span>{this.invoiceStatus(proposal.status)}</span>
        }
    }

    invoiceStatus = (status) => {

        var statuses = {
            1: 'Accepted',
            2: 'Cancelled',
            3: 'Rejected',
            4: 'Cancelled',
            5: 'Rejected',
            6: 'Paid',
        };

        return statuses[status];
    }

    invoiceAction = (proposal, action) => {

        var formData = new FormData();
        
        formData.append('account_token', this.props.account_token);
        formData.append('invoice', proposal.invoice_id);
        
        var that = this;

        if(action == 'cancel'){
        
            var url = 'invoices/cancel';
        }
        if(action == 'pay'){
        
            var url = 'invoices/pay';
        }
        if(action == 'reject'){
        
            var url = 'invoices/reject';
        }

        this.setState({loading: true});
        Api.post(url, formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){
            
                that.props.updateProposal(data.proposal, data.notification);
                that.props.onSuccess(data.message);
            }else{

                that.props.onError(data.message);
            }
        });
    }

    acceptProposal = (proposal) => {

        var formData = new FormData();
        
        formData.append('account_token', this.props.account_token);
        formData.append('proposal', proposal.proposal_unq_id);
        
        var that = this;

        this.setState({loading: true});
        Api.post('proposals/accept', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){
            
                that.props.updateProposal(data.proposal, false);
                that.props.onSuccess(data.message);
                that.props.updateProject(data.project);
            }else{

                that.props.onError(data.message);
            }
        });
    }

    rejectProposal = (proposal) => {

        var formData = new FormData();
        
        formData.append('account_token', this.props.account_token);
        formData.append('proposal', proposal.proposal_unq_id);
        
        var that = this;

        this.setState({loading: true});
        Api.post('proposals/reject', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){
            
                that.props.updateProposal(data.proposal, false);
                that.props.onSuccess(data.message);
            }else{

                that.props.onError(data.message);
            }
        });
    }

    cancelProposal = (proposal) => {

        var formData = new FormData();
        
        formData.append('account_token', this.props.account_token);
        formData.append('proposal', proposal.proposal_unq_id);
        
        var that = this;

        this.setState({loading: true});
        Api.post('proposals/cancel', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){
            
                that.props.updateProposal(data.proposal, data.notification);
                that.props.onSuccess(data.message);
                that.props.updateCanResend(true);
            }else{

                that.props.onError(data.message);
            }
        });
    }
}

export default ProposalActions;