import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import renderHTML from 'react-render-html';

// add form components
import { Typography, Button, Progress } from 'antd';

import FeatherIcon from 'feather-icons-react';

import ProfilePic from '../../components/ProfilePic';
import File from '../../components/File';

import ProposalActions from './ProposalActions';
import ProposalPopup from './ProposalPopup';

import { StarFilled } from '@ant-design/icons';

import Api from '../../api/Api';

class ProposalView extends Component { 
    constructor(props) {
        super();
        this.state = {
            proposal_modal: false,

            expended: {}
        }
    }

    render () {

        return (

            <>
                <div>
                    {this.renderProposals()}

                    {this.props.project.project_view == 1 && this.props.can_resend && this.props.area == 'main' &&
                        
                        <>
                            <div className="block">
                                <div className="details" style={{display:'flex', justifyContent:'center'}}>
                                    <Button onClick={() => {
                                        this.setState({proposal_modal: true})
                                    }} className="button">Resend Proposal</Button>
                                </div>
                            </div>
                        </>
                    }
                </div>

                <ProposalPopup
                    proposal_modal={this.state.proposal_modal}
                    project={this.props.project}
                    account_token={this.props.account_token}
                    appendRow={(row) => {
                        this.props.appendRow(row);
                        this.props.updateCanResend(false);
                    }}
                    onError={(message) => {
                        this.props.onError(message);
                    }}
                    onSuccess={(message) => {
                        this.props.onSuccess(message);
                    }}
                    hideModal={() => {
                        this.setState({proposal_modal: false})
                    }}
                />
            </>
        )
    }

    renderProposals = () => {

        var proposals = this.props.proposals;
        
        if(proposals.length > 0){

            return proposals.map((proposal) => {

                var key = 'proposal_' + proposal.inc_id;

                if(proposal.type == 'proposal' || proposal.type == 'invoice'){

                    return this.proposalView(key, proposal);
                }

                if((proposal.type == 'buyer_feedback' || proposal.type == 'seller_feedback') && this.props.area == 'main'){

                    return this.ratingView(key, proposal);
                }

                if(proposal.type == 'message' && this.props.area == 'main'){

                    return this.messageView(key, proposal);
                }

                if( (
                        proposal.type == 'notification'
                        ||
                        proposal.type == 'proposal_acceptence'
                        ||
                        proposal.type == 'proposal_rejection'
                        ||
                        proposal.type == 'proposal_cancel'
                        ||
                        proposal.type == 'invoice_paid'
                        ||
                        proposal.type == 'invoice_rejection'
                        ||
                        proposal.type == 'invoice_cancel'
                        ||
                        proposal.type == 'nda_requested'
                        ||
                        proposal.type == 'nda_accepted'
                        ||
                        proposal.type == 'status_delivered'
                    )
                    &&
                    this.props.area == 'main'
                ){

                    return this.notificationView(key, proposal);
                }
            })
        }
    }

    ratingView = (key, proposal) => {

        var user = this.props.project.project_view == 1 ? this.props.buyer : this.props.seller;
        var percent = (proposal.feedback_rating / 5) * 100;
        return (

            <div key={key} className="proposal-block block chat feedback">
                <div className="icon"><FeatherIcon icon="bell" /></div>
                <div className="details">
                    <div className="top">
                        <div className="title">Feedback</div>
                        <div className="user-details">
                            <span className="img-block">
                                <ProfilePic src={user.profile_pic_full} size={50} />
                            </span>
                            <div className="ratings-block">
                                <span className="rating">
                                    <div className="ratings">
                                        <span>{proposal.feedback_rating}</span>
                                        <StarFilled className="ico" />
                                    </div>
                                    <div class="top-text">
                                        <Progress percent={percent} showInfo={false} strokeColor="#FF4500" trailColor="#FFF" type="line" strokeWidth={6} />
                                    </div>
                                </span>
                                <div class="bottom-text">{proposal.feedback_review}</div>
                            </div>
                        </div>
                    </div>
                    <div className="time-block">2 hours ago</div>
                </div>
            </div>
        )
    }

    notificationView = (key, proposal) => {

        return (
            <div key={key} className="proposal-block block chat">
                <div className="icon">
                    <FeatherIcon icon="bell" />
                </div>
                <div className="details">
                    <div className="top">
                        <div className="text lg">{proposal.description}</div>
                    </div>
                    <div className="time-block">{proposal.added_on_formatted}</div>
                </div>
            </div>
        )
    }

    messageView = (key, proposal) => {

        var direction = 'left';
        
        if(this.props.project.project_view == 1){

            if(proposal.message_type == 'buyer'){

                direction = 'right';
            }else{

                direction = 'left';
            }
        }

        if(this.props.project.project_view == 0){

            if(proposal.message_type == 'seller'){

                direction = 'right';
            }else{

                direction = 'left';
            }
        }

        return (

            <div key={key} className={"proposal-block block " + direction + " chat"}>
                
                {direction == 'left' &&
                    <div className="icon">
                        <ProfilePic src={this.props.project.project_view == 1 ? this.props.buyer.profile_pic_full : this.props.seller.profile_pic_full} />
                    </div>
                }

                <div className="details">
                    <div className="top">
                        <div className="text lg">{proposal.message}</div>
                    </div>
                    <div className="time-block">{proposal.added_on_formatted}</div>
                </div>

                {direction == 'right' &&
                    <div className="icon">
                        <ProfilePic src={this.props.user.profile_pic_full} />
                    </div>
                }
            </div>
        )
    }

    statusLabel = (key, type) => {

        var invoice = {0: 'Raised', 4: 'Cancelled', 5: 'Rejected', 6: 'Paid'};
        var proposal = {0: 'Submitted', 1: 'Accepted', 2: 'Cancelled', 3: 'Rejected'};

        if(type == 'invoice'){

            return invoice[key];
        }
        if(type == 'proposal'){

            return proposal[key];
        }
    }

    proposalView = (key, proposal) => {

        var project = this.props.project;

        if(project){

            return (

                <div key={key} className="proposal-block-container">

                    <div className="proposal-block">
                        <div className="icon">
                            {this.props.area == 'main'
                                ?
                                    <ProfilePic src={this.props.user.profile_pic_full} />
                                :
                                    proposal.invoice
                                        ?
                                            <FeatherIcon icon="file-text" />
                                        :
                                            <FeatherIcon icon="edit-2" />
                            }
                        </div>
                        <div className="details">
                            <div className="details-block">
                                <div className="top">

                                    {this.props.area == 'sidebar' &&
                                        <div className="time-block">{proposal.date}</div>
                                    }

                                    {this.props.area == 'main'
                                        ?
                                            proposal.invoice
                                                ?
                                                    <div className="title">Invoice</div>
                                                :
                                                    <div className="title">Proposal</div>
                                        :
                                            <div onClick={() => {
                                                var expended = this.state.expended;
                                                if(this.state.expended.hasOwnProperty(proposal.inc_id)){

                                                    delete expended[proposal.inc_id];
                                                }else{
                                                    
                                                    expended[proposal.inc_id] = 1;
                                                }
                                                this.setState({expended: expended});
                                            }}>
                                                {proposal.invoice
                                                    ?
                                                        <div className="title">Invoice #{proposal.invoice.invoice_number} {this.statusLabel(proposal.status, 'invoice')}</div>
                                                    :
                                                        <div className="title">Proposal #{proposal.proposal_id} {this.statusLabel(proposal.status, 'proposal')}</div>
                                                }
                                            </div>
                                    }

                                    {this.props.area == 'main'
                                        ?
                                            proposal.invoice
                                                ?
                                                    null
                                                :
                                                    <div className="text">
                                                        {proposal.description != ''
                                                            ?
                                                                renderHTML(proposal.description)
                                                            :
                                                                null
                                                        }
                                                    </div>
                                        :
                                            this.state.expended.hasOwnProperty(proposal.inc_id)
                                                ?
                                                    <div className="text">
                                                        <Typography.Paragraph>{proposal.description}</Typography.Paragraph>
                                                    </div>
                                                :
                                                    null
                                    }

                                    {proposal.hasOwnProperty('attachments') && proposal.attachments && proposal.attachments.length > 0 && this.props.area == 'main'
                                        ?
                                            <div className="form-gallery sm mt-1">

                                                <ul>
                                                    {
                                                        proposal.attachments.map((_item) => {

                                                            return (
                                                                <li>
                                                                    <File url={_item} />
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        :
                                            null
                                    }
                                </div>

                                {this.props.area == 'main'
                                    ?
                                        this.itemFooter(proposal, project)
                                    :
                                        null
                                }
                            </div>
                            {this.props.area == 'main' &&
                                <div className="time-block">{proposal.added_on_formatted}</div>
                            }
                        </div>
                    </div>

                    {this.props.area == 'sidebar'
                        ?
                            this.state.expended.hasOwnProperty(proposal.inc_id)
                                ?
                                    this.itemFooter(proposal, project)
                                :
                                    null
                        :
                            null
                    }
                </div>
            )
        }
    }

    itemFooter = (proposal, project) => {

        return (
            <div className="item-block proposal-items-block">

                {proposal.invoice
                    ?

                        <>
                            <div className="title">
                                <span className="" style={{width:'40%'}}><FeatherIcon icon="layers" />Item</span>
                                <span className="" style={{width:'15%'}}>Qty</span>
                                <span className="" style={{width:'20%'}}>Date</span>
                                <span className="" style={{width:'25%', textAlign:'right'}}>Amount</span>
                            </div>
                            <div className="details">
                                {proposal.invoice.invoice_items && proposal.invoice.invoice_items.length > 0 &&

                                    proposal.invoice.invoice_items.map((item) => {

                                        var key = 'invoice_item_' + item.id;
                                        return (

                                            <div className="detail-block" key={key}>
                                                <span style={{width:'40%'}}>{item.item}</span>
                                                <span style={{textAlign:'center', width:'15%'}}>{item.qty}</span>
                                                <span style={{width:'20%'}}>{item.date}</span>
                                                <span style={{width:'25%', textAlign:'right'}}>${item.amount}</span>
                                            </div>
                                        )
                                    })
                                }

                                <div className="detail-block">
                                    <span style={{width:'40%'}}></span>
                                    <span style={{textAlign:'center', width:'15%'}}></span>
                                    <span style={{width:'20%', textAlign:'right'}}>Tax</span>
                                    <span style={{width:'25%', textAlign:'right'}}>${proposal.invoice.tax}</span>
                                </div>
                            </div>
                        </>
                    :
                        <>
                            <div className="title">
                                <span className=""><FeatherIcon icon="layers" />Item</span>
                                <span className="">Amount</span>
                            </div>
                            <div className="details">
                                {proposal.items && proposal.items.length > 0 &&

                                    proposal.items.map((item) => {

                                        var key = 'milestone_item_' + item.id;
                                        return (

                                            <div className="detail-block" key={key}>
                                                <span>{item.title}</span>
                                                <span>${item.amount}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </>
                }
                <div className="price-block">
                    <div className="left">

                    {proposal.invoice
                        ?
                            <div className="price-detail-block">
                                <span>Invoice ID</span>
                                <span>{proposal.invoice.invoice_number}</span>
                            </div>
                        :
                            <div className="price-detail-block">
                                <span>Proposal ID</span>
                                <span>{proposal.proposal_id}</span>
                            </div>
                    }
                    </div>
                    <div className="right">
                        
                        <ProposalActions
                            proposal={proposal}
                            project={project}
                            updateProposal={(proposal, notification) => {
                                this.updateProposal(proposal, notification)
                            }}
                            updateCanResend={(status) => {
                                this.props.updateCanResend(status)
                            }}

                            updateProject={(project) => {
                                this.props.updateProject(project)
                            }}

                            onError={(message) => {
                                this.props.onError(message)
                            }}
                            onSuccess={(message) => {
                                this.props.onSuccess(message)
                            }}
                            account_token={this.props.account_token}
                        />
                    </div>
                </div>
            </div>
        )
    }

    updateProposal = (_proposal, notification) => {

        var proposals = this.props.proposals;
        
        var _proposals = [];
        if(proposals.length > 0){

            for(var i in proposals){

                var proposal = proposals[i];
                if(proposal.inc_id == _proposal.inc_id){

                    _proposals.push(_proposal);
                }else{

                    _proposals.push(proposal);
                }
            }
        }

        if(notification){

            _proposals.push(notification);
        }
        this.props.updateProposals(_proposals);
    }
}

export default ProposalView;