import React, { Component } from 'react';

// add form components
import { Table, Tabs, Empty, Avatar } from 'antd';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import FeatherIcon from 'feather-icons-react';
import Main from '../../../components/Main';

import ProjectsList from './ProjectsList';

import LayoutHelper from '../../../helpers/LayoutHelper';

import Api from '../../../api/Api';

class Projects extends Component { 
    constructor(props) {
        super();
        this.state = {

            error_message: '',
            success_message: '',

            active_tab: 'projects',

            account_token: false,
            user: false,

            invitations: [],

            pending_proposals: []
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');

        var _user = JSON.parse(user);
        this.setState({user: _user});

        if(account_token){
            
            this.setState({account_token: account_token});
        }else{
            // window.location = Api.url + '/logout';
        }
    }
    
    render () {
        const tableColumns = [
            {
                title: 'Buyer / Client',
                dataIndex: 'buyer',
                key: 'buyer',
            },
            {
                title: 'Project',
                dataIndex: 'project',
                key: 'project',
            },
            {
                title: 'Target Date',
                dataIndex: 'targetdate',
                key: 'targetdate',
            },
            {
                title: 'Total',
                dataIndex: 'total',
                key: 'total',
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
            },
        ];

        const pendingTableColumns = [
            {
                title: 'Buyer / Client',
                dataIndex: 'buyer',
                key: 'buyer',
            },
            {
                title: 'Project',
                dataIndex: 'project',
                key: 'project',
            },
            {
                title: 'Total',
                dataIndex: 'total',
                key: 'total',
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
            },
        ];

        const projectTableColumns = [
            {title: 'Buyer/Client', width: 150, dataIndex: 'employer_name', ellipsis: true, key: 'employer_name', render: (name, row) => {
                return (
                    <div>
                        <Avatar size={40} src={row.profile_pic} />
                        <span className="ml-5">{name}</span>
                    </div>
                )
            }},
            {title: 'Project', dataIndex: 'title', key: 'title'},
            {title: 'Start Date', width: 130, align: 'top', dataIndex: 'start_date_formatted', key: 'start_date_formatted',},
            {title: 'Budget', width: 130, dataIndex: 'budget_formatted', key: 'budget_formatted',},
            {title: 'Invitation Date', width: 130, dataIndex: 'invitation_date', key: 'invitation_date',},
            {title: 'Action', key: 'action', render: (name, row) => {

                return (

                    <a className="btn" target="_blank" href={Api.url + 'project/' + row.slug}>Details</a>
                )
            }},
        ];

        const pendingProposalsColumns = [
            {title: 'Buyer/Client', dataIndex: 'employer_name', key: 'employer_name', render: (name, row) => {
                return (
                    <div>
                        <Avatar size={40} src={row.profile_pic} />
                        <span className="ml-5">{name}</span>
                    </div>
                )
            }},
            {title: 'Project', dataIndex: 'title', key: 'title',},
            {title: 'Start Date', dataIndex: 'start_date_formatted', key: 'start_date_formatted',},
            {title: 'Budget', dataIndex: 'budget_formatted', key: 'budget_formatted',},
            {title: 'Action', key: 'action', render: (name, row) => {

                return (

                    <Link className="btn" to={"/project-flow/" + row.id}>Details</Link>
                )
            }},
        ];

        return (

            <>
                <Main page_title="Manage Projects" hide_page_title="true" className="projects-as-seller account-bg" page="as_seller" error_message={this.state.error_message} success_message={this.state.success_message}>
                    
                    <div className="container-lg page-main-content">
                        
                        <div className="page-heading">Manage Projects (Seller)</div>

                        <Tabs className="manage-projects-list" defaultActiveKey="projects" tabPosition='left' onTabClick={(key, event) => {
                            this.setState({active_tab: key});
                            if(key == 'invitations'){

                                this.loadInvitations(this.state.account_token);
                            }
                            if(key == 'pending_proposals'){

                                this.pendingProposals(this.state.account_token);
                            }
                        }}>
                            
                            <Tabs.TabPane tab={
                                <a>
                                    <FeatherIcon icon="layers" /><span>Projects</span>
                                </a>
                                } key="projects">

                                {this.state.active_tab == 'projects' &&
                                    <ProjectsList
                                        active_tab={this.state.active_tab}
                                        errorMessage={(message) => {
                                            LayoutHelper.addErrorMessage(this, message);
                                        }}
                                    />
                                }
                            </Tabs.TabPane>

                            <Tabs.TabPane tab={
                                <a>
                                    <FeatherIcon icon="inbox" /><span>Invitations</span>
                                </a>
                                } key="invitations">

                                <div className="tab-heading">Invitations</div>
                                <Table columns={projectTableColumns} dataSource={this.state.invitations}>
                                    <Empty />
                                </Table>
                            </Tabs.TabPane>

                            <Tabs.TabPane tab={
                                <a>
                                    <FeatherIcon icon="thumbs-up" /><span>Recommendations</span>
                                </a>
                                } key="recommendations">
                                <Tabs defaultActiveKey="1">
                        
                                    <Tabs.TabPane tab={
                                        <a>
                                            <span>New</span>
                                        </a>
                                    } key="1">
                                        <div className="tab-heading">Recommendations</div>
                                        <Table columns={tableColumns}>
                                            <Empty />
                                        </Table>
                                    </Tabs.TabPane>

                                    <Tabs.TabPane tab={
                                        <a>
                                            <span>All</span>
                                        </a>
                                    } key="2">
                                        <div className="tab-heading">All</div>
                                        <Table columns={tableColumns}>
                                            <Empty />
                                        </Table>
                                    </Tabs.TabPane>

                                </Tabs>
                            </Tabs.TabPane>

                            <Tabs.TabPane tab={
                                <a>
                                    <FeatherIcon icon="inbox" /><span>Pending Proposals</span>
                                </a>
                                } key="pending_proposals">

                                <div className="tab-heading">Pending Proposals</div>
                                <Table columns={pendingProposalsColumns} dataSource={this.state.pending_proposals}>
                                    <Empty />
                                </Table>
                            </Tabs.TabPane>

                        </Tabs>
                    </div>
                </Main>
            </>
        )
    }

    pendingProposals = (account_token) => {
        
        var formData = new FormData();
        
        formData.append('account_token', account_token);
        
        var that = this;

        this.setState({loading: true});
        Api.post('seller/pending_proposals', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){
            
                if(data.projects.length > 0){

                    that.setState({pending_proposals: data.projects, no_data: false});
                }else{

                    that.setState({no_data: true, pending_proposals: []});
                }
            }else{

                // if(data.code == 'no_account'){
                //     window.location = Api.url + '/logout';
                // }
                // that.props.errorMessage(data.message);
            }
        });
    }

    loadInvitations = (account_token) => {
        
        var formData = new FormData();
        
        formData.append('account_token', account_token);
        
        var that = this;

        this.setState({loading: true});
        Api.post('seller/invitations', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){
            
                if(data.invitations.length > 0){

                    that.setState({invitations: data.invitations});
                }else{

                    that.setState({invitations: []});
                }
            }else{

                // if(data.code == 'no_account'){
                //     window.location = Api.url + '/logout';
                // }
                // that.props.errorMessage(data.message);
            }
        });
    }
}

export default Projects;