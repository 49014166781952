import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

// add form components
import { List, Select, Skeleton, Typography, Carousel, Modal } from 'antd';

import FeatherIcon from 'feather-icons-react';
import Main from '../../../components/Main';

import FreelancerCard from '../../../components/freelancer/FreelancerCard';
import FreelancerRow from '../../../components/freelancer/FreelancerRow';

import ProfilePic from '../../../components/ProfilePic';

import LayoutHelper from '../../../helpers/LayoutHelper';

import Api from '../../../api/Api';

class Projects extends Component { 
    constructor(props) {
        super();
        this.state = {

            loading: false,

            error_message: '',
            success_message: '',

            account_token: false,
            user: false,

            go_back: false,

            has_data: false,

            project: false,
            proposals: [],

            per_page: 10,
            total: 0,

            featured_proposals: [],

            proposal_view: false,

            active_proposal: false
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');

        var _user = JSON.parse(user);
        this.setState({user: _user, account_token: account_token});

        var project_id = this.props.project_id;

        if(project_id){
         
            this.loadProposals(account_token, project_id);
        }else{

            this.setState({go_back: true});
        }
    }

    header = () => {

        return (

            <div className="listing-header">
                
                <div className="listing-toolbar">
                    <div className="filter-sort">
                        <button type="submit" className="btn btn-sm" onClick={() => {
                            this.setState({show_filters: true});
                        }}>
                            Filter
                            <span className="filter-icon">
                                <FeatherIcon icon="sliders" />
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
    
    render () {

        if(this.state.go_back){

            return <Redirect to="/" />
        }

        return (

            <>
                <Main page_title="Project Proposals" page="as_seller" error_message={this.state.error_message} success_message={this.state.success_message}>
                    
                    <div className="page-static-banner" ref={this.page}>

                        <div className="container">
                            {this.state.project
                                ?
                                    <>
                                        <h2>{this.state.project.title}</h2>
                                        <div className="actions">
                                            <div className="badge">Open for proposals</div>

                                            {/* <div className="filter-sort">
                                                <Select defaultValue="" className="sort-option ml-10">
                                                    <Select.Option value="">More Actions</Select.Option>
                                                </Select>
                                            </div> */}
                                        </div>
                                    </>
                                :
                                    null
                            }
                        </div>
                    </div>

                    <div>

                        {this.featuredProposals()}
                    </div>

                    <div className="container">

                        <div className="page-section-heading">
                            {/* <div className="tab-heading">Everything else</div> */}
                            <div className="tab-sub-heading">Proposals from freelancers</div>
                        </div>
                        <div className="freelancers-list">
                            <List
                                // header={this.header()}
                                className="projects-list"
                                itemLayout="horizontal"
                                size="large"
                                loading={this.state.loading}
                                pagination={{
                                    onChange: page => {
                                        this.props.onLoad();
                                        window.setTimeout(() => {
                                            // this.loadProjects(page);
                                        }, 200);
                                    },
                                    pageSize: this.state.per_page,
                                    total: this.state.total
                                }}
                                dataSource={this.state.proposals}
                                renderItem={item => (
                                    <FreelancerRow
                                        freelancer={item}
                                        source="proposals"
                                        project={this.state.project}
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <Modal
                        title="New Proposal"
                        visible={this.state.proposal_view}
                        // onOk={this.handleOk}
                        closable={true}
                        onCancel={() => {
                            this.setState({proposal_view: false, active_proposal: false});
                        }}
                        width={1000}
                        footer={null}
                        title=""
                    >
                        <div className="proposal-overview">

                            <div className="proposal-block">
                                <div className="icon">
                                    <ProfilePic src={this.state.active_proposal.profile_pic_full} size={60} />
                                </div>
                                <div className="details">
                                    <div className="proposal-user-details">
                                        <h5>{this.state.active_proposal.name}</h5>
                                        <strong>{this.state.active_proposal.story}</strong>
                                    </div>
                                    <div className="details-block proposal-details">
                                        <div className="top">
                                            <div className="title">New Proposal</div>
                                            <div className="text">
                                                <Typography.Paragraph>{this.state.active_proposal.description}</Typography.Paragraph>
                                            </div>
                                        </div>
                                        <div className="item-block">
                                            <div className="title">
                                                <span className=""><FeatherIcon icon="layers" />Item</span>
                                                <span className="">Amount</span>
                                            </div>
                                            <div className="details">
                                                {this.state.active_proposal.items && this.state.active_proposal.items.length > 0 &&

                                                    this.state.active_proposal.items.map((item) => {

                                                        var key = 'milestone_item_' + item.id;
                                                        return (

                                                            <div className="detail-block" key={key}>
                                                                <span>{item.title}</span>
                                                                <span>${item.amount}</span>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className="price-block">
                                                <div className="left">
                                                    <div className="price-detail-block d-flex justify-content-center" style={{paddingTop:20, paddingBottom:20}}>
                                                        <Link className="btn btn-purple" to={"/project-flow/" + this.state.active_proposal.project_id + '/' + this.state.active_proposal.freelancer_id}>
                                                            Reply
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </Main>
            </>
        )
    }

    featuredProposals = () => {

        if(this.state.featured_proposals.length > 0){

            return (
            
                <div className="container">

                    <div className="page-section-heading">
                        <div className="tab-heading">Recommended</div>
                        <div className="tab-sub-heading">We recommends this proposal</div>
                    </div>
                    <div className="articles-grid featured-slider">
                        
                        <Carousel
                            dots={false}
                            autoplay={false}
                            slidesToScroll={1}
                            slidesToShow={this.state.featured_proposals.length < 2 ? (this.state.featured_proposals.length - 0) : 2}
                            arrows={true}
                            prevArrow={<div><div className="slider-arrows"><FeatherIcon icon="chevron-left" className="ico" size={30} /></div></div>}
                            nextArrow={<div><div className="slider-arrows"><FeatherIcon icon="chevron-right" className="ico" size={30} /></div></div>}
                        >
                            {this.state.featured_proposals.map((_proposal) => {

                                var key = 'featured_card_' + _proposal.inc_id;
                                return (
                                    <FreelancerCard
                                        key={key}
                                        user={_proposal}
                                        onRightActionClick={(proposal) => {
                                            this.setState({active_proposal: proposal, proposal_view: true});
                                        }}
                                        right_action_label="View Proposal"
                                        no_link={true}
                                    />
                                )
                            })}
                        </Carousel>
                    </div>
                </div>
            )
        }
    }

    loadProposals = (account_token, project_id) => {

        var formData = new FormData();
        
        formData.append('account_token', account_token);
        formData.append('project', project_id);
        
        var that = this;

        this.setState({loading: true});
        Api.post('proposals/bids', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){

                if(data.featured_proposals && data.featured_proposals.length > 0){

                    that.setState({featured_proposals: data.featured_proposals});
                }else{

                    that.setState({featured_proposals: []});
                }
            
                if(data.proposals){

                    that.setState({proposals: data.proposals, project: data.project, no_data: false, total: data.proposals.length});
                }else{

                    that.setState({no_data: true, proposals: [], project: false});
                }
            }else{

                // if(data.code == 'no_account'){
                //     window.location = Api.url + '/logout';
                // }
                // that.props.errorMessage(data.message);
            }
        });
    }
}

export default Projects;