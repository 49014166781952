import React, { Component } from 'react';

// import { Link } from "react-router-dom";
// import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { Tabs } from 'antd';

import Api from '../api/Api';

import Main from '../components/Main';


const calculateRemaining = (number, divideby) => {
    const remaining = number % divideby;
    return remaining;
}

class TrustSafetyPage extends Component { 
    constructor(props) {
        super();
        this.state = {
            main_banner: '',
            main_heading: '',

            client_content_blocks: [],
            sp_content_blocks: []
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        this.setState({account_token: account_token});

        this.loadData();
    }

    renderClientBlocks = () => {

        const blocksList = [];
        for(let i = 0; i < this.state.client_content_blocks.length; i++) {
            let key = 'client_block_'+i;

            let remaining_count = calculateRemaining(i, 2);
            
            blocksList.push(
                <div className={(remaining_count== 1)? 'img-text-section rev-row': 'img-text-section'} data={remaining_count} key={key}>
                    <div className="text-block half-container">
                        <div className="block">
                            <div className="heading">{this.state.client_content_blocks[i].title}</div>
                            <div dangerouslySetInnerHTML={{ __html: this.state.client_content_blocks[i].text }} />
                        </div>
                    </div>
                    <div className="img-block">
                        <img src={this.state.client_content_blocks[i].image_url} alt={this.state.client_content_blocks[i].image_alt} />
                    </div>
                </div>
            );
        }
        return blocksList;
    }

    renderSpBlocks = () => {

        const blocksList = [];
        for(let i = 0; i < this.state.sp_content_blocks.length; i++) {
            let key = 'sp_block_'+i;

            let remaining_count = calculateRemaining(i, 2);
            
            blocksList.push(
                <div className={(remaining_count== 1)? 'img-text-section rev-row': 'img-text-section'} data={remaining_count} key={key}>
                    <div className="text-block half-container">
                        <div className="block">
                            <div className="heading">{this.state.sp_content_blocks[i].title}</div>
                            <div dangerouslySetInnerHTML={{ __html: this.state.sp_content_blocks[i].text }} />
                        </div>
                    </div>
                    <div className="img-block">
                        <img src={this.state.sp_content_blocks[i].image_url} alt={this.state.sp_content_blocks[i].image_alt} />
                    </div>
                </div>
            );
        }
        return blocksList;
    }

    render () {

        return (

            <Main page_title="TrustSafetyPage">
                <div className="inner-page pt-0 white-bg">
                    <div className="banner-block orange-bg-img">
                        <img src={this.state.main_banner} alt={this.state.main_heading} />
                        <div className="container">
                            <div className="heading">{this.state.main_heading}</div>
                        </div>
                    </div>

                    <Tabs defaultActiveKey="1" centered>
                        <Tabs.TabPane tab="Clients" key="1">
                            {this.renderClientBlocks()}
                        </Tabs.TabPane>

                        <Tabs.TabPane tab="Service Provider" key="2">
                            {this.renderSpBlocks()}
                        </Tabs.TabPane>
                    </Tabs>
                    
                </div>
            </Main>
        )
    }


    loadData = () => {
        const formData = new FormData();
        var that = this;

        Api.post('cms/trust_safety', formData, function(data){
            
            that.setState({
                main_banner: data.main_banner,
                main_heading: data.main_heading,
                client_content_blocks: data.client_content_blocks,
                sp_content_blocks: data.sp_content_blocks
            });
        });
    }
}

export default TrustSafetyPage;