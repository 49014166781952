import React, { Component } from 'react';

// add form components
import { Tabs } from 'antd';

import Main from '../../../components/Main';

import SingleProduct from '../../../components/products/FreelancerProductBlock';

import LayoutHelper from '../../../helpers/LayoutHelper';

import Api from '../../../api/Api';

class ProductsListComponent extends Component { 
    constructor(props) {
        super();
        this.state = {

            error_message: '',
            success_message: '',

            active_tab: 'all_products',

            account_token: false,
            user: false,

            all_products: [],
            active_products: [],
            paused_products: [],
            pending_products: [],
            declined_products: [],
            featured_products: [],
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');

        var _user = JSON.parse(user);
        this.setState({user: _user});

        if(account_token){
            
            this.setState({account_token: account_token});

            this.loadProducts(account_token, 'all');
        }else{
            // window.location = Api.url + '/logout';
        }
    }
    
    list = (products, type) => {

        return (

            <div className="products_offers">
                {products && products.length > 0
                    ?
                        products.map((product, index) => {

                            return <SingleProduct key={type + index} product={product} user={this.state.user} />
                        })
                    :
                        null
                }
            </div>
        )
    }

    render () {

        return (

            <>
                <Tabs className="manage-products-list" defaultActiveKey="all_products" onTabClick={(key, event) => {
                    this.setState({active_tab: key});
                    if(key == 'all_products'){

                        this.loadProducts(this.state.account_token, 'all');
                    }
                    if(key == 'active_products'){
                        this.loadProducts(this.state.account_token, 'active');
                    }
                    if(key == 'paused_products'){
                        this.loadProducts(this.state.account_token, 'paused');
                    }
                    if(key == 'pending_products'){
                        this.loadProducts(this.state.account_token, 'pending');
                    }
                    if(key == 'declined_products'){
                        this.loadProducts(this.state.account_token, 'declined');
                    }
                    if(key == 'featured_products'){
                        this.loadProducts(this.state.account_token, 'featured');
                    }
                }}>
                    
                    <Tabs.TabPane tab={
                        <a>
                            <span>All</span>
                        </a>
                        } key="all_products">

                            {this.list(this.state.all_products, 'all')}
                    </Tabs.TabPane>

                    <Tabs.TabPane tab={<a><span>Active</span></a>} key="active_products">

                            {this.list(this.state.active_products, 'active')}
                    </Tabs.TabPane>

                    <Tabs.TabPane tab={
                        <a>
                            <span>Paused</span>
                        </a>
                        } key="paused_products">
                        
                            {this.list(this.state.paused_products, 'paused')}
                    </Tabs.TabPane>

                    <Tabs.TabPane tab={
                        <a>
                            <span>Pending</span>
                        </a>
                        } key="pending_products">
                            
                            {this.list(this.state.pending_products, 'pending')}
                    </Tabs.TabPane>

                    <Tabs.TabPane tab={
                        <a>
                            <span>Declined</span>
                        </a>
                        } key="declined_products">
                            
                            {this.list(this.state.declined_products, 'declined')}
                    </Tabs.TabPane>

                    <Tabs.TabPane tab={
                        <a>
                            <span>Featured</span>
                        </a>
                        } key="featured_products">
                            
                            {this.list(this.state.featured_products, 'featured')}
                    </Tabs.TabPane>

                </Tabs>
            </>
        )
    }

    loadProducts = (account_token, type) => {
        
        var formData = new FormData();
        
        formData.append('account_token', account_token);
        formData.append('type', type);
        
        var that = this;

        this.setState({loading: true});
        Api.post('freelancer/product_listing', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){
            
                if(data.products.length > 0){

                    if(type == 'active'){
                        that.setState({active_products: data.products});
                    }else if(type == 'paused'){
                        that.setState({paused_products: data.products});
                    }else if(type == 'pending'){
                        that.setState({pending_products: data.products});
                    }else if(type == 'declined'){
                        that.setState({declined_products: data.products});
                    }else if(type == 'featured'){
                        that.setState({featured_products: data.products});
                    }else{
                        that.setState({all_products: data.products});
                    }
                    
                }else{

                    that.setState({products: []});
                    LayoutHelper.addErrorMessage(that, data.message);
                }
            }else{

                // if(data.code == 'no_account'){
                //     window.location = Api.url + '/logout';
                // }
                LayoutHelper.addErrorMessage(that, data.message);
            }
        });
    }
}

export default ProductsListComponent;