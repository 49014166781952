import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { Row, Col, Input, Tag, Button, Slider, Rate } from 'antd';

import FreelancersBannerSection from '../../components/sections/freelancers/FreelancersBannerSection';
import FreelancersLeftDetailsSection from '../../components/sections/freelancers/FreelancersLeftDetailsSection';
import FreelancersRightDetailsSection from '../../components/sections/freelancers/FreelancersRightDetailsSection';

import Api from '../../api/Api';

import Main from '../../components/Main';

class FreelancersProfilePage extends Component { 
    constructor(props) {
        super();
        this.state = {
            // edit_link: true,
            redirect: false,

            freelancer: false,
            freelancer_domains: [],
            freelancer_skills: [],
            freelancer_tools: [],

            experience_details: [],

            certification_details: [],

            education_details: [],

            employment_details: [],

            portfolios: [],
            products: [],
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        // var user = localStorage.getItem('user');

        // if(account_token){
        //     this.setState({account_token: account_token});
        // }

        // if(user){

        //     var _user = JSON.parse(user);
        //     this.setState({freelancer: _user});
        // }

        this.loadData();
    }

    render () {
        if(this.state.redirect){
            return <Redirect to='/'/>;
        }


        return (

            <Main page="account_profile_view">
                <div className="freelancers-profile inner-page pt-0">
                    <FreelancersBannerSection edit_link={this.state.edit_link} freelancer={this.state.freelancer} />

                    <div className="container">
                        <Row className="left-right-column" gutter={16}>
                            <Col className="gutter-row" span={6}>
                                
                                {this.state.freelancer &&
                                
                                    <FreelancersLeftDetailsSection
                                        freelancer={this.state.freelancer}
                                        freelancer_domains={this.state.freelancer_domains}
                                        freelancer_skills={this.state.freelancer_skills}
                                        freelancer_tools={this.state.freelancer_tools}
                                        fb_link={this.state.fb_link}
                                        linkedin_link={this.state.linkedin_link}
                                    />
                                }
                            </Col>
                            <Col className="gutter-row" span={18}>

                                <FreelancersRightDetailsSection
                                    freelancer={this.state.freelancer}
                                    member_since={this.state.member_since}
                                    member_details={this.state.member_details}
                                    experience_details={this.state.experience_details}
                                    certification_details={this.state.certification_details}
                                    education_details={this.state.education_details}
                                    employment_details={this.state.employment_details}
                                    portfolios={this.state.portfolios}
                                    products={this.state.products}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
                
            </Main>
            
        )
    }


    loadData = () => {

        var slug = this.props.keyword;

        const formData = new FormData();
        formData.append('slug', slug);
        formData.append('ref', 'profile');

        var that = this;

        Api.post('freelancer/get_details', formData, function(data){

            if(data.status === false){

                that.setState({redirect: true});
            }else{

                var user = data.user;
                
                that.setState({
                    freelancer: data.user,

                    freelancer_domains: data.user.domain_formatted,
                    freelancer_skills: data.user.skills_formatted,
                    freelancer_tools: data.user.tools_formatted,
                    fb_link: data.user.fb_link,
                    linkedin_link: data.user.linkedin_link,

                    employment_details: data.employement,

                    certification_details: data.certificates,

                    education_details: data.education,

                    experience_details: data.experience,

                    portfolios: data.portfolio,
                    products: data.products,
                });

            }
            
        });
    }
}

export default FreelancersProfilePage;