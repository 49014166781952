import React, { Component } from 'react';
import { Tabs, Timeline, Tag, Carousel, Rate, Button } from 'antd';

import { Link } from "react-router-dom";

// import coffeecupsimage from '../../../assets/images/blog/coffee-cups.jpg';
import tshirtimage from '../../../assets/images/blog/t-shirt.jpg';

import profileimage from '../../../assets/images/anne-peres.png';
import clientimage from '../../../assets/images/anne-peres.png';

import SingleProduct from '../../products/FreelancerProductBlock';

const { TabPane } = Tabs;

const props = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
};

class FreelancersRightDetailsSection extends Component { 
    constructor(props) {
        super();
        this.state = {
            sizeSmall: 'small',
            sizeLarge: 'large',
            rate_tooltips: ['terrible', 'bad', 'normal', 'good', 'wonderful']
        }
    }

    renderExperiences = () => {

        const experiencesList = [];

        for(let i = 0; i < this.props.experience_details.length; i++) {
            let key = 'exp_'+i;

            let d = new Date(this.props.experience_details[i].added_on);
            let year = d.getFullYear();

            experiencesList.push(
                <div className="block" key={key}>
                    <div className="small-text">{year}</div>
                    <div className="title">{this.props.experience_details[i].title}</div>
                    <div className="text">{this.props.experience_details[i].description}</div>
                </div>
            );
        }
        return experiencesList;
    }

    renderCertifications = () => {

        const certificationsList = [];
        for(let i = 0; i < this.props.certification_details.length; i++) {
            let key = 'cert_'+i;

            certificationsList.push(

                <div className="block" key={key}>
                    <div className="num">{i + 1}</div>
                    <div className="title">{this.props.certification_details[i].certificate_name}</div>
                    <div className="d-flex details">
                        <div className="text">{this.props.certification_details[i].certificate_from}</div>
                        <div className="small-text">{this.props.certification_details[i].year_of_certificate}</div>
                    </div>
                </div>
            );
        }
        return certificationsList;
    }

    renderEducations = () => {

        const educationsList = [];
        for(let i = 0; i < this.props.education_details.length; i++) {
            let key = 'edu_'+i;

            educationsList.push(

                <div className="block" key={key}>
                    <div className="num">{i + 1}</div>
                    <div className="title">{this.props.education_details[i].major}</div>
                    <div className="d-flex details">
                        <div className="text">{this.props.education_details[i].university}</div>
                        <div className="small-text">{this.props.education_details[i].year_of_passing}</div>
                    </div>
                </div>
            );
        }
        return educationsList;
    }

    renderEmployments = () => {

        const employmentsList = [];

        for(let i = 0; i < this.props.employment_details.length; i++) {
            let key = 'emp_'+i;

            let _technologies = {};
            var technologies = this.props.employment_details[i].technologies;

            for (var j = 0; j < technologies.length; j++){      // convert to object
                _technologies[j] = technologies[j];
            }

            let technologies_text = '';
            for (var t of Object.keys(_technologies)) {         // loop through object
                if(t != 0){
                    technologies_text += ', ';
                }
                technologies_text += _technologies[t];
            }

            employmentsList.push(

                <Timeline.Item key={key}>
                    <div className="block">
                        <div className="title">{this.props.employment_details[i].title}</div>
                        <div className="text">{this.props.employment_details[i].company_name}</div>
                        <div className="time">{this.props.employment_details[i].from_year} - {this.props.employment_details[i].to_year}</div>
                        <div className="text">{this.props.employment_details[i].description}</div>
                        <div className="technologies">
                            <span className="bold-text">Technologies:</span>
                            <span className="text">{technologies_text}</span>
                        </div>
                    </div>
                </Timeline.Item>
            );
        }
        return employmentsList;
    }

    renderSkills = (skills) => {
        const skillsList = [];
        var remaining = 0;

        for(let s = 0; s < skills.length; s++) {
            let key = 'my_skill_'+s;
            if(s > 4){
                remaining++; 
            }else{
                skillsList.push(
                    <Tag key={key} className="btn btn-blank-brown">{skills[s]}</Tag>
                );
            }
            
        }

        if(remaining > 0){
            let key = 'my_skills_1';
            skillsList.push(
                <span key={key} className="total-other">+{remaining}</span>
            );
        }

        return skillsList;
    }


    renderPortfolios = () => {

        const portfoliosList = [];

        for(let i = 0; i < this.props.portfolios.length; i++) {
            let key = 'pf_'+i;

            portfoliosList.push(

                <div className="block" key={key}>
                    {/* <Link to={this.props.portfolios[i].link} className="img-block">
                        <img src={this.props.portfolios[i].main_image_preview} className="project-image" alt="Portfolio Image" />
                    </Link> */}
                    <div className="img-block">
                        <img src={this.props.portfolios[i].main_image_preview} className="project-image" alt="Portfolio Image" />
                    </div>
                    <div className="title">{this.props.portfolios[i].title}</div>
                    <div className="tags">
                        {this.renderSkills(this.props.portfolios[i].skills)}
                    </div>
                </div>
            );
        }
        return portfoliosList;
    }
    
    renderProductBlocks = () => {

        const productBlocksList = [];
        for(let i = 0; i < this.props.products.length; i++) {
            let key = 'prt_'+i;

            productBlocksList.push(

                <SingleProduct key={'active_' + key} product={this.props.products[i]} />
            );
        }
        return productBlocksList;
    }

    render () {

        return (
            <div className="freelancers-profile-right-section">

                <Tabs defaultActiveKey="1" centered>
                    <TabPane tab="About" key="1">
                        <div className="about-tab">
                            <div className="section main-details">
                                <div className="small-text">Member since {this.props.freelancer.member_since}</div>
                                <div className="title">{this.props.freelancer.industry_role}</div>
                                <div className="short-desc">{this.props.freelancer.story}</div>
                                <div className="text">{this.props.freelancer.description}</div>
                            </div>

                            {/* <div className="section experience-details">
                                <div className="heading"><i className="ico girl"></i>Experience</div>
                                <div className="blocks">
                                    {this.renderExperiences()}

                                </div>
                                
                                <div className="text-center">
                                    <Link to="" className="btn btn-blue">View All</Link>
                                </div>
                            </div> */}

                            <div className="section certification-details">
                                <div className="heading"><i className="ico degree"></i>Certification</div>
                                <div className="blocks">

                                    {this.renderCertifications()}
                                </div>
                                
                                <div className="text-center">
                                    <Link to="" className="btn btn-blue">View All</Link>
                                </div>
                            </div>

                            <div className="section certification-details education-details">
                                <div className="heading"><i className="ico degree"></i>Education</div>
                                <div className="blocks">

                                    {this.renderEducations()}
                                </div>
                                
                                <div className="text-center">
                                    <Link to="" className="btn btn-blue">View All</Link>
                                </div>
                            </div>

                            <div className="section employment-details">
                                <div className="heading"><i className="ico briefcase"></i>Employment</div>
                                <Timeline mode="alternate">

                                    {this.renderEmployments()}
                                </Timeline>
                                
                                <div className="text-center">
                                    <Link to="" className="btn btn-blue">View All</Link>
                                </div>
                            </div>

                        </div>
                    </TabPane>

                    <TabPane tab="Portfolio" key="2">
                        <div className="portfolio-tab">
                            <div className="blocks">

                                {this.renderPortfolios()}
                                
                            </div>
                        </div>
                    </TabPane>

                    <TabPane tab="Products/Offers" key="3">
                        {/* <div className="products-offers-tab">
                            <Carousel ref={node => (this.carousel = node)} {...props}>
                                <div className="single-offer">
                                    <Link to="" className="top-block">
                                        <img src={tshirtimage} className="offer-image" alt="Offer Image" />
                                        <span className="featured-text">Featured</span>
                                        <span className="wishlist-count">
                                            <i className="ico heart"></i> 10
                                        </span>
                                    </Link>
                                    <div className="mid-block">
                                        <div className="title">Create responsive woocommerce website</div>
                                        <div className="last-activity">Last activity: 2 years ago</div>
                                        <div className="ratings-price">
                                            <div className="ratings">
                                                <Rate tooltips={this.state.rate_tooltips} size={this.state.sizeLarge} value="3" />
                                                <div className="rating-text">3.2 (120)</div>
                                            </div>
                                            <span className="price-text">
                                                <span className="text">Price:</span>
                                                <span className="price">$199</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="bottom-block">
                                        <div className="sold-text">
                                            <span className="num">0</span>
                                            <span className="text">sold</span>
                                        </div>
                                        <div className="buttons-block">
                                            <Link to="" className="btn btn-blue">View</Link>
                                            <Link to="" className="btn blank-blue-btn">Contact</Link>
                                        </div>
                                    </div>
                                </div>
                            </Carousel>
                        </div> */}

                        <div className="products-listing products_offers">
                            <Carousel ref={node => (this.carousel = node)} {...props}>
                                {this.renderProductBlocks()}
                            </Carousel>
                        </div>
                    </TabPane>

                    <TabPane tab="Reviews" key="4">
                        <div className="reviews-tab">
                            <div className="section">
                                <div className="block orange-light-bg">
                                    <div className="title">Add google adwords tracking codes into my wordpress website head</div>
                                    <div className="links">
                                        <span>
                                            <i className="ico user"></i>
                                            <span className="num">Adam Joe</span>
                                        </span>
                                        <span>
                                            <i className="ico clock"></i>
                                            <span className="num">3 hrs ago</span>
                                        </span>
                                        <span>
                                            <i className="ico heart"></i>
                                            <span className="num">10</span>
                                        </span>
                                    </div>
                                </div>
                                
                                <div className="block review">
                                    <div className="img-block">
                                        <img src={profileimage} className="profile-image" alt="Profile Image" />
                                    </div>
                                    <div className="text-block">
                                        <div className="name">
                                            <div className="text">Erica Jensen</div>
                                            <div className="ratings">
                                                <Rate tooltips={this.state.rate_tooltips} size={this.state.sizeLarge} value="3" />
                                                <div className="rating-text">3.2 (120)</div>
                                            </div>
                                        </div>
                                        <div className="location">
                                            <i className="ico location"></i>
                                            <span className="num">New Delhi, India</span>
                                        </div>
                                        <div className="text">Excellent work! Highly recommend</div>
                                        
                                    </div>
                                    <div className="added-block">
                                        <span>
                                            <i className="ico clock"></i>
                                            <span className="num">3 hrs ago</span>
                                        </span>
                                    </div>
                                    
                                </div>
                            </div>

                            <div className="section">
                                <div className="block blue-light-bg">
                                    <div className="left-block">
                                        <img src={clientimage} className="profile-image" alt="Profile Image" />
                                    </div>
                                    <div className="mid-block">
                                        <div className="title">Design & develop bespoke wordpress website</div>
                                        <div className="links">
                                            <span>
                                                <i className="ico user"></i>
                                                <span className="num">Adam Joe</span>
                                            </span>
                                            <span>
                                                <i className="ico clock"></i>
                                                <span className="num">3 hrs ago</span>
                                            </span>
                                            <span>
                                                <i className="ico heart"></i>
                                                <span className="num">10</span>
                                            </span>
                                            <span>
                                                <i className="ico sold"></i>
                                                <span className="num">1357</span>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="right-block">
                                        <div className="price">$615</div>
                                    </div>
                                </div>
                                
                                <div className="block review">
                                    <div className="img-block">
                                        <img src={profileimage} className="profile-image" alt="Profile Image" />
                                    </div>
                                    <div className="text-block">
                                        <div className="name">
                                            <div className="text">Erica Jensen</div>
                                            <div className="ratings">
                                                <Rate tooltips={this.state.rate_tooltips} size={this.state.sizeLarge} value="3" />
                                                <div className="rating-text">3.2 (120)</div>
                                            </div>
                                        </div>
                                        <div className="location">
                                            <i className="ico location"></i>
                                            <span className="num">New Delhi, India</span>
                                        </div>
                                        <div className="text">John was great to work with and delivered the work on time as promised. I would highly recommend his services.</div>
                                        
                                    </div>
                                    <div className="added-block">
                                        <span>
                                            <i className="ico clock"></i>
                                            <span className="num">3 hrs ago</span>
                                        </span>
                                    </div>
                                </div>

                                <div className="block review reply">
                                    <div className="img-block">
                                        <img src={profileimage} className="profile-image" alt="Profile Image" />
                                    </div>
                                    <div className="text-block">
                                        <div className="name">
                                            <div className="text">Erica Jensen</div>
                                        </div>
                                        <div className="location">
                                            <i className="ico location"></i>
                                            <span className="num">New Delhi, India</span>
                                        </div>
                                        <div className="text">Thank you, It was great to work with you. Your excellent communication and clear vision helped me to deliver this project on time. Look forward to work with you.</div>
                                    </div>
                                </div>

                                <div className="block review">
                                    <div className="img-block">
                                        <img src={clientimage} className="profile-image" alt="Profile Image" />
                                    </div>
                                    <div className="text-block">
                                        <div className="name">
                                            <div className="text">Erica Jensen</div>
                                            <div className="ratings">
                                                <Rate tooltips={this.state.rate_tooltips} size={this.state.sizeLarge} value="3" />
                                                <div className="rating-text">3.2 (120)</div>
                                            </div>
                                        </div>
                                        <div className="location">
                                            <i className="ico location"></i>
                                            <span className="num">New Delhi, India</span>
                                        </div>
                                        <div className="text">Excellent work! Highly recommend</div>
                                        
                                    </div>
                                    <div className="added-block">
                                        <span>
                                            <i className="ico clock"></i>
                                            <span className="num">3 hrs ago</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPane>

                    <TabPane tab="Purchase" key="5">
                        <div className="purchase-tab">
                        <div className="section">
                                <div className="block blue-light-bg">
                                    <div className="left-block">
                                        <img src={clientimage} className="profile-image" alt="Profile Image" />
                                    </div>
                                    <div className="mid-block">
                                        <div className="title">Design & develop bespoke wordpress website</div>
                                        <div className="links">
                                            <span>
                                                <i className="ico user"></i>
                                                <span className="num">Adam Joe</span>
                                            </span>
                                            <span>
                                                <i className="ico clock"></i>
                                                <span className="num">3 hrs ago</span>
                                            </span>
                                            <span>
                                                <i className="ico heart"></i>
                                                <span className="num">10</span>
                                            </span>
                                            <span>
                                                <i className="ico sold"></i>
                                                <span className="num">1357</span>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="right-block">
                                        <div className="price">$615</div>
                                    </div>
                                </div>
                                
                                <div className="block review">
                                    <div className="img-block">
                                        <img src={profileimage} className="profile-image" alt="Profile Image" />
                                    </div>
                                    <div className="text-block">
                                        <div className="name">
                                            <div className="text">Erica Jensen</div>
                                            <div className="ratings">
                                                <Rate tooltips={this.state.rate_tooltips} size={this.state.sizeLarge} value="3" />
                                                <div className="rating-text">3.2 (120)</div>
                                            </div>
                                        </div>
                                        <div className="location">
                                            <i className="ico location"></i>
                                            <span className="num">New Delhi, India</span>
                                        </div>
                                        <div className="text">John was great to work with and delivered the work on time as promised. I would highly recommend his services.</div>
                                        
                                    </div>
                                    <div className="added-block">
                                        <span>
                                            <i className="ico clock"></i>
                                            <span className="num">3 hrs ago</span>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="section">
                                <div className="block blue-light-bg">
                                    <div className="left-block">
                                        <img src={clientimage} className="profile-image" alt="Profile Image" />
                                    </div>
                                    <div className="mid-block">
                                        <div className="title">Design & develop bespoke wordpress website</div>
                                        <div className="links">
                                            <span>
                                                <i className="ico user"></i>
                                                <span className="num">Adam Joe</span>
                                            </span>
                                            <span>
                                                <i className="ico clock"></i>
                                                <span className="num">3 hrs ago</span>
                                            </span>
                                            <span>
                                                <i className="ico heart"></i>
                                                <span className="num">10</span>
                                            </span>
                                            <span>
                                                <i className="ico sold"></i>
                                                <span className="num">1357</span>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="right-block">
                                        <div className="price">$615</div>
                                    </div>
                                </div>
                                
                                <div className="block review">
                                    <div className="img-block">
                                        <img src={profileimage} className="profile-image" alt="Profile Image" />
                                    </div>
                                    <div className="text-block">
                                        <div className="name">
                                            <div className="text">Erica Jensen</div>
                                            <div className="ratings">
                                                <Rate tooltips={this.state.rate_tooltips} size={this.state.sizeLarge} value="3" />
                                                <div className="rating-text">3.2 (120)</div>
                                            </div>
                                        </div>
                                        <div className="location">
                                            <i className="ico location"></i>
                                            <span className="num">New Delhi, India</span>
                                        </div>
                                        <div className="text">John was great to work with and delivered the work on time as promised. I would highly recommend his services.</div>
                                        
                                    </div>
                                    <div className="added-block">
                                        <span>
                                            <i className="ico clock"></i>
                                            <span className="num">3 hrs ago</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}

export default FreelancersRightDetailsSection;