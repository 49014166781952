import React, { Component } from 'react';

import { Carousel  } from 'antd';
import { Link } from "react-router-dom";
// import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import Api from '../api/Api';

import Main from '../components/Main';

class OurStoryPage extends Component { 
    constructor(props) {
        super();
        this.state = {
            main_heading: '',
            main_content: '',

            heading_2: '',
            slides_data: []
        }

        this.page = React.createRef();
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        this.setState({account_token: account_token});

        this.loadData();
    }

    renderSlides = () => {

        const slidesList = [];
        for(let i = 0; i < this.state.slides_data.length; i++) {
            let key = 'team_slide_'+i;
            slidesList.push(
                <div className="single-member" key={key}>
                    <div className="left-block">
                        <img src={this.state.slides_data[i].profile_pic_url} alt={this.state.slides_data[i].name} />
                    </div>
                    <div className="right-block">
                        <div className="name">{this.state.slides_data[i].name}</div>
                        <div className="designation">{this.state.slides_data[i].designation}</div>
                        <div className="text">
                            <div dangerouslySetInnerHTML={{ __html: this.state.slides_data[i].text }} />
                        </div>

                        <button className="btn btn-blue no-border">See More</button>
                    </div>
                </div>
            );
        }
        return slidesList;
    }

    render () {

        const props = {
            arrows: true,
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
        };

        return (

            <Main full_content={true}>
                <div className="inner-page white-bg">
                    <div className="container text-center">
                        <div className="heading text-center">{this.state.main_heading}</div>
                        <div dangerouslySetInnerHTML={{ __html: this.state.main_content }} />

                        <div className="heading text-center mt-5">{this.state.heading_2}</div>
                        <Carousel className="our-team" ref={node => (this.carousel = node)} {...props}>
                            {this.renderSlides()}
                        </Carousel>
                    </div>
                </div>
            </Main>
        )
    }


    loadData = () => {

        const formData = new FormData();
        var that = this;

        Api.post('cms/our_story', formData, function(data){
            
            that.setState({
                main_heading: data.main_heading,
                main_content: data.main_content,
                heading_2: data.heading_2,
                slides_data: data.slides_data
            });
        });
    }
}

export default OurStoryPage;