import React, { Component } from 'react';

import { Avatar, List, Tabs } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

import BannerSlider from '../components/sections/common/BannerSlider';

import Api from '../api/Api';

import Main from '../components/Main';

class TACSPage extends Component { 
    constructor(props) {
        super();
        this.state = {
            banners: [],

            tac_tabs: [],

            challenges_subheading: '',
            challenges_heading: '',
            challenges_img: '',
            challenges_points: [],
            
            gigfying_subheading: '',
            gigfying_heading: '',
            gigfying_content: '',
            
            tacs_reviews: [],
            
            value_proposition_subheading: '',
            value_proposition_heading: '',
            value_proposition_img: '',
            value_propositions: [],
            
            candidate_assessment_subheading: '',
            candidate_assessment_heading: '',
            candidate_assessments: [],
            
            talent_service_areas_img: '',
            talent_service_areas_subheading: '',
            talent_service_areas_heading: '',
            talent_service_areas: [],
            
            actionable_insights_heading: '',
            actionable_insights: '',
            
            engagement_functions_subheading: '',
            engagement_functions_heading: '',
            engagement_functions_img: '',
            engagement_functions: [],
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        this.setState({account_token: account_token});

        this.loadData();
    }

    renderChildCategoryTabs = () => {

        const ccategoriesList = [];
        for(let i = 0; i < this.state.tac_tabs.length; i++) {
            let key = 'cc_'+i;

            ccategoriesList.push(
                <Tabs.TabPane tab={<div><div className="image"><img src={this.state.tac_tabs[i].thumb} className="img-responsive" alt={this.state.tac_tabs[i].name} /></div><div>{this.state.tac_tabs[i].name}</div></div>} key={key}>
                    <div className="heading">{this.state.tac_tabs[i].name}</div>
                    <div className="block" dangerouslySetInnerHTML={{ __html: this.state.tac_tabs[i].description }} />
                </Tabs.TabPane>
            );
        }
        return ccategoriesList;
    }

    renderChallengesBlocks = () => {

        const finalBlocks = [];
        for(let i = 0; i < this.state.challenges_points.length; i++) {
            let key = 'cc_'+i;

            finalBlocks.push(
                <div class="block">
                    <div className="img">
                        <img src={this.state.challenges_points[i].image} alt={this.state.challenges_points[i].title} />
                    </div>
                    <div class="title">{this.state.challenges_points[i].title}</div>
                    <div class="text">{this.state.challenges_points[i].text}</div>
                </div>
            );
        }
        return finalBlocks;
    }

    renderReviewBlocks = () => {

        const finalBlocks = [];
        for(let i = 0; i < this.state.tacs_reviews.length; i++) {
            let key = 'cc_'+i;

            finalBlocks.push(
                <div class="block">
                    <div class="subtitle">{this.state.tacs_reviews[i].subheading}</div>
                    <div class="title">{this.state.tacs_reviews[i].heading}</div>
                    <div className="img-block">
                        <img src={this.state.tacs_reviews[i].image} alt={this.state.tacs_reviews[i].heading} />
                    </div>
                </div>
            );
        }
        return finalBlocks;
    }

    renderCandidateAssessBlocks = () => {

        const finalBlocks = [];
        for(let i = 0; i < this.state.candidate_assessments.length; i++) {
            let key = 'cc_'+i;

            finalBlocks.push(
                <div class="block">
                    <div class="num">{this.state.candidate_assessments[i].num}</div>
                    <div className="img-block">
                        <img src={this.state.candidate_assessments[i].image} alt={this.state.candidate_assessments[i].title} />
                    </div>
                    <div class="title">{this.state.candidate_assessments[i].title}</div>
                </div>
            );
        }
        return finalBlocks;
    }

    renderActionableBlocks = () => {

        const finalBlocks = [];
        for(let i = 0; i < this.state.actionable_insights.length; i++) {
            let key = 'cc_'+i;

            finalBlocks.push(
                <div class="block">
                    <div class="title">{this.state.actionable_insights[i].title}</div>
                    <div class="text">{this.state.actionable_insights[i].text}</div>
                </div>
            );
        }
        return finalBlocks;
    }

    render () {

        return (

            <Main page_title="Talent Acquisition & Continuity Services (TACS)">
                <div className="inner-page pt-0 white-bg">

                    <BannerSlider banners={this.state.banners} />

                    <div className="child-category-section tac-tabs">
                        <div className="container">
                            <Tabs defaultActiveKey="cc_1" centered>
                                {this.renderChildCategoryTabs()}
                            </Tabs>
                        </div>
                    </div>

                    <div className="challenges-block light-grey-bg">
                        <div className="container">
                            <div className="d-flex justify-content-between">
                                <div className="text-block d-flex-column justify-content-center">
                                    <div className="small-heading lg">{this.state.challenges_subheading}</div>
                                    <div className="heading lg">{this.state.challenges_heading}</div>

                                    <div className="blocks">
                                        {this.renderChallengesBlocks()}
                                    </div>
                                </div>
                                <div class="img-block">
                                    <img src={this.state.challenges_img} alt={this.state.challenges_heading} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="gigfy-block">
                        <div className="container">
                            <div className="d-flex justify-content-between">
                                <div class="left-block d-flex-column justify-content-center">
                                    <div className="small-heading lg">{this.state.gigfying_subheading}</div>
                                    <div className="heading lg">{this.state.gigfying_heading}</div>
                                </div>
                                <div className="right-block">
                                    <div className="content">
                                        <div dangerouslySetInnerHTML={{ __html: this.state.gigfying_content }} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container reviews-block">
                            <div className="blocks d-flex justify-content-between">
                                {this.renderReviewBlocks()}
                            </div>
                        </div>
                    </div>

                    <div className="value-prop-block light-grey-bg">
                        <div className="container">
                            <div className="d-flex justify-content-between">
                                <div class="img-block">
                                    <img src={this.state.value_proposition_img} alt={this.state.value_proposition_heading} />
                                </div>
                                <div className="text-block d-flex-column justify-content-center">
                                    <div className="small-heading lg">{this.state.value_proposition_subheading}</div>
                                    <div className="heading lg">{this.state.value_proposition_heading}</div>

                                    <List
                                        itemLayout="horizontal"
                                        dataSource={this.state.value_propositions}
                                        renderItem={item => (
                                        <List.Item>
                                            <List.Item.Meta
                                            avatar = {<Avatar icon={<CheckOutlined />} />}
                                            description={item}
                                            />
                                        </List.Item>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container candidate-assess-block">
                        <div className="small-heading lg text-center">{this.state.candidate_assessment_subheading}</div>
                        <div className="heading lg text-center">{this.state.candidate_assessment_heading}</div>

                        <div className="tac-steps d-flex justify-content-between">
                            {this.renderCandidateAssessBlocks()}
                        </div>
                    </div>

                    <div className="tsa-block light-grey-bg">
                        <div className="container">
                            <div className="d-flex justify-content-between">
                                <div class="img-block">
                                    <img src={this.state.talent_service_areas_img} alt={this.state.talent_service_areas_heading} />
                                </div>
                                <div className="text-block d-flex-column justify-content-center">
                                    <div className="small-heading lg">{this.state.talent_service_areas_subheading}</div>
                                    <div className="heading lg">{this.state.talent_service_areas_heading}</div>

                                    <List
                                        itemLayout="horizontal"
                                        dataSource={this.state.talent_service_areas}
                                        renderItem={item => (
                                        <List.Item>
                                            <List.Item.Meta
                                            avatar = {<Avatar icon={<CheckOutlined />} />}
                                            description={item}
                                            />
                                        </List.Item>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="ai-grey-block">
                        <div className="container">

                            <div className="heading lg text-center">{this.state.actionable_insights_heading}</div>

                            <div className="blocks d-flex justify-content-between">
                                {this.renderActionableBlocks()}
                            </div>
                        </div>
                    </div>

                    <div className="container hiw-block">
                        <div className="d-flex justify-content-between">
                            <div class="img-block">
                                <img src={this.state.engagement_functions_img} alt={this.state.engagement_functions_heading} />
                            </div>
                            <div className="text-block d-flex-column justify-content-center">
                                <div className="small-heading lg">{this.state.engagement_functions_subheading}</div>
                                <div className="heading lg">{this.state.engagement_functions_heading}</div>

                                <List
                                    itemLayout="horizontal"
                                    dataSource={this.state.engagement_functions}
                                    renderItem={item => (
                                    <List.Item>
                                        <List.Item.Meta
                                        avatar = {<Avatar icon={item.num} />}
                                        description={item.text}
                                        />
                                    </List.Item>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    
                </div>
            </Main>
        )
    }


    loadData = () => {

        const formData = new FormData();
        var that = this;

        Api.post('cms/tacs', formData, function(data){
            
            that.setState({
                banners: data.banners,

                tac_tabs: data.tac_tabs,

                challenges_subheading: data.challenges_subheading,
                challenges_heading: data.challenges_heading,
                challenges_img: data.challenges_img,
                challenges_points: data.challenges_points,
            
                gigfying_subheading: data.gigfying_subheading,
                gigfying_heading: data.gigfying_heading,
                gigfying_content: data.gigfying_content,
            
                tacs_reviews: data.tacs_reviews,
            
                value_proposition_subheading: data.value_proposition_subheading,
                value_proposition_heading: data.value_proposition_heading,
                value_proposition_img: data.value_proposition_img,
                value_propositions: data.value_propositions,
            
                candidate_assessment_subheading: data.candidate_assessment_subheading,
                candidate_assessment_heading: data.candidate_assessment_heading,
                candidate_assessments: data.candidate_assessments,
            
                talent_service_areas_img: data.talent_service_areas_img,
                talent_service_areas_subheading: data.talent_service_areas_subheading,
                talent_service_areas_heading: data.talent_service_areas_heading,
                talent_service_areas: data.talent_service_areas,
            
                actionable_insights_heading: data.actionable_insights_heading,
                actionable_insights: data.actionable_insights,

                engagement_functions_subheading: data.engagement_functions_subheading,
                engagement_functions_heading: data.engagement_functions_heading,
                engagement_functions_img: data.engagement_functions_img,
                engagement_functions: data.engagement_functions,
            });
        });
    }
}

export default TACSPage;