import React, { Component } from 'react';

import FeatherIcon from 'feather-icons-react';

import Api from '../../../api/Api';
import certificateimg from '../../../assets/images/certificate-bg.png';

import moment from 'moment';

import Loader from '../../../components/Loader';

import { Form, Select, Input, InputNumber, Button, Carousel, Popconfirm } from 'antd';
import { FileDoneOutlined } from '@ant-design/icons';

class Certifications extends Component { 
    constructor(props) {
        super();
        this.state = {
            loading: false,
            list: [],

            remove_loading: false,
            item: false,

            main_file_name: '',
            random_dir: '',
            media_url: '',
            upload_dir: '',

            image_uploading: false
        }
    }

    componentDidMount = () => {

        window.setTimeout(() => {
            this.loadCertificationList(this.props.account_token);
        });
    }

    render () {

        return (

            <>

                {this.props.account_token &&

                    <div style={{position:'relative'}}>
                        <div className="custom-carousel custom-employment-carousel">
                            <Carousel arrows={true}>
                                {this.renderList()}
                            </Carousel>

                        </div>
                        {this.state.remove_loading &&
                            <Loader />
                        }
                    </div>
                }
                {this.props.display
                    ?
                        this.certificationForm()
                    :
                        null
                }
            </>
        )
    }

    onCertificationSubmit = (values) => {
        var formData = new FormData();
        formData.append('account_token', this.props.account_token);

        if(this.state.item != false){

            formData.append('item_id', this.state.item.id);
        }

        if(this.state.random_dir != ''){

            formData.append('file', this.state.random_dir + this.state.main_file_name);
        }

        Object.keys(values).forEach((key) => {

            if(values[key]){
                formData.append(key, values[key])
            }
        });
        
        var that = this;

        this.setState({loading: true});
        Api.post('freelancer/profile_update_certificate', formData, function(data){
            
            that.setState({loading: false, item: false});
            
            if(data.status == true){
            
                that.setState({list: data.list, random_dir: '', main_file_name: '', media_url: '', upload_dir: ''});
                that.props.hide_form();
                that.props.updateUser(data.user);
            
            }else{
                that.props.onError(data.message);
            }
        });
    }

    certificationForm = () => {

        const layout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
        };

        const validateMessages = {
            required: '${label} is required!',
            types: {
                email: '${label} is not valid!',
                number: '${label} is not a valid number!',
            },
            number: {
              range: '${label} must be between ${min} and ${max}',
            },
        };

        return (

            <div className={"table-row table-row-employment-form"}>
            
                <Form {...layout} scrollToFirstError={true} name="update_certification_details" ref="update_certification_details" validateMessages={validateMessages} onFinish={this.onCertificationSubmit.bind(this)}>
            
                    <div className="d-flex form-item-block">
                        <Form.Item className="w-33" label="Certificate Name" name="certificate_name" rules={[{required:true}]}>
                            <Input />
                        </Form.Item>

                        <Form.Item className="w-33" label="Certificate From" name="certificate_from" rules={[{required:true}]}>
                            <Input />
                        </Form.Item>

                        <Form.Item className="w-33" label="Year" name="year_of_certificate" rules={[{required:true}]}>
                            <InputNumber max={new Date().getFullYear()} />
                        </Form.Item>
                    </div>

                    <div className="form-item-block profile-pic-box type-input">
                        <div className="picture">
                            <label htmlFor="profile_banner">Upload Your Certificate</label>
                            <div className="profile-pic-banner-bg solid" style={{cursor:'pointer'}} onClick={() => {
                                this.certificate_file.click();
                            }}>

                                <FeatherIcon icon="image" />
                                <p>Click here to upload the file.</p>
                                <input type="file" className="hidden" onChange={(e) => {this._handleImageChange(e)}} ref={(input)=> this.certificate_file = input}/>
                            </div>
                        </div>
                    </div>

                    {this.state.image_uploading &&
                        <Loader />
                    }

                    {this.state.random_dir != '' &&
                        <div className="form-item-block justify-center mb-2" style={{backgroundColor:'#F8F8F8', padding:10, alignItems:'center'}}>
                            <a href={this.state.media_url + this.state.upload_dir + this.state.random_dir + this.state.main_file_name} target="_blank">
                                <FileDoneOutlined style={{color:'#003965', fontSize:25}} />
                            </a>
                            <span>
                                <Popconfirm placement="left" title="Do you really want to remove this item?" onConfirm={() => {
                                    this.setState({random_dir: '', main_file_name: '', media_url: '', upload_dir: ''});
                                }} okText="Yes" cancelText="Cancel">
                                    <div className="pointer">
                                        <FeatherIcon icon="trash-2" />
                                    </div>
                                </Popconfirm>
                            </span>
                        </div>
                    }

                    <div className="text-right buttons">
                        <Button type="secondary" onClick={() => {
                            this.setState({item: false});
                            this.props.hide_form();
                        }}>Cancel</Button>
                        <Button className="ml-10" type="primary" htmlType="submit" loading={this.state.loading}>
                            {this.state.item ? 'Update' : '+ Add'}
                        </Button>
                    </div>
                </Form>
                
            </div>
        )
    }

    renderList = () => {

        if(this.state.list.length > 0){

            return this.state.list.map((certification_item, index) => {

                var key = 'certification_item_' + certification_item.id;
                return (

                    <div key={key} className={"row-employment-slider-"}>

                        <div className="block d-flex justify-content-between">
                            <div className="icon-block">
                                <img className="img-fluid" src={certificateimg} alt={certification_item.certificate_name} />
                                <span>{index + 1}</span>
                            </div>
                            <div className="text-block ml-20">
                                <div className="sl-title">{certification_item.certificate_name}</div>
                                <div className="sl-company-name">{certification_item.certificate_from}</div>
                                <div className="sl-from-to">{certification_item.year_of_certificate}</div>
                                <div className="sl-from-to mt-1">
                                    <a href={certification_item.media_url + certification_item.upload_dir + certification_item.random_dir + certification_item.main_file_name} target="_blank">
                                        <FileDoneOutlined style={{color:'#003965', fontSize:25}} />
                                    </a>
                                </div>
                            </div>
                            <div className="sl-buttons">
                                
                                <div className="pointer" onClick={() => {
                                    this.editItem(certification_item);
                                }}>
                                    Edit
                                </div>

                                <Popconfirm placement="left" title="Do you really want to remove this item?" onConfirm={() => {
                                    this.setState({item: false});
                                    this.remove(certification_item);
                                }} okText="Yes" cancelText="Cancel">
                                    <div className="pointer">
                                        Remove
                                    </div>
                                </Popconfirm>
                            </div>
                        </div>
                    </div>
                )
            })
        }
    }

    editItem = (item) => {

        this.props.display_form();

        window.setTimeout(() => {

            if(item.emp_from != '0000-00-00'){
            
                item.from = moment(item.emp_from);
            }
            if(item.emp_to != '0000-00-00'){
            
                item.to = moment(item.emp_to);
            }

            this.setState({item: item, random_dir: item.random_dir, main_file_name: item.main_file_name, media_url: item.media_url, upload_dir: item.upload_dir});
            this.refs.update_certification_details.setFieldsValue(item);
        });
        
    }

    renderTags = (tags) => {

        if(tags.length > 0){

            return tags.map((tag) => {

                var key = "tag_" + tag;
                return (

                    <span key={key} className="sl-single-tag">{tag}</span>
                )
            })
        }
    }

    remove = (item) => {

        var formData = new FormData();
        
        formData.append('account_token', this.props.account_token);
        formData.append('item_id', item.id);
        
        var that = this;

        this.setState({remove_loading: true});
        
        Api.post('freelancer/remove_certificate', formData, function(data){
            
            that.setState({remove_loading: false});
            if(data.status){
                that.setState({list: data.list});
            }
        });
    }

    loadCertificationList = (account_token) => {
        var formData = new FormData();
        formData.append('account_token', account_token);
        
        var that = this;

        this.setState({loading: true});
        Api.post('freelancer/get_certificates', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){
            
                that.setState({list: data.list});
            }
        });
    }

    _handleImageChange = async (e) => {
        e.preventDefault();
    
        let reader = new FileReader();
        let file = e.target.files[0];

        this.setState({image_uploading: true});
    
        reader.onloadend = () => {
            this.setState({
                updated: false,
                file: file,
                profile_preview_url: reader.result
            });
        }
    
        reader.readAsDataURL(file);

        var _sizes = {};
        _sizes['profile_pic_small'] = [100, 100];
        _sizes['profile_pic_medium'] = [300, 300];

        const formData = new FormData();
        formData.append('tmp_file_name', file);
        formData.append('allowed_types', 'jpg,jpeg,png,gif,pdf');
        formData.append('upload_dir', 'freelancer/certificates/');

        this.setState({image_uploading: true, random_dir: ''});
        var that = this;

        Api.post('imageuploader', formData, function(data){

            that.setState({image_uploading: false});

            if(data.status == 'success'){

                that.setState({random_dir: data.random_dir, main_file_name:data.main_file_name, upload_dir: data.upload_dir, media_url: data.media_url});
            }else{

                that.props.onError(data.message)
            }
        });
    }
}

export default Certifications;
