import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import { Card, Avatar, Progress, Typography, Button, Rate, Tag } from 'antd';

import { UserOutlined, ClockCircleOutlined, HeartOutlined, MailOutlined, CheckCircleOutlined } from '@ant-design/icons';
import FeatherIcon from 'feather-icons-react';

import Tags from '../Tags';

class ProjectRow extends Component { 
    
    render () {

        var project = this.props.project;
        
        if(project){
            
            return (

                <div className="row-item">
                    <div className="top-section d-flex justify-content-between">
                        <Link to={"/projects/details/" + project.slug} className="title">
                            <span>{project.title}</span>
                            {project.verified && (project.verified == 1)
                                ?
                                    <span className="verified"><CheckCircleOutlined /> Verified</span>
                                :
                                    null
                            }
                        </Link>
                        <div className="price-section">
                            <div className="price">{project.budget_formatted}</div>
                            <div className="type">{project.work_type}</div>
                        </div>
                    </div>
                    <div className="mid-section">
                        {project.featured && (project.featured == 1)
                            ?
                                <div className="tags">
                                    <Tag>Featured</Tag>
                                </div>
                            :
                                null
                        }
                        
                        <Typography.Paragraph className="user-about" ellipsis={{rows: 2}}>{project.description}</Typography.Paragraph>
                    </div>
                    <div className="bottom-section">
                        <div className="details">
                            <div className="single">
                                <UserOutlined />
                                <div className="text">{project.freelancer_name}</div>
                            </div>
                            <div className="single">
                                <ClockCircleOutlined />
                                <div className="text">{project.posting_time}</div>
                            </div>
                            <div className="single">
                                <HeartOutlined />
                                <div className="text">{project.likes}</div>
                            </div>
                            <div className="single">
                                <MailOutlined />
                                <div className="text">{project.proposals_count} proposal{project.proposals_count > 1 ? "'s" : ''}</div>
                            </div>
                        </div>

                        {this.props.user
                            ?
                                this.props.user.unq_index_id != project.user_unq_id
                                    ?
                                        <Link to={"/projects/details/" + project.slug} className="dark-blue-btn">Send Proposal</Link>
                                    :
                                        null
                            :
                                <Link to={"/projects/details/" + project.slug} className="dark-blue-btn">Send Proposal</Link>
                        }
                    </div>
                </div>
            )
        }else{

            return null;
        }
    }
}

export default ProjectRow;