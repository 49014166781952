import React, { Component } from 'react';
import { Link } from "react-router-dom";

import { Carousel } from 'antd';

class RelatedProjectsSection extends Component { 
    constructor(props) {
        super();
        this.state = {
            sizeLarge: 'large',
        }
    }
    

    render () {

        const props = {
            arrows: true,
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
        };

        return (
            <div className="related-projects-section inner-page listing-page">
                <div className="container">
                    <div className="heading pb-3 ">Related Projects</div>
                    
                    <div className="related-projects-listing">

                        {this.props.related_projects && this.props.related_projects.length == 1
                            ?

                                this.props.related_projects.map((project_item) => {

                                    var key = 'project_item_' + project_item.id;

                                    return this.renderCard(key, project_item)
                                })
                            :
                                <Carousel ref={node => (this.carousel = node)} {...props}>
                                    {this.props.related_projects && this.props.related_projects.length > 0
                                        ?
                                            this.props.related_projects.map((project_item) => {

                                                var key = 'project_item_' + project_item.id;

                                                return this.renderCard(key, project_item)
                                            })
                                            
                                        :
                                            null
                                    }
                                    
                                </Carousel>
                        }
                    </div>
                </div>
            </div>
        )
    }

    renderCard = (key, project_item) => {

        return (

            <div key={key} className="single-project">
                <div className="top-block">
                    {project_item.featured && (project_item.featured == 1)
                        ?
                            <div className="featured-text">Featured</div>
                        :
                            null
                    }
                    <Link to={"/projects/details/" + project_item.slug} onClick={() => {
                        
                        this.props.loadData(project_item.slug)

                    }} className="title">{project_item.title}</Link>
                    <div className="text">{project_item.description}</div>

                    <div className="links">
                        <span><i className="ico user"></i><span className="num">{project_item.user_name}</span></span>
                        <span><i className="ico clock"></i><span className="num">{project_item.posting_time}</span></span>
                        <span><i className="ico heart"></i><span className="num">{project_item.likes}</span></span>
                        <span><i className="ico email"></i><span className="num">{project_item.proposals} proposal</span></span>
                    </div>
                </div>
                <div className="bottom-block">
                    <div className="price-block">{project_item.budget_formatted}</div>
                    <button className="btn btn-blue no-border">Send Proposal</button>
                </div>
            </div>
        )
    }
}

export default RelatedProjectsSection;