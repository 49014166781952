import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

// add form components
import { Table, Tabs, Empty, Avatar } from 'antd';

import FeatherIcon from 'feather-icons-react';

import Main from '../../../components/Main';
import LayoutHelper from '../../../helpers/LayoutHelper';

import Api from '../../../api/Api';

class ProjectsBuyer extends Component { 
    constructor(props) {
        super();
        this.state = {

            error_message: '',
            success_message: '',

            projects: [],
            no_data: false,

            type: 'active',

            user: false,
            account_token: false
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');

        var _user = JSON.parse(user);
        this.setState({user: _user});

        if(account_token){
            
            this.setState({account_token: account_token});
        }else{
            // window.location = Api.url + '/logout';
        }

        this.loadProjects(account_token, this.state.type);
    }

    loadProjects = (account_token, type) => {
        
        var formData = new FormData();
        
        formData.append('account_token', account_token);
        formData.append('status', type);
        
        var that = this;

        this.setState({loading: true});
        Api.post('buyer/projects', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){
            
                if(data.projects.length > 0){

                    that.setState({projects: data.projects, no_data: false});
                }else{

                    that.setState({no_data: true, projects: []});
                }
            }else{

                // if(data.code == 'no_account'){
                //     window.location = Api.url + '/logout';
                // }
                // that.props.errorMessage(data.message);
            }
        });
    }
    
    render () {
        const tableColumns = [
            {
                title: 'Buyer / Client',
                dataIndex: 'buyer',
                key: 'buyer',
            },
            {
                title: 'Project',
                dataIndex: 'project',
                key: 'project',
            },
            {
                title: 'Target Date',
                dataIndex: 'targetdate',
                key: 'targetdate',
            },
            {
                title: 'Total',
                dataIndex: 'total',
                key: 'total',
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
            },
        ];

        const pendingTableColumns = [
            {
                title: 'Buyer / Client',
                dataIndex: 'buyer',
                key: 'buyer',
            },
            {
                title: 'Project',
                dataIndex: 'project',
                key: 'project',
            },
            {
                title: 'Total',
                dataIndex: 'total',
                key: 'total',
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
            },
        ];

        const projectTableColumns = [
            {title: 'Project', dataIndex: 'title', key: 'title', render: (name, row) => {

                return <Link to={"/project-proposals/" + row.project_unq_id}>{row.title}</Link>
            }},
            {title: 'Proposals', align:'center', dataIndex: 'proposals_count', key: 'proposals_count', render: (name, row) => {

                return <Link to={"/project-proposals/" + row.project_unq_id}>{row.proposals_count}</Link>
            }},
            {title: 'Start Date', dataIndex: 'start_date_formatted', key: 'start_date_formatted',},
            {title: 'Budget', dataIndex: 'budget_formatted', key: 'budget_formatted',},
            {title: 'Visibility', dataIndex: 'visibility', key: 'visibility', },
        ];

        const awardedTableColumns = [
            {title: 'Project', dataIndex: 'title', key: 'title', render: (name, row) => {

                return <Link to={"/project-flow/" + row.project_unq_id + '/' + row.awarded_freelancer}>{row.title}</Link>
            }},
            {title: 'Awarded', align:'left', dataIndex: 'freelancer_name', key: 'freelancer_name', render: (name, row) => {

                return (
                    <div>
                        <Avatar size={40} src={row.profile_pic_full} />
                        <span className="ml-5">{name}</span>
                    </div>
                )
            }},
            {title: 'Awarded On', dataIndex: 'awarded_on_date_formatted', key: 'awarded_on_date_formatted',},
            {title: 'Budget', dataIndex: 'budget_formatted', key: 'budget_formatted',},
            {title: 'Visibility', dataIndex: 'visibility', key: 'visibility', },
        ];

        return (

            <>
                <Main page_title="Manage Projects" hide_page_title="true" className="projects-as-buyer account-bg" page="as_buyer" error_message={this.state.error_message} success_message={this.state.success_message}>
                    <div className="container-lg">
                        
                        <div className="page-heading">Manage Projects (Buyer)</div>

                        <Tabs className="manage-projects-list" defaultActiveKey="active" tabPosition='left' onTabClick={(key, event) => {
                            this.setState({type: key});
                            this.loadProjects(this.state.account_token, key);
                        }}>
                            
                            <Tabs.TabPane tab={
                                <a>
                                    <FeatherIcon icon="slack" /><span>Open For Bids</span>
                                </a>
                                } key="active">

                                <div className="tab-heading">Open For Bids</div>
                                <Table columns={projectTableColumns} dataSource={this.state.projects}>
                                    <Empty />
                                </Table>

                            </Tabs.TabPane>

                            <Tabs.TabPane tab={
                                <a>
                                    <FeatherIcon icon="award" /><span>Awarded</span>
                                </a>
                                } key="awarded">
                                
                                <div className="tab-heading">Awarded</div>
                                <Table columns={awardedTableColumns} dataSource={this.state.projects}>
                                    <Empty />
                                </Table>
                            </Tabs.TabPane>

                            <Tabs.TabPane tab={
                                <a>
                                    <FeatherIcon icon="thumbs-up" /><span>Delivered</span>
                                </a>
                                } key="delivered">
                                
                                <div className="tab-heading">Delivered</div>
                                <Table columns={projectTableColumns}>
                                    <Empty />
                                </Table>
                            </Tabs.TabPane>

                            <Tabs.TabPane tab={
                                <a>
                                    <FeatherIcon icon="check-circle" /><span>Completed</span>
                                </a>
                                } key="completed">
                                
                                <div className="tab-heading">Completed</div>
                                <Table columns={projectTableColumns}>
                                    <Empty />
                                </Table>
                            </Tabs.TabPane>

                            <Tabs.TabPane tab={
                                <a>
                                    <FeatherIcon icon="x-circle" /><span>Cancelled</span>
                                </a>
                                } key="cancelled">
                                
                                <div className="tab-heading">Cancelled</div>
                                <Table columns={tableColumns}>
                                    <Empty />
                                </Table>
                            </Tabs.TabPane>

                            <Tabs.TabPane tab={
                                <a>
                                    <FeatherIcon icon="pause-circle" /><span>On-Hold</span>
                                </a>
                                } key="on_hold">
                                
                                <div className="tab-heading">On-Hold</div>
                                <Table columns={tableColumns}>
                                    <Empty />
                                </Table>
                            </Tabs.TabPane>

                            <Tabs.TabPane tab={
                                <a>
                                    <FeatherIcon icon="alert-triangle" /><span>Suspended</span>
                                </a>
                                } key="suspended">
                                
                                <div className="tab-heading">Suspended</div>
                                <Table columns={pendingTableColumns}>
                                    <Empty />
                                </Table>
                            </Tabs.TabPane>

                        </Tabs>
                    </div>
                </Main>
            </>
        )
    }
}

export default ProjectsBuyer;