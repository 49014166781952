import React, { Component } from 'react';

import FeatherIcon from 'feather-icons-react';

import Api from '../../api/Api';

import moment from 'moment';

import Loader from '../../components/Loader';

import { Form, Select, Input, InputNumber, Button, Carousel, Popconfirm, Row, Col } from 'antd';

class BankDetails extends Component { 
    constructor(props) {
        super();
        this.state = {
            loading: false,
            list: [],

            account_token: false,

            remove_loading: false,
            item: false,

            display: false,

            is_primary_selected: false,
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');

        this.setState({account_token: account_token});
        window.setTimeout(() => {
            this.loadBankList(account_token);
        });
    }

    render () {

        return (

            <>

                <div className="update-profile-page d-flex">
                    
                    <div className="profile-wrapper">
                        <Row>
                            <Col span={24}>
                                
                                <div className="profile-main-wrapper">
    
                                    <div className="page-heading">Bank Details</div>

                                    <div className="align-center mb-2">
                                        {!this.state.display &&
                                            <Button type="primary" onClick={() => {
                                                this.setState({display: true})
                                            }}>
                                                + Add Bank
                                            </Button>
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>

                {this.state.display
                    ?
                        this.bankForm()
                    :
                        null
                }

                {this.state.account_token &&

                    <div style={{position:'relative'}}>
                        
                        <div className="custom-carousel">
                            {this.renderList()}
                        </div>

                        {this.state.remove_loading &&
                            <Loader />
                        }
                    </div>
                }
                
            </>
        )
    }

    onBankSubmit = (values) => {
        var formData = new FormData();
        formData.append('account_token', this.state.account_token);

        if(this.state.item != false){

            formData.append('item_id', this.state.item.id);
        }

        Object.keys(values).forEach((key) => {

            if(values[key]){
                formData.append(key, values[key])
            }
        });
        
        var that = this;

        this.setState({loading: true});
        Api.post('freelancer/profile_update_bank', formData, function(data){
            
            that.setState({loading: false, item: false});
            
            if(data.status == true){
            
                that.setState({list: data.list, display: false});
                that.props.updateUser(data.user);
            
            }else{
                that.props.onError(data.message);
            }
        });
    }

    bankForm = () => {

        const layout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
        };

        const validateMessages = {
            required: '${label} is required!',
            types: {
                email: '${label} is not valid!',
                number: '${label} is not a valid number!',
            },
            number: {
              range: '${label} must be between ${min} and ${max}',
            },
        };

        return (

            <div className={"table-row table-row-employment-form"}>
            
                <Form {...layout} scrollToFirstError={true} name="update_bank_details" ref="update_bank_details" validateMessages={validateMessages} onFinish={this.onBankSubmit.bind(this)}>
            
                    <div className="d-flex form-item-block">
                        <Form.Item className="w-50" label="Bank Name" name="bank_name" rules={[{required:true}]}>
                            <Input />
                        </Form.Item>
                        <Form.Item className="w-50" label="IFSC Code" name="ifsc_code" rules={[{required:true}]}>
                            <Input />
                        </Form.Item>
                    </div>
                    <div className="d-flex form-item-block">
                        <Form.Item className="w-66" label="Bank Address" name="bank_address" rules={[{required:true}]}>
                            <Input />
                        </Form.Item>

                        {!this.state.is_primary_selected &&
                        
                            <Form.Item className="w-33" label="Is Primary" name="is_primary" rules={[{required:true}]}>
                                <Select>
                                    <Select.Option value="1">Yes</Select.Option>
                                    <Select.Option value="0">No</Select.Option>
                                </Select>
                            </Form.Item>
                        }
                    </div>

                    <div className="text-right buttons">
                        <Button type="secondary" onClick={() => {
                            this.setState({item: false, display: false});
                        }}>Cancel</Button>
                        <Button className="ml-10" type="primary" htmlType="submit" loading={this.state.loading}>+ Add</Button>
                    </div>
                </Form>
                
            </div>
        )
    }

    renderList = () => {

        if(this.state.list.length > 0){

            var n = 0;

            return this.state.list.map((bank_item) => {

                var key = 'bank_item_' + bank_item.id;
                n = n + 1;
                var num = n;
                return (

                    <div key={key} className={"row-employment-slider- mb-2"}>

                        <div className="block d-flex justify-content-between">
                            <div className="icon-block">
                                <div className="align-center" style={{backgroundColor:'#003965', borderRadius:'50%', width:50, height:50}}>
                                    <span style={{color:'#fff', top:10}}>{num}</span>
                                </div>
                            </div>
                            <div className="text-block ml-20">
                                <div className="sl-title">{bank_item.bank_name}</div>
                                <div className="sl-company-name">{bank_item.ifsc_code}</div>
                                <div className="sl-from-to">{bank_item.bank_address}</div>
                            </div>
                            <div className="sl-buttons">
                                
                                {bank_item.is_primary == 1 &&
                                    <span style={{backgroundColor:'#A24921', marginRight:15, fontSize:12, padding:'2px 10px', color:'#fff'}}>Primary</span>
                                }
                                <div className="pointer" onClick={() => {
                                    this.editItem(bank_item);
                                }}>
                                    Edit
                                </div>

                                <Popconfirm placement="left" title="Do you really want to remove this item?" onConfirm={() => {
                                    this.setState({item: false});
                                    this.remove(bank_item);
                                }} okText="Yes" cancelText="Cancel">
                                    <div className="pointer">
                                        Remove
                                    </div>
                                </Popconfirm>
                            </div>
                        </div>
                    </div>
                )
            })
        }
    }

    editItem = (item) => {

        this.setState({display: true});

        window.setTimeout(() => {

            if(item.emp_from != '0000-00-00'){
            
                item.from = moment(item.emp_from);
            }
            if(item.emp_to != '0000-00-00'){
            
                item.to = moment(item.emp_to);
            }

            this.setState({item: item});
            this.refs.update_bank_details.setFieldsValue(item);
        });
        
    }

    renderTags = (tags) => {

        if(tags.length > 0){

            return tags.map((tag) => {

                var key = "tag_" + tag;
                return (

                    <span key={key} className="sl-single-tag">{tag}</span>
                )
            })
        }
    }

    remove = (item) => {

        var formData = new FormData();
        
        formData.append('account_token', this.state.account_token);
        formData.append('item_id', item.id);
        
        var that = this;

        this.setState({remove_loading: true});
        
        Api.post('freelancer/remove_bank', formData, function(data){
            
            that.setState({remove_loading: false});
            if(data.status){
                that.setState({list: data.list});
            }
        });
    }

    loadBankList = (account_token) => {
        var formData = new FormData();
        formData.append('account_token', account_token);
        
        var that = this;

        this.setState({loading: true});
        Api.post('freelancer/get_bank_details', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){
            
                that.setState({list: data.list});

                if(data.list.length > 0){

                    let is_primary_selected = false;

                    data.list.forEach((_bank) => {

                        if(_bank.is_primary == 1){

                            is_primary_selected = true;
                        }
                    })

                    that.setState({is_primary_selected: true});
                }
            }
        });
    }
}

export default BankDetails;
