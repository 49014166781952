import React, { Component } from 'react';

import { Link } from "react-router-dom";

class HomeMoneyProtection extends Component { 
    constructor(props) {
        super();
        this.state = {
        }
    }

    renderMoneyBlockNums = () => {

        const moneyProtectionsList = [];
        for(let i = 0; i < this.props.money_protection_blocks.length; i++) {
            let key = 'mpn_'+i;
            let link = this.props.money_protection_blocks[i].link;

            moneyProtectionsList.push(
                <div className="block" key={key}>
                    <span className="text">{i + 1}</span>
                </div>
            );
        }
        return moneyProtectionsList;
    }

    renderMoneyBlocks = () => {

        const moneyProtectionsList = [];
        for(let i = 0; i < this.props.money_protection_blocks.length; i++) {
            let key = 'mp_'+i;
            let link = this.props.money_protection_blocks[i].link;

            moneyProtectionsList.push(
                <div className="block" key={key}>
                    <div className="img-block" dangerouslySetInnerHTML={{ __html: this.props.money_protection_blocks[i].icon }} />
                    <div className="block-title">{this.props.money_protection_blocks[i].title}</div>
                    <span className="block-text">{this.props.money_protection_blocks[i].text}</span>
                    <Link to={link} className="read-more"><i className="ico blue-arrow"></i></Link>
                </div>
            );
        }
        return moneyProtectionsList;
    }

    render () {

        return (

            <div className="money-protection text-center">
                <div className="container">
                    <div className="money-protection-block">
                        <div className="small-heading">{this.props.money_protection.small_text}</div>
                        <div className="heading">{this.props.money_protection.title}</div>
                        <div className="text">{this.props.money_protection.text}</div>

                        <div className="circle-blocks">
                            {this.renderMoneyBlockNums()}
                        </div>
                        <div className="blocks">
                            {this.renderMoneyBlocks()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default HomeMoneyProtection;