import React, { Component } from 'react';
import { Row, Col, Divider, Select, Input, Tag, Button, Pagination, Checkbox, Slider, Rate } from 'antd';

import { Link } from "react-router-dom";

class FreelancersLeftDetailsSection extends Component { 
    constructor(props) {
        super();
        this.state = {
            sizeSmall: 'small',
            sizeLarge: 'large',
        }
    }

    renderCounts = (counts) => {
        
        const countsList = [];
        var remaining = 0;

        if(counts && counts.length > 0){

            for(let s = 0; s < counts.length; s++) {
                let key = 'tag_'+s;

                if(s > 4){
                    remaining++; 
                }else{
                    countsList.push(
                        <Tag key={key} className="btn btn-blank-brown">{counts[s]}</Tag>
                    );
                }
            }

            if(remaining > 0){
                let key = 'tags_1';
                countsList.push(
                    <span key={key} className="total-other">+{remaining}</span>
                );
            }
        }

        return countsList;
    }

    renderAvailability = (availability) => {

        const _text = availability.replace('_', ' ');

        return _text.toUpperCase();
    }

    render () {

        return (
            <div className="freelancers-profile-left-section">

                <div className="section">
                    <div className="heading">Availability</div>
                    <div className="text">
                        {(this.props.freelancer.availability != '')
                            ? 
                                this.renderAvailability(this.props.freelancer.availability)
                            :
                                ''
                        }
                    </div>
                </div>

                <div className="section buttons">
                    <Button type="default" className="btn btn-blue">Hire Me</Button>
                    <Button type="default" className="btn blank-blue-btn">Contact Me</Button>
                </div>

                <div className="section">
                    <div className="heading">Category</div>
                    <div className="tags">

                        <div className="tags">
                            {/* {this.renderCounts(this.props.freelancer.job_industry != '' ? this.props.freelancer.job_industry.split(',') : [])} */}
                            {this.renderCounts(this.props.freelancer.job_industry != '' ? this.props.freelancer.job_industry : [])}
                        </div>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">Skills</div>
                    <div className="tags">

                        <div className="tags" key="dom_1">
                            {this.renderCounts(this.props.freelancer_skills)}
                        </div>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">Tools</div>
                    <div className="tags">

                        <div className="tags" key="dom_1">
                            {this.renderCounts(this.props.freelancer_tools)}
                        </div>
                    </div>
                </div>
                
                {(this.props.fb_link && (this.props.fb_link != '')) || (this.props.linkedin_link && (this.props.linkedin_link != ''))
                    ?
                        <div className="section">
                            <div className="heading">Social accounts</div>
                            <ul className="list-inline d-flex social-links">
                                {/* {this.props.fb_link && (this.props.fb_link != '')
                                    ?
                                        <li><Link to={this.props.fb_link} target="_blank"><i className="ico facebook"></i></Link></li>
                                    :
                                        null
                                } */}
                                
                                {this.props.linkedin_link && (this.props.linkedin_link != '')
                                    ?
                                        <li><Link to={this.props.linkedin_link} target="_blank"><i className="ico linkedin"></i></Link></li>
                                    :
                                        null
                                }
                            </ul>
                        </div>
                    :
                        null
                }
                
            </div>
        )
    }
}

export default FreelancersLeftDetailsSection;