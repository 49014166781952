import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { Row, Col, Tabs, Form, Input, InputNumber, Upload, Rate, message, Checkbox, Modal, Popconfirm, Button, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import FeatherIcon from 'feather-icons-react';

import Main from '../../components/Main';

import Api from '../../api/Api';
import LayoutHelper from '../../helpers/LayoutHelper';

import paymentmethodimg from '../../assets/images/payment-method.png';
import SingleReview from '../../components/products/SingleReview';

class ProductDetailsPage extends Component { 
    constructor(props) {
        super();
        this.state = {

            error_message: '',
            success_message: '',
            
            user: false,
            account_token: false,

            product: false,
            // product: [],
            gallery: [],
            reviews: [],
            buyer_tips: [],
            how_it_works: [],

            buy_count: 1,
            selected_addons: [],

            buy_clicked: false,
            sub_total_per_product: 0,
            total: 0,

            loading: false,
            redirectTo: false,
        }

        // this.proposal_submission = React.createRef();
    }

    componentDidMount = () => {

        var user = localStorage.getItem('user');
        user = JSON.parse(user);

        if(user){

            this.setState({user: user});
        }

        var account_token = localStorage.getItem('account_token');
        if(account_token){

            this.setState({account_token: account_token});
        }

        const product_id = this.props.product_id;

        if(product_id && product_id != ''){
            this.loadData(product_id, account_token);
        }else{
            this.setState({redirectTo: '/products-search/'});
        }
    }


    loadData = (product_id, account_token) => {

        var formData = new FormData();
        
        if(account_token){
            formData.append('account_token', account_token);
        }
        formData.append('product_id', product_id);

        var that = this;
        this.setState({loading: true});

        Api.post('freelancer/product_view', formData, function(data){
            
            that.setState({loading: false});

            if(data.status){

                that.setState({product: data.product});
                that.setState({gallery: data.product.gallery});
                that.setState({reviews: data.product.reviews});

                that.setState({buyer_tips: data.buyer_tips});
                that.setState({how_it_works: data.how_it_works});
            }else{
                that.setState({back: true});
            }
        });
    }
    
    renderBuyerTips = () => {

        const list = [];
        for(let i = 0; i < this.state.buyer_tips.length; i++) {
            let key = 'bt_'+i;

            list.push(
                <li key={key}>{this.state.buyer_tips[i]}</li>
            );
        }
        return list;
    }

    renderHowItWorks = () => {

        const list = [];
        for(let i = 0; i < this.state.how_it_works.length; i++) {
            let key = 'hiw_'+i;

            list.push(
                <li key={key}>{this.state.how_it_works[i]}</li>
            );
        }
        return list;
    }

    calculateProductSubTotal = () => {
        
        var addons = this.state.product.addons;
        var price = this.state.product.dollar_price;

        var selected_addons = this.state.selected_addons;

        var _total = 0;

        _total = parseFloat(price);

        for(var n in selected_addons){

            var addon_row = addons.find(row => row.id === selected_addons[n]);

            _total = parseFloat(_total) + parseFloat(addon_row.price);
        }
        
        // this.setState({sub_total_per_product: _total});
        return _total;
    }

    calculateProductTotal = () => {
        
        var addons = this.state.product.addons;
        var price = this.state.product.dollar_price;

        var buy_count = this.state.buy_count;
        var selected_addons = this.state.selected_addons;

        var _total = 0;

        _total = parseFloat(price) * parseInt(buy_count);

        for(var n in selected_addons){

            var addon_row = addons.find(row => row.id === selected_addons[n]);

            _total = parseFloat(_total) + ( parseInt(buy_count) * parseFloat(addon_row.price) );
        }
        
        // this.setState({total:_total});
        return _total;
    }

    renderAddonsPrices = () => {

        var addons = this.state.product.addons;
        var selected_addons = this.state.selected_addons;

        return selected_addons.map((key) => {

            var _key = "select_option_" + key;
            // var addon_row = addons.find(row => row.id === selected_addons[key]);
            var addon_row = addons.find(row => row.id === key);

            return (
                <div key={_key} className="d-flex">
                    <div className="text">{addon_row.description}</div>
                    <div className="text">{addon_row.price_formatted}</div>
                </div>
            )
        });

        // return _html;
    }

    onFinish = (values) => {
        return false;
    }

    onPayClick = () => {
        var formData = new FormData();

        var addons = this.state.product.addons;
        var selected_addons = this.state.selected_addons;
        var formatted_addons = [];

        Object.keys(selected_addons).forEach((key) => {
            
            if(selected_addons[key]){
                var addon_row = addons.find(row => row.id === selected_addons[key]);

                if(addon_row){
                    formatted_addons.push(addon_row);
                }
            }
        });
        // console.log(formatted_addons);

        formData.append('account_token', this.state.account_token);
        formData.append('product_id', this.state.product.product_id);
        formData.append('price', this.state.product.dollar_price);
        formData.append('addons', JSON.stringify(formatted_addons));
        formData.append('sub_total_per_product', this.calculateProductSubTotal());
        formData.append('qty', this.state.buy_count);
        
        formData.append('final_total', this.calculateProductTotal());

        this.setState({loading: true});

        var that = this;
        Api.post('products/buy_product', formData, function(data){
            
            that.setState({loading: false});
            if(data.status == true){
                
                LayoutHelper.addSuccessMessage(that, data.message);
                that.setState({buy_clicked: false, buy_count: 1, selected_addons: []});
            }else{

                LayoutHelper.addErrorMessage(that, data.message);
            }
            
        });
    }

    render () {

        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo} />;
        }

        const product = this.state.product;
        const _gallery = this.state.gallery;

        return (

            <Main full_content={true} error_message={this.state.error_message} success_message={this.state.success_message}>
                <div className="product-view inner-page pt-2">

                    <div className="container">
                        <div className="page-heading">Products and Offers</div>


                        {(this.state.buy_clicked && (this.state.buy_count > 0))
                            ?
                                <div className="mt-3 card">
                                    {/* {console.log(this.state.selected_addons)}
                                    {console.log(this.state.buy_count)} */}
                                    <div className="justify-center" style={{width:'100%'}}>
                                        <div className="left-block" style={{width:'45%'}}>
                                            <div className="page-heading">Pay for your Product</div>
                                            <form className="" onFinish={this.onFinish.bind(this)}>
                                                <div className="payment-blocks">
                                                    <div className="d-flex form-item-block border-block">
                                                        <Form.Item name="paytm">
                                                            <label>
                                                                <Checkbox /><img className="img-fluid" src={paymentmethodimg} alt="Payment Method" />
                                                            </label>
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                
                                            </form>
                                        </div>
                                       <div className="right-block extras-payment-block" style={{width:'45%'}}>
                                            
                                            <div className="project-block border-bottom">
                                                <div className="d-flex">
                                                    <div className="text bold">{product.title}</div>
                                                    <div className="text bold">{product.dollar_price_formatted}</div>
                                                </div>
                                            </div>

                                            <div className="project-block border-bottom mt-1">
                                                <div className="d-flex">
                                                    <div className="text bold">Add-ons</div>
                                                    <div className="text bold"></div>
                                                </div>
                                            </div>
                                            
                                            <div className="price-block border-bottom">
                                                {this.renderAddonsPrices()}
                                            </div>

                                            <div className="price-block border-bottom mt-1">
                                                <div className="d-flex">
                                                    <div className="text bold">Subtotal</div>
                                                    <div className="text bold">${this.calculateProductSubTotal()}</div>
                                                </div>
                                            </div>

                                            <div className="project-block border-bottom mt-1">
                                                <div className="d-flex">
                                                    <div className="text bold">Qty</div>
                                                    <div className="text bold">{this.state.buy_count}</div>
                                                </div>
                                            </div>

                                            <div className="summary-block border-bottom">
                                                <div className="d-flex">
                                                    <div className="text bold">Total</div>
                                                    <div className="text bold">${this.calculateProductTotal()}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>  
                                    <div className="justify-center" style={{width:'100%', marginTop:40}}>
                                        <div className="d-flex form-item-block agree-block" style={{flexDirection:'column'}}>
                                            <Form.Item name="tnc">
                                                <Checkbox>I agree to the <Link className="link" to='/'>terms and conditions</Link>, <Link className="link" to='/'>refund and cancelation policy</Link>, <Link className="link" to='/'>Fees</Link></Checkbox>
                                            </Form.Item>
                                            <div className="">
                                                <FeatherIcon icon="lock" />
                                                <div className="text">
                                                    <div className="top">Secure Payment</div>
                                                    <div className="bottom">Your information is protected by 256-bit SSL encryptions.</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-between form-end">
                                            
                                            <Button type="secondary" onClick={() => {
                                                // this.props.goBack(this.props.selected_options)
                                                this.setState({buy_clicked: false, buy_count: 1, selected_addons: []});
                                            }} className="btn btn-xs">Cancel Selection</Button>
                                            <Button type="primary" onClick={() => {
                                                this.onPayClick()
                                            }} className="btn ml-10">Pay Now</Button>
                                        </div>
                                    </div>
                                </div>
                            :
                                <Row className="left-right-column" gutter={16}>
                                    <Col className="gutter-row" span={16}>
                                        <div className="product-view-left-section view-details-left-blk">
                                            <div className="top-section">
                                                {product.featured == 1 &&
                                                    <div className="tag-wrapper">
                                                        <span className="tag-info active">Featured</span>
                                                    </div>
                                                }
                                                <div className="block-heading">{product.title}</div>

                                                <div className="product-image">
                                                    <img src={product.image_full} alt="Product Image" />
                                                </div>

                                                <div className="product-gallery">
                                                    
                                                    {_gallery && _gallery.length > 0
                                                        ?
                                                            _gallery.map((img, index) => {
                                                                var key = 'gl_' + index;
                                                                return (
                                                                        <div key={key} className="g-img">
                                                                            <img src={img.image} alt="Product Image" onClick={(e) => {
                                                                                var _product = product;
                                                                                _product.image_full = e.target.src;
                                                                                this.setState({product: _product});
                                                                            }} />
                                                                        </div>
                                                                    )
                                                            })
                                                        :
                                                            null
                                                    }
                                                </div>
                                            </div>

                                            <Tabs defaultActiveKey="1" className="blue-tabs" centered>
                                                <Tabs.TabPane tab="Info" key="1">
                                                    <div className="info-tab">
                                                        <div className="section main-details">
                                                            <div className="desc-section">
                                                                <div className="title">What you get with this Offer</div>
                                                                <div className="text">
                                                                    <div dangerouslySetInnerHTML={{ __html: product.description }} />
                                                                </div>
                                                            </div>

                                                            {this.state.product.addons && this.state.product.addons.length > 0
                                                                ?
                                                                    <>
                                                                        <div className="addons-section">
                                                                            <div className="title mb-3">Get more with offer add-ons</div>
                                                                            <div className="addons">
                                                                                {
                                                                                    this.state.product.addons.map((addon, index) => {
                                                                                        var key = 'add_' + index;
                                                                                        return (
                                                                                                <div className="block" key={key}>
                                                                                                    <div className="top">
                                                                                                        <div className="heading">{addon.description}</div>
                                                                                                        <Checkbox onClick={(e) => {
                                                                                                            var checked = e.target.checked;
                                                                                                            var selected_addons = this.state.selected_addons;
                                                                                                            if(checked){
                                                                                                                selected_addons.push(addon.id);
                                                                                                            }else{
                                                                                                                var index = selected_addons.indexOf(addon.id);
                                                                                                                if (index !== -1) {
                                                                                                                    selected_addons.splice(index, 1);
                                                                                                                }
                                                                                                            }
                                                                                                            this.setState({selected_addons:selected_addons});
                                                                                                        }}></Checkbox>
                                                                                                    </div>
                                                                                                    <div className="bottom">
                                                                                                        <div className="text">Additional {addon.days} working days</div>
                                                                                                        <div className="price">+{addon.price_formatted}</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                :
                                                                    null
                                                            }

                                                            <div className="requirements-section mt-3">
                                                                <div className="title">What do we need from the buyer to get started?</div>
                                                                <div className="text">
                                                                    <div dangerouslySetInnerHTML={{ __html: product.requirements }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tabs.TabPane>

                                                <Tabs.TabPane tab="Reviews" key="2">
                                                    <div className="clarification-board-tab">

                                                        <div className="title">Reviews</div>

                                                        {this.state.reviews && this.state.reviews.length > 0
                                                            ?
                                                                <>
                                                                    <div className="clarification-chat">
                                                                        {
                                                                            this.state.reviews.map((review, index) => {
                                                                                var key = 'rev_' + index;

                                                                                return <SingleReview key={key} review={review} />
                                                                            })
                                                                        }
                                                                    </div>
                                                                </>
                                                            :
                                                                null
                                                        }
                                                            
                                                    </div>
                                                </Tabs.TabPane>

                                            </Tabs>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" span={8}>

                                        <div className="product-view-right-section">

                                            <div className="section">
                                                <div className="profile-basic-details right-blk">
                                                    <div className="top"></div>
                                                    <div className="bottom">
                                                        
                                                    {product.profile_pic_full != ''
                                                        ?
                                                            <Avatar size={100} src={product.profile_pic_full} />
                                                        :
                                                            <Avatar size={100} icon={<UserOutlined />} />
                                                    }

                                                        <div className="name">{product.freelancer_name}</div>
                                                        <div className="location"><i className="mat-ico">location_on</i> {product.freelancer_country}</div>
                                                        <div className="ratings-reviews">
                                                            <Rate disabled tooltips={this.state.rate_tooltips} size="large" value={product.reviews_rating_round} />
                                                            <div className="rating-text">{product.reviews_rating} ({product.freelancer_reviews_count})</div>
                                                        </div>

                                                        <div className="buy-blk">
                                                            <div className="d-flex justify-content-between">
                                                                <div className="price">{product.dollar_price_formatted}</div>
                                                                <div className="in-de-blk">
                                                                    <span className="label">Qty:</span>
                                                                    <Button className="btn btn-secondary btn-lg" onClick={(e) => {
                                                                        var _buy_count = this.state.buy_count;
                                                                        _buy_count = parseInt(_buy_count) - 1;
                                                                        {(_buy_count > 0)
                                                                            ?
                                                                                this.setState({buy_count: _buy_count})
                                                                            :
                                                                                this.setState({buy_count: '1'})
                                                                        }

                                                                    }}>-</Button>
                                                                    <Input value={this.state.buy_count}/>
                                                                    <Button className="btn btn-secondary btn-lg" onClick={(e) => {
                                                                        var _buy_count = this.state.buy_count;
                                                                        _buy_count = parseInt(_buy_count) + 1;
                                                                        this.setState({buy_count: _buy_count})

                                                                    }}>+</Button>
                                                                </div>
                                                            </div>
                                                            <Button className="btn btn-blue btn-lg" onClick={(e) => {
                                                                this.setState({buy_clicked: true});
                                                            }}>Buy Now</Button>
                                                        </div>

                                                        <div className="response-block">
                                                            <i className="ico clock-blue"></i>
                                                            <div className="">Response time <span className="blue-txt">within a few hours</span></div>
                                                        </div>

                                                        <div className="other-details">
                                                            <div className="flex-col">
                                                                <i className="ico eye-blue"></i>
                                                                <span className="text">{product.views_count} Views</span>
                                                            </div>
                                                            <div className="flex-col">
                                                                <i className="ico heart-blue"></i>
                                                                <span className="text">{product.likes_count} Likes</span>
                                                            </div>
                                                            <div className="flex-col">
                                                                <i className="ico sold-fill"></i>
                                                                <span className="text">{product.purchase_count}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="money-protection">
                                                    <img src={require('../../assets/images/100.png')} alt="Money Protection" />
                                                    <div className="details">
                                                        <div className="title">Money Protection Guarantee</div>
                                                        <div className="text">Project done or your money back</div>
                                                    </div>
                                                </div>

                                                <Tabs defaultActiveKey="1" className="buyer-hiw-tabs blue-tabs" centered>
                                                    <Tabs.TabPane tab="Buyer Tips" key="1">
                                                        <div className="tips-tab">
                                                            <ol type="1">
                                                                {this.renderBuyerTips()}
                                                            </ol>
                                                        </div>
                                                    </Tabs.TabPane>

                                                    <Tabs.TabPane tab="How It Works" key="2">
                                                        <div className="hiw-tab">
                                                            <ol type="1">
                                                                {this.renderHowItWorks()}
                                                            </ol>
                                                        </div>
                                                    </Tabs.TabPane>

                                                </Tabs>
                                            </div>

                                        </div>
                                    </Col>
                                </Row>
                        }

                        
                    </div>
                    
                </div>

            </Main>
            
        )
    }
}

export default ProductDetailsPage;