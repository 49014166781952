import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { Menu, Avatar } from 'antd';

// add form components
import { Row, Col, Tabs, Form, Input, Button, Checkbox, Select, Collapse, Rate } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import Main from '../../../components/Main';

import LayoutHelper from '../../../helpers/LayoutHelper';

import Api from '../../../api/Api';

import SingleReview from '../../../components/products/SingleReview';

import dashboardbg from '../../../assets/images/dashboard-bg.png';

class ProductView extends Component {       // not in use
    constructor(props) {
        super();
        this.state = {

            error_message: '',
            success_message: '',

            page_title: 'Offer Details',
            account_token: false,
            user: false,
            show_review_form: false,

            product: [],
            gallery: [],
            reviews: [],

            featured_count: '1',

            addons: [{description: '', amount: '', days: ''}],
            
            redirectTo: false,
            confirm_check: 0,
        }

        this.product_form = React.createRef();
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');

        var _user = JSON.parse(user);
        this.setState({user: _user});

        if(account_token){
            
            this.setState({account_token: account_token});

            const product_id = this.props.product_id;

            if(product_id && product_id != ''){
                this.loadData(product_id, account_token);
            }else{
                this.setState({redirectTo: 'products'});
            }
            
        }else{
            window.location = Api.url + '/logout';
        }
    }


    render () {

        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo} />;
        }

        const layout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
        };

        const validateMessages = {
            required: '${label} is required!',
            types: {
                email: '${label} is not valid!',
                number: '${label} is not a valid number!',
            },
            number: {
                range: '${label} must be between ${min} and ${max}',
            },
        };

        const product = this.state.product;
        const _gallery = this.state.gallery;

        return (

            <>
                <Main page_title="Product View" hide_page_title="true" className="products-view freelancer-ac account-bg" page="as_seller" error_message={this.state.error_message} success_message={this.state.success_message}>

                    <div className="banner-block dashboard-bg-img">
                        <img src={dashboardbg} alt="Banner Image" />
                    </div>

                    <div className="page-main-content blue-gr-bg">
                        <div className="heading-container container-lg">
                            <div className="page-heading">{product.title}</div>

                            <Row className="left-right-column" gutter={16}>
                                <Col className="gutter-row" span={16}>
                                    <div className="product-view-left-section">
                                        <div className="top-section">
                                            {product.featured == 1 &&
                                                <div className="tag-wrapper">
                                                    <span className="tag-info active">Featured</span>
                                                </div>
                                            }

                                            <div className="product-image">
                                                <img src={product.image_full} alt="Product Image" />
                                            </div>

                                            <div className="product-gallery">
                                                
                                                {_gallery && _gallery.length > 0
                                                    ?
                                                        
                                                        _gallery.map((img, index) => {
                                                            var key = 'gl_' + index;
                                                            return (
                                                                    <div key={key} className="g-img">
                                                                        <img src={img.image} alt="Product Image" onClick={(e) => {
                                                                            var _product = product;
                                                                            _product.image_full = e.target.src;
                                                                            this.setState({product: _product});
                                                                        }} />
                                                                    </div>
                                                                )
                                                        })
                                                    :
                                                        null
                                                }
                                                
                                            </div>
                                        </div>


                                        <Tabs defaultActiveKey="1" centered>
                                            <Tabs.TabPane tab="Info" key="1">
                                                <div className="info-tab">
                                                    <div className="section main-details">
                                                        <div className="desc-section white-block">
                                                            <div className="title">What you get with this Offer</div>
                                                            <div className="text">
                                                                <div dangerouslySetInnerHTML={{ __html: product.description }} />
                                                            </div>
                                                        </div>

                                                        <div className="white-block">
                                                            {this.state.product.addons && this.state.product.addons.length > 0
                                                                ?
                                                                    <>
                                                                        <div className="addons-section">
                                                                            <div className="title">Get more with offer add-ons</div>
                                                                            <div className="addons">
                                                                                {
                                                                                    this.state.product.addons.map((addon, index) => {
                                                                                        var key = 'add_' + index;
                                                                                        return (
                                                                                                <div className="block" key={key}>
                                                                                                    <div className="top">
                                                                                                        <div className="heading">{addon.description}</div>
                                                                                                        <Checkbox></Checkbox>
                                                                                                    </div>
                                                                                                    <div className="bottom">
                                                                                                        <div className="text">Additional {addon.days} working days</div>
                                                                                                        <div className="price">+{addon.price}</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                    })
                                                                                }
                                                                                
                                                                            </div>
                                                                        </div>

                                                                    </>
                                                                    
                                                                :
                                                                    null
                                                            }
                                                        
                                                            <div className="requirements-section">
                                                                <div className="title">What do you need from a buyer to get started?</div>
                                                                <div className="text">
                                                                    <div dangerouslySetInnerHTML={{ __html: product.requirements }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </Tabs.TabPane>

                                            <Tabs.TabPane tab="Reviews" key="2">
                                                
                                                <div className="clarification-board-tab white-block">

                                                    <div className="title lg">
                                                        <span>Reviews</span>
                                                        <Button className="btn-blue empty btn-lg" onClick={(e) => {
                                                            this.setState({show_review_form: true});
                                                        }}>Add a Question</Button>
                                                    </div>

                                                    {this.state.show_review_form
                                                        ?
                                                            <Form {...layout} scrollToFirstError={true} name="review_form" validateMessages={validateMessages} onFinish={this.onReviewSubmit.bind(this)}>

                                                                <div className="page-main-content">
                                                                    <div className="form-wrap">
                                                                        <h4 className="title">Please give your views</h4>
                                                                        <Form.Item name="description" label="" rules={[{required:true, message: 'Please enter your review!'}]}>
                                                                            <Input.TextArea />
                                                                        </Form.Item>
                                                                    </div>

                                                                    <div className="form-wrap">
                                                                        <Form.Item className="w-100 no-label" name="confirm_check" label="Confirm Checkbox" onClick={(e) => {
                                                                            var checked = e.target.checked;
                                                                            if(checked){
                                                                                this.setState({confirm_check:'1'});
                                                                            }else{
                                                                                this.setState({confirm_check:'0'});
                                                                            }
                                                                        }}>
                                                                        <Checkbox className="orng-checkbox">I confirm that this question is essential for the submission of my proposal and I understand that it will be publicly posted in the project’s clarification board and in case it is used to spam or solely advertise my skills, my rankings will be severely affected.</Checkbox>
                                                                    </Form.Item>
                                                                    </div>

                                                                    <div className="terms">
                                                                        <Row justify="space-between" align="bottom">
                                                                            <Col className="buttons-block align-right" span={24}>
                                                                                <Button type="default" loading={this.state.loading} htmlType="submit" className="btn blank-blue-btn"><span>Send</span></Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </div>
                                                            </Form>
                                                        :
                                                            null
                                                    }

                                                    
                                                    {this.state.reviews && this.state.reviews.length > 0
                                                        ?
                                                            <>
                                                                <div className="clarification-chat">
                                                                    {
                                                                        this.state.reviews.map((review, index) => {
                                                                            var key = 'rev_' + index;

                                                                            return <SingleReview key={key} review={review} />

                                                                        })
                                                                    }
                                                                        
                                                                </div>

                                                            </>
                                                            
                                                        :
                                                            null
                                                    }
                                                    
                                                </div>
                                            </Tabs.TabPane>

                                        </Tabs>
                                    </div>
                                </Col>
                                <Col className="gutter-row" span={8}>

                                    <div className="product-view-right-section">

                                        <div className="section">
                                            <div className="profile-basic-details">
                                                <div className="top"></div>
                                                <div className="bottom">
                                                    
                                                    {product.profile_pic_full != ''
                                                        ?
                                                            <Avatar size={100} src={product.profile_pic_full} />
                                                        :
                                                            <Avatar size={100} icon={<UserOutlined />} />
                                                    }

                                                    <div className="name">{product.freelancer_name}</div>
                                                    <div className="location"><i className="mat-ico">location_on</i> {product.freelancer_country}</div>
                                                    <div className="ratings-reviews">
                                                        <Rate disabled tooltips={this.state.rate_tooltips} size="large" value={product.reviews_rating_round} />
                                                        <div className="rating-text">{product.reviews_rating} ({product.reviews_count})</div>
                                                    </div>

                                                    <div className="price-block">{product.dollar_price_formatted}</div>
                                                    <div className="featured-it-blk">
                                                        <div className="in-de-blk">
                                                            <Button className="btn btn-lgrey btn-lg" onClick={(e) => {
                                                                var _featured_count = this.state.featured_count;
                                                                _featured_count = parseInt(_featured_count) - 1;
                                                                {(_featured_count > 0)
                                                                    ?
                                                                        this.setState({featured_count: _featured_count})
                                                                    :
                                                                        this.setState({featured_count: '1'})
                                                                }

                                                            }}>-</Button>
                                                            <Input value={this.state.featured_count}/>
                                                            <Button className="btn btn-lgrey btn-lg" onClick={(e) => {
                                                                var _featured_count = this.state.featured_count;
                                                                _featured_count = parseInt(_featured_count) + 1;
                                                                this.setState({featured_count: _featured_count})

                                                            }}>+</Button>
                                                        </div>
                                                        <Button className="btn btn-blue btn-lg">Featured It</Button>
                                                    </div>

                                                    <div className="details">
                                                        <div className="">
                                                            <i className="ico plane-orng"></i>
                                                            <div className="">Delivery in {product.delivery_time} days</div>
                                                        </div>

                                                        <div className="">
                                                            <i className="ico clock-orng"></i>
                                                            <div className="">Response time within a few hours</div>
                                                        </div>
                                                    </div>

                                                    <div className="other-details">
                                                        <div className="">
                                                            <i className="ico eye-orng"></i>
                                                            <span className="text">{product.views_count} Views</span>
                                                        </div>
                                                        <div className="">
                                                            <i className="ico heart-orng"></i>
                                                            <span className="text">{product.likes_count} Likes</span>
                                                        </div>
                                                        <div className="">
                                                            <i className="ico sold-orng"></i>
                                                            <span className="text">{product.purchase_count}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div className="container-lg white-block">
                            
                        </div>
                    </div>
                    
                </Main>
            </>
        )
    }


    loadData = (product_id, account_token) => {

        var formData = new FormData();
        
        if(account_token){
            formData.append('account_token', account_token);
        }
        formData.append('product_id', product_id);

        var that = this;
        this.setState({loading: true});

        Api.post('freelancer/product_view', formData, function(data){
            
            that.setState({loading: false});

            if(data.status){

                that.setState({product: data.product});
                that.setState({gallery: data.product.gallery});
                that.setState({reviews: data.product.reviews});
                
            }else{
                that.setState({back: true});
            }
        });
    }

    onReviewSubmit = (values) => {
        var formData = new FormData();
        Object.keys(values).forEach((key) => {

            if(values[key]){
                formData.append(key, values[key]);
            }
        });

        formData.append('account_token', this.state.account_token);
        formData.append('product_id', this.state.product.product_id);
        
        var that = this;

        this.setState({loading: true});

        if(this.state.confirm_check == 0){
            LayoutHelper.addErrorMessage(that, 'Please confirm the checkbox!!');
        }

        // Api.post('products/submit_review', formData, function(data){
            
        //     that.setState({loading: false});

        //     if(data.status == true){

        //         that.state.onSuccess(data.message);
        //         that.setState({proposal_submitted: true});

        //         that.state.updateProject(project, true);

        //         that.scrollToSubmission();

        //     }else{
                
        //         that.state.onError(data.message);
        //     }
        // });
    }
}

export default ProductView;