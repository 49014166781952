import React, { Component } from 'react';
import { Row, Col, Divider, Select, Input, Tag, Button, Pagination, Checkbox, List, Collapse } from 'antd';
import { ArrowRightOutlined, CheckCircleOutlined } from '@ant-design/icons';

// import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { Link } from "react-router-dom";

import bannerbgimage from '../../assets/images/projects-bg.jpg';
import profileimage from '../../assets/images/anne-peres.png';
import coffeecupsimage from '../../assets/images/blog/coffee-cups.jpg';
import laptopbloggingimage from '../../assets/images/blog/laptop-blogging.jpg';
import makingnewblogimage from '../../assets/images/blog/making-new-blog.jpg';
import tshirtimage from '../../assets/images/blog/t-shirt.jpg';

import PostRow from './PostRow';

import Main from '../../components/Main';
import Api from '../../api/Api';

// import BlogList from './BlogList';

const { Panel } = Collapse;

class BlogListingPage extends Component { 
    constructor(props) {
        super();
        this.state = {

            user: false,
            account_token: false,

            loading: false,
            current_page: 1,

            sizeSmall: 'small',
            sizeLarge: 'large',

            posts: [
                {post_title:'Doloremque velit sapien labore eius lopren', post_slug:'test-1', post_image:coffeecupsimage, post_description:'Massa sociosqu ab incidunt aute modi incidunt aliquet. Laborum ante dictumst sunt aut, porta mollis.', category_name:'Risk & Fraud Analytics', profile_name:'Erica Jensen', profile_image:profileimage, total_comments:3},

                {post_title:'Doloremque velit sapien labore eius lopren', post_slug:'test-2', post_image:laptopbloggingimage, post_description:'Massa sociosqu ab incidunt aute modi incidunt aliquet. Laborum ante dictumst sunt aut, porta mollis.', category_name:'Risk & Fraud Analytics', profile_name:'Erica Jensen', profile_image:profileimage, total_comments:3},

                {post_title:'Doloremque velit sapien labore eius lopren', post_slug:'test-3', post_image:makingnewblogimage, post_description:'Massa sociosqu ab incidunt aute modi incidunt aliquet. Laborum ante dictumst sunt aut, porta mollis.', category_name:'Risk & Fraud Analytics', profile_name:'Erica Jensen', profile_image:profileimage, total_comments:3},

                {post_title:'Doloremque velit sapien labore eius lopren', post_slug:'test-4', post_image:tshirtimage, post_description:'Massa sociosqu ab incidunt aute modi incidunt aliquet. Laborum ante dictumst sunt aut, porta mollis.', category_name:'Risk & Fraud Analytics', profile_name:'Erica Jensen', profile_image:profileimage, total_comments:3},
            ],
            total: 0,
            per_page: 10,
            total_pages:0,

            related_posts: [
                {post_title:'What the Corona Crisis will Teach Us, Eventually!', post_slug:'test-1', post_description:'All global crises, be it the economic ones such as the Great Depression of 1930, the Financial Crisis of 2008 or the...'},

                {post_title:'What the Corona Crisis will Teach Us, Eventually!', post_slug:'test-2', post_description:'All global crises, be it the economic ones such as the Great Depression of 1930, the Financial Crisis of 2008 or the...'},

                {post_title:'What the Corona Crisis will Teach Us, Eventually!', post_slug:'test-3', post_description:'All global crises, be it the economic ones such as the Great Depression of 1930, the Financial Crisis of 2008 or the...'},

                {post_title:'What the Corona Crisis will Teach Us, Eventually!', post_slug:'test-4', post_description:'All global crises, be it the economic ones such as the Great Depression of 1930, the Financial Crisis of 2008 or the...'},
            ],

            search_keyword: '',
            search_category: '',
        }
    }

    componentDidMount = () => {

        var user = localStorage.getItem('user');
        var account_token = localStorage.getItem('account_token');
        
        if(account_token){

            this.setState({account_token: account_token});
        }

        if(user){
            user = JSON.parse(user);
            this.setState({user: user});
        }

        if(this.props.keyword){
            this.setState({
                search_keyword: this.props.keyword
            }, () => {
                this.loadPosts(this.state.current_page, false, this.props.keyword);
            });
        }else{
            this.loadPosts(this.state.current_page, false, false);
        }
    }

    loadPosts = (page, values, keyword/*, category*/) => {

        /*var formData = new FormData();
        formData.append('page', page);

        var account_token = localStorage.getItem('account_token');
        if(account_token){
            formData.append('account_token', account_token);
        }

        if(keyword){
            formData.append('keyword', keyword);
        }else if(this.props.keyword){
            formData.append('keyword', this.props.keyword);
        }

        // if(category){
        //     formData.append('category_id', category);
        // }

        if(values){
            Object.keys(values).forEach((key) => {

                if(values[key]){
                    formData.append(key, values[key]);
                }
            });
        }

        // if(this.state.checkedCategories.length > 0){
        //     formData.append('category_ids', this.state.checkedCategories);
        // }

        var that = this;
        this.setState({loading: true});

        Api.post('projects/listing', formData, function(data){
            
            that.setState({loading: false});

            that.setState({
                projects: data.results,
                total: data.total,
                per_page: data.per_page,
                current_page: data.page
            });
        });*/
    }

    applyFilters = (values) => {

        this.loadPosts(1, values, this.state.search_keyword, this.state.search_category);
    }

    submitSearch = (values) => {

        this.setState({current_page: 1});
        this.loadPosts(1, false, this.state.search_keyword, this.state.search_category);
    }

    onKeywordChange(value) {
    
        this.setState({
            search_keyword: value
        });
    }

    renderRelatedPosts = () => {

        const related_posts = this.state.related_posts;

        return related_posts.map(function(item, index){
            var key = 'rp_'+index;

            return (
                <div className="single-item" key={key}>
                    <Link to={"/blog/"+item.post_slug} className="title">{item.post_title}</Link>
                    <div className="desc">{item.post_description}</div>
                    <Link to={"/blog/"+item.post_slug} className="link">View More</Link>
                </div>
            )
        });
    }

    render () {

        return (

            <Main page_title="BlogListingPage">
                <div className="blog-listing-section inner-page pt-0 white-bg listing-page"> {/*white-bg*/}
                    <div className="banner-block orange-bg-img">
                        <img src={bannerbgimage} alt="Banner Image" />
                        <div className="container">
                            <div className="heading">Blogging the reel world</div>
                        </div>
                    </div>

                    {/* <BlogList /> */}

                    <div className="container">
                        <Row gutter={16}>
                            <Col className="gutter-row" span={17}>
                                <div className="listing-section">
                                    <div className="heading">Recent Blogs</div>
                                    <div className="listing">

                                        <List
                                            className="posts-list"
                                            itemLayout="horizontal"
                                            size="large"
                                            loading={this.state.loading}
                                            pagination={{
                                                onChange: page => {
                                                    window.setTimeout(() => {
                                                        this.loadPosts(page, false, false);
                                                    }, 200);
                                                },
                                                pageSize: this.state.per_page,
                                                total: this.state.total,
                                                size: "small",
                                            }}
                                            dataSource={this.state.posts}
                                            renderItem={item => (
                                                <PostRow
                                                    post={item}
                                                    user={this.state.user}
                                                />
                                            )}
                                        />
                                        {/* <div className="single-item d-flex">
                                            <div className="left">
                                                <img className="img-fluid" src={coffeecupsimage} alt="Doloremque velit sapien labore eius lopren" />
                                            </div>
                                            <div className="right">
                                                <div className="top-section">
                                                    <div className="profile-block">
                                                        <img className="img-fluid" src={profileimage} alt="Erica Jensen" />
                                                        <div className="profile-detail">
                                                            <span className="name">Erica Jensen</span>
                                                            <span className="category-name">Risk & Fraud Analytics</span>
                                                        </div>
                                                    </div>
                                                    <Link to="" className="title">Doloremque velit sapien labore eius lopren</Link>
                                                    <div className="description">Massa sociosqu ab incidunt aute modi incidunt aliquet. Laborum ante dictumst sunt aut, porta mollis.</div>
                                                </div>
                                                <div className="bottom-section">
                                                    <div className="comment-block">
                                                        <i className="mat-ico">comment</i><span className="text">3 Comments</span>
                                                    </div>
                                                    <Link to="" className="btn blank-blue-btn" size={this.setState.sizeSmall}>Read more</Link>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="single-item d-flex">
                                            <div className="left">
                                                <img className="img-fluid" src={laptopbloggingimage} alt="Doloremque velit sapien labore eius lopren" />
                                            </div>
                                            <div className="right">
                                                <div className="top-section">
                                                    <div className="profile-block">
                                                        <img className="img-fluid" src={profileimage} alt="Erica Jensen" />
                                                        <div className="profile-detail">
                                                            <span className="name">Erica Jensen</span>
                                                            <span className="category-name">Risk & Fraud Analytics</span>
                                                        </div>
                                                    </div>
                                                    <Link to="" className="title">Doloremque velit sapien labore eius lopren</Link>
                                                    <div className="description">Massa sociosqu ab incidunt aute modi incidunt aliquet. Laborum ante dictumst sunt aut, porta mollis.</div>
                                                </div>
                                                <div className="bottom-section">
                                                    <div className="comment-block">
                                                        <i className="mat-ico">comment</i><span className="text">3 Comments</span>
                                                    </div>
                                                    <Link to="" className="btn blank-blue-btn" size={this.setState.sizeSmall}>Read more</Link>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="single-item d-flex">
                                            <div className="left">
                                                <img className="img-fluid" src={makingnewblogimage} alt="Doloremque velit sapien labore eius lopren" />
                                            </div>
                                            <div className="right">
                                                <div className="top-section">
                                                    <div className="profile-block">
                                                        <img className="img-fluid" src={profileimage} alt="Erica Jensen" />
                                                        <div className="profile-detail">
                                                            <span className="name">Erica Jensen</span>
                                                            <span className="category-name">Risk & Fraud Analytics</span>
                                                        </div>
                                                    </div>
                                                    <Link to="" className="title">Doloremque velit sapien labore eius lopren</Link>
                                                    <div className="description">Massa sociosqu ab incidunt aute modi incidunt aliquet. Laborum ante dictumst sunt aut, porta mollis.</div>
                                                </div>
                                                <div className="bottom-section">
                                                    <div className="comment-block">
                                                        <i className="mat-ico">comment</i><span className="text">3 Comments</span>
                                                    </div>
                                                    <Link to="" className="btn blank-blue-btn" size={this.setState.sizeSmall}>Read more</Link>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="single-item d-flex">
                                            <div className="left">
                                                <img className="img-fluid" src={tshirtimage} alt="Doloremque velit sapien labore eius lopren" />
                                            </div>
                                            <div className="right">
                                                <div className="top-section">
                                                    <div className="profile-block">
                                                        <img className="img-fluid" src={profileimage} alt="Erica Jensen" />
                                                        <div className="profile-detail">
                                                            <span className="name">Erica Jensen</span>
                                                            <span className="category-name">Risk & Fraud Analytics</span>
                                                        </div>
                                                    </div>
                                                    <Link to="" className="title">Doloremque velit sapien labore eius lopren</Link>
                                                    <div className="description">Massa sociosqu ab incidunt aute modi incidunt aliquet. Laborum ante dictumst sunt aut, porta mollis.</div>
                                                </div>
                                                <div className="bottom-section">
                                                    <div className="comment-block">
                                                        <i className="mat-ico">comment</i><span className="text">3 Comments</span>
                                                    </div>
                                                    <Link to="" className="btn blank-blue-btn" size={this.setState.sizeSmall}>Read more</Link>
                                                </div>
                                            </div>
                                        </div> */}

                                    </div>

                                    {/* <div className="pagination-block">
                                        <div className="">
                                            <Button type="primary" className="dark-blue-btn round">Previous</Button>
                                        </div>
                                        <Pagination size="small" total={50} />
                                        <div className="">
                                            <Button type="primary" className="dark-blue-btn round">Next</Button>
                                        </div>
                                    </div> */}
                                </div>
                            </Col>

                            <Col className="gutter-row" span={7}>
                                <div className="right-section white-bg pt-0">
                                    <div className="social-links-text d-flex">
                                        <span className="text">Share this blog:</span>
                                        <ul className="list-inline d-flex social-links">
                                            <li><Link to="#" target="_blank"><i className="ico facebook"></i></Link></li>
                                            <li><Link to="#" target="_blank"><i className="ico twitter"></i></Link></li>
                                            <li><Link to="#" target="_blank"><i className="ico linkedin"></i></Link></li>
                                        </ul>
                                    </div>

                                    <div className="search-row">
                                        <Input.Search placeholder="Search By Article Title" size={this.state.sizeLarge} enterButton={<ArrowRightOutlined size={this.state.sizeLarge} />} />
                                    </div>

                                    <Collapse defaultActiveKey={['1']} expandIconPosition="right">
                                        <Panel header="Industry types" key="1">
                                            <div className="filter-list industry-types">
                                                <div className="single">
                                                    <Checkbox>Banking</Checkbox>
                                                    <Checkbox>Insurance</Checkbox>
                                                    <Checkbox>Manufacturing</Checkbox>
                                                    <Checkbox>Retail</Checkbox>
                                                    <Checkbox>Utilities</Checkbox>
                                                </div>
                                            </div>
                                        </Panel>
                                    </Collapse>
                                    <Collapse defaultActiveKey={['1']} expandIconPosition="right">
                                        <Panel header="Functional Areas" key="2">
                                            <div className="filter-list functional-areas">
                                                <div className="single">
                                                    <Checkbox>Banking</Checkbox>
                                                    <Checkbox>Insurance</Checkbox>
                                                    <Checkbox>Manufacturing</Checkbox>
                                                    <Checkbox>Retail</Checkbox>
                                                    <Checkbox>Utilities</Checkbox>
                                                </div>
                                            </div>
                                        </Panel>
                                    </Collapse>
                                    <Collapse defaultActiveKey={['1']} expandIconPosition="right">
                                        <Panel header="Objectives" key="3">
                                            <div className="filter-list objectives">
                                                <div className="single">
                                                    <Checkbox>Banking</Checkbox>
                                                    <Checkbox>Insurance</Checkbox>
                                                    <Checkbox>Manufacturing</Checkbox>
                                                    <Checkbox>Retail</Checkbox>
                                                    <Checkbox>Utilities</Checkbox>
                                                </div>
                                            </div>
                                        </Panel>
                                    </Collapse>

                                    <div className="other-posts-block">
                                        <div className="heading">You might also like..</div>

                                        <div className="other-posts">
                                            {this.renderRelatedPosts()}
                                        </div>
                                    </div>
                                    
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                
            </Main>
            
        )
    }
}

export default BlogListingPage;