import React, { Component } from 'react';

import FeatherIcon from 'feather-icons-react';

import Api from '../../../api/Api';
import briefcaseimg from '../../../assets/images/employement-bg.png';

import moment from 'moment';

import Loader from '../../../components/Loader';

import { Form, Select, Input, DatePicker, Button, Carousel, Popconfirm } from 'antd';

class Employement extends Component { 
    constructor(props) {
        super();
        this.state = {
            loading: false,
            list: [],

            remove_loading: false,
            item: false
        }
    }

    componentDidMount = () => {

        window.setTimeout(() => {
            this.loadEmployementList(this.props.account_token);
        });
    }

    render () {

        return (

            <>

                {this.props.account_token &&

                    <div style={{position:'relative'}}>
                        <div className="custom-carousel custom-employment-carousel">
                            <Carousel arrows={true}>
                                {this.renderList()}
                            </Carousel>

                        </div>
                        {this.state.remove_loading &&
                            <Loader />
                        }
                    </div>
                }
                {this.props.display
                    ?
                        this.employmentForm()
                    :
                        null
                }
            </>
        )
    }

    onEmployementSubmit = (values) => {
        var formData = new FormData();
        formData.append('account_token', this.props.account_token);

        if(this.state.item != false){

            formData.append('item_id', this.state.item.id);
        }

        Object.keys(values).forEach((key) => {

            if(values[key]){
                formData.append(key, values[key])
            }
        });
        
        var that = this;

        this.setState({loading: true});
        Api.post('freelancer/profile_update_employement', formData, function(data){
            
            that.setState({loading: false, item: false});
            
            if(data.status == true){
            
                that.setState({list: data.list});
                that.props.hide_form();
                that.props.updateUser(data.user);
            
            }else{
                that.props.onError(data.message);
            }
        });
    }

    employmentForm = () => {

        const layout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
        };

        const validateMessages = {
            required: '${label} is required!',
            types: {
                email: '${label} is not valid!',
                number: '${label} is not a valid number!',
            },
            number: {
              range: '${label} must be between ${min} and ${max}',
            },
        };

        return (

            <div className={"table-row table-row-employment-form"}>
            
                <Form {...layout} scrollToFirstError={true} name="update_employment_details" ref="update_employment_details" validateMessages={validateMessages} onFinish={this.onEmployementSubmit.bind(this)}>
                    <div className="d-flex form-item-block">
                        
                        <Form.Item className="w-50" label="Company Name" name={"company_name"} rules={[{required:true}]}>
                            <Input />
                        </Form.Item>
                        <Form.Item className="w-50" label="Title" name={"title"} rules={[{required:true}]}>
                            <Input />
                        </Form.Item>
                    </div>

                    <div className="d-flex form-item-block">
                        <div className="d-flex justify-content-between w-50">
                            <Form.Item className="w-50" label="From" name={"from"} rules={[{required:true}]}>
                                <DatePicker disabledDate={(current) => {
                                    return !current || current > moment().endOf('day');
                                }} format='DD-MM-YYYY' />
                            </Form.Item>
                            <Form.Item className="w-50" label="To" name={"to"} rules={[{required:true}]}>
                                <DatePicker disabledDate={(current) => {
                                    return !current || current > moment().endOf('day');
                                }} format='DD-MM-YYYY' />
                            </Form.Item>
                        </div>
                        <Form.Item className="w-50" label="Technologies" name={"technologies"} rules={[{required:true, type:'array'}]}>
                            <Select mode="tags" placeholder="eg. R, Python">

                            </Select>
                        </Form.Item>
                    </div>

                    <div className="d-flex form-item-block">
                        <Form.Item className="w-100" label="Description (Upto 100 words)" name={"description"} rules={[{required:true}]}>
                            <Input.TextArea />
                        </Form.Item>
                    </div>

                    <div className="text-right buttons">
                        <Button type="secondary" onClick={() => {
                            this.setState({item: false});
                            this.props.hide_form();
                        }}>Cancel</Button>
                        <Button className="ml-10" type="primary" htmlType="submit" loading={this.state.loading}>
                            {this.state.item ? 'Update' : '+ Add'}
                        </Button>
                    </div>
                </Form>
                
            </div>
        )
    }

    renderList = () => {

        if(this.state.list.length > 0){

            return this.state.list.map((emp_item, index) => {

                var key = 'emp_item_' + emp_item.id;
                return (

                    <div key={key} className={"row-employment-slider-"}>
                        <div className="block d-flex justify-content-between">
                            <div className="icon-block">
                                <img className="img-fluid" src={briefcaseimg} alt={emp_item.title} />
                                <span>{index + 1}</span>
                            </div>
                            <div className="text-block ml-20">
                                <div className="sl-title">{emp_item.title}</div>
                                <div className="sl-company-name">{emp_item.company_name}</div>
                                <div className="sl-from-to">{emp_item.emp_from_formatted} - {emp_item.emp_to_formatted}</div>
                                <div className="sl-text">{emp_item.description}</div>
                                <div className="sl-tags">
                                    <strong className="heading">Technologies:</strong>
                                    {this.renderTags(emp_item.technologies)}
                                </div>
                            </div>
                            <div className="sl-buttons">
                                
                                <div className="pointer" onClick={() => {
                                    this.editItem(emp_item);
                                }}>
                                    Edit
                                </div>

                                <Popconfirm placement="left" title="Do you really want to remove this item?" onConfirm={() => {
                                    this.setState({item: false});
                                    this.remove(emp_item);
                                }} okText="Yes" cancelText="Cancel">
                                    <div className="pointer">
                                        Remove
                                    </div>
                                </Popconfirm>
                            </div>
                        </div>
                    </div>
                )
            })
        }
    }

    editItem = (item) => {

        this.props.display_form();

        window.setTimeout(() => {

            if(item.emp_from != '0000-00-00'){
            
                item.from = moment(item.emp_from);
            }
            if(item.emp_to != '0000-00-00'){
            
                item.to = moment(item.emp_to);
            }

            this.setState({item: item});
            this.refs.update_employment_details.setFieldsValue(item);
        });
        
    }

    renderTags = (tags) => {

        if(tags.length > 0){

            return tags.map((tag) => {

                var key = "tag_" + tag;
                return (

                    <span key={key} className="sl-single-tag">{tag}</span>
                )
            })
        }
    }

    remove = (item) => {

        var formData = new FormData();
        
        formData.append('account_token', this.props.account_token);
        formData.append('item_id', item.id);
        
        var that = this;

        this.setState({remove_loading: true});
        
        Api.post('freelancer/remove_employement', formData, function(data){
            
            that.setState({remove_loading: false});
            if(data.status){
                that.setState({list: data.list});
            }
        });
    }

    loadEmployementList = (account_token) => {
        var formData = new FormData();
        formData.append('account_token', account_token);
        
        var that = this;

        this.setState({loading: true});
        Api.post('freelancer/get_employment', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){
            
                that.setState({list: data.list});
            }
        });
    }
}

export default Employement;
