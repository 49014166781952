import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { Row, Col, Avatar, Tabs, Checkbox, Rate, Form, Input, InputNumber, Modal, Popconfirm, Button } from 'antd';

import Loader from '../../../components/Loader';
import File from '../../../components/File';

import LoginBlock from '../../auth/LoginBlock';

import { CloseOutlined, CloudUploadOutlined, UserOutlined } from '@ant-design/icons';

import FeatherIcon from 'feather-icons-react';

import SingleQuestion from '../../../components/projects/SingleQuestion';
import SingleQuestionReply from '../../../components/projects/SingleQuestionReply';

import Api from '../../../api/Api';

class ProjectInfo extends Component { 
    constructor(props) {
        super();
        this.state = {

            categories: [],
            total: 0,

            account_token: false,
            user: false,

            loading: false,

            is_login: false,

            media_url: '',
            gallery: [],

            login_modal: false,

            milestones: [{
                title: '',
                amount: 0
            }],

            milestones_total: 0,
        }

        this.proposal_form = React.createRef();
        this.proposal_submission = React.createRef();
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');
        
        if(user){

            this.setState({user: JSON.parse(user)});
        }
        if(account_token){

            this.setState({is_login: true, account_token: account_token});
        }
    }

    render () {

        const layout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
        };

        const validateMessages = {
            required: '${label} is required!',
            types: {
              email: '${label} is not valid!',
              number: '${label} is not a valid number!',
            },
            number: {
              range: '${label} must be between ${min} and ${max}',
            },
        };

        var project = this.props.project;
        return (

            <div className="projects-details inner-page pt-2">

                {project
                    ?
                        <div className="container">
                            <div className="page-heading">Project Details</div>

                            {!this.state.user &&
                            
                                <div className="page-subheading">To bid on a project or send proposal to get this project, create an account now.</div>
                            }
                            <Row className="left-right-column" gutter={16}>
                                <Col className="gutter-row" span={16}>
                                    <div className="projects-details-left-section view-details-left-blk">
                                        <div className="top-section">
                                            <div className="block-heading">{project.title}</div>

                                            <div className="details">
                                                {project.featured == 1 &&
                                                    <div className="tag-wrapper">
                                                        <span className="tag-info active">Featured</span>
                                                    </div>
                                                }
                                                <div className="icons">
                                                    <div className="single">
                                                        <i className="mat-ico">watch_later</i>
                                                        <div className="text">{project.posting_time}</div>
                                                    </div>
                                                    <div className="single">
                                                        <i className="mat-ico">favorite</i>
                                                        <div className="text">{project.likes}</div>
                                                    </div>
                                                    <div className="single">
                                                        <i className="mat-ico">visibility</i>
                                                        <div className="text">{project.views_count}</div>
                                                    </div>
                                                </div>

                                                <div className="right-block">
                                                    {project.proposals_count > 0
                                                        ?
                                                            <>
                                                                <span className="profile-images">
                                                                    <span className="profile-img-block with-text">{project.proposals_count}</span>
                                                                </span>
                                                                <span className="profile-text-block">have already sent a proposal</span>
                                                            </>
                                                        :
                                                            this.state.user && (this.state.user.unq_index_id == project.user_unq_id)
                                                                ?
                                                                    null
                                                                :
                                                                    <span className="profile-text-block">Be the first to send the proposal</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <Tabs defaultActiveKey="1" className="blue-tabs" centered>
                                            <Tabs.TabPane tab="Info" key="1">
                                                <div className="info-tab">
                                                    <div className="section main-details">
                                                        <div className="first-section">
                                                            {project.expiry > 0 &&
                                                                <span className="block">
                                                                    <div className="bold-text">{project.expiry}</div>
                                                                    <div className="text">ENDS IN (DAYS)</div>
                                                                </span>
                                                            }
                                                            <span className="block">
                                                                <div className="bold-text">${project.budget_formatted}</div>
                                                                <div className="text">{project.work_type_label} PRICE</div>
                                                            </span>
                                                            {project.level != '' > 0 &&
                                                                <span className="block">
                                                                    <div className="bold-text">{project.level}</div>
                                                                    <div className="text">Experience Level</div>
                                                                </span>
                                                            }
                                                        </div>
                                                        <div className="desc-section">
                                                            <div className="title">Description</div>
                                                            <div className="text">
                                                                <p>{project.description}</p>
                                                            </div>
                                                        </div>

                                                        {(project.attachments && project.attachments.length > 0) &&
                                                            <div className="attachments-section">
                                                                <div className="title">Attachments</div>
                                                                <div className="blocks">

                                                                    {project.attachments.map((_attachment, index) => {

                                                                        return (
                                                                            <a href={_attachment} target="_blank" key={"attachment_" + index}>
                                                                                <span className="block">
                                                                                    <i className="ico jpg-file"></i>
                                                                                </span>
                                                                            </a>
                                                                        )
                                                                    })}
                                                                    
                                                                </div>
                                                            </div>
                                                        }

                                                        {/* {this.state.user && (this.state.user.unq_index_id != project.user_unq_id)
                                                            ? */}
                                                        
                                                                <div ref={this.proposal_form}>
                                                                    {this.state.user
                                                                        ?

                                                                            this.props.proposal_submitted
                                                                                ?

                                                                                    <Row>
                                                                                        <Col span={24} className="left-section">
                                                                                            <div className="new-proposal-wrap">
                                                                                                <h3>Proposal Submitted</h3>
                                                                                                <p>Thank you for submitting your proposal.</p>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                :

                                                                                    (this.state.user.unq_index_id != project.user_unq_id)
                                                                                        ?

                                                                                            this.state.user.profile_progress < 50
                                                                                                ?
                                                                                                    <Row>
                                                                                                        <Col span={24} className="left-section">
                                                                                                            <div className="new-proposal-wrap" style={{padding:'35px 100px'}}>
                                                                                                                <h3>Incomplete Profile</h3>
                                                                                                                <p><strong>A minimum completion rate of 50% is required to continue.</strong></p>

                                                                                                                <p>Your seller profile is off to a the great start. Take a moment to complete the following steps, to help build more trust between you and your customers so you can get more work.</p>
                                                                                                                <a className="btn solid purple square btn-sm" href={Api.url + 'account/profile-update'}>Edit Profile</a>
                                                                                                            </div>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                :
                                                                                                    // <div className="new-proposal-form">
                                                                                                    <div className="new-proposal-details">
                                                                                                        {/* <div className="new-proposal-section"> */}
                                                                                                        
                                                                                                        <Form {...layout} scrollToFirstError={true} name="login_form" initialValues={{ remember: true }} validateMessages={validateMessages} onFinish={this.onFinish.bind(this)}>

                                                                                                            <div className="page-main-content">
                                                                                                                <h3 className="heading">New Proposal</h3>
                                                                                                                <div className="form-wrap">
                                                                                                                    <h4 className="title">Enter your proposal details</h4>
                                                                                                                    <Form.Item name="description" label="" rules={[{required:true, message: 'Please enter your proposal details!'}]}>
                                                                                                                        <Input.TextArea placeholder="Provide general information about your project." />
                                                                                                                    </Form.Item>
                                                                                                                </div>
                                                                                                                <div className="form-wrap">
                                                                                                                    <h4 className="title">Upload Files</h4>

                                                                                                                    <div className="form-gallery">

                                                                                                                        {this.displayGallery()}
                                                                                                                        {this.state.image_uploading
                                                                                                                            ?   
                                                                                                                                <Loader is_small={true} />
                                                                                                                            :
                                                                                                                                null
                                                                                                                        }
                                                                                                                    </div>

                                                                                                                    <label className="cloud-uploader">
                                                                                                                        <input type="file" className="hidden" onChange={(e) => {this._handleImageChange(e)}} ref={(input)=> this.myinput = input}/>
                                                                                                                        <CloudUploadOutlined />
                                                                                                                        <p className="ant-upload-text">Click this area to upload</p>
                                                                                                                    </label>
                                                                                                                </div>
                                                                                                                <div className="form-wrap">
                                                                                                                    <Row>
                                                                                                                        <Col span={24} className="milestones-block border-right">
                                                                                                                            <Row>
                                                                                                                                <Col span={17}>
                                                                                                                                    {/* <h5 className="label">Project Milestone</h5> */}
                                                                                                                                    <label className="" title="Project Milestone">Project Milestone</label>
                                                                                                                                </Col>
                                                                                                                                <Col span={5}>
                                                                                                                                    <label className="" title="Project Milestone">Amount</label>
                                                                                                                                </Col>
                                                                                                                            </Row>
                                                                                                                            {this.displayMilestones()}
                                                                                                                            <Row>
                                                                                                                                <Col span={24}>
                                                                                                                                    <Button type="primary" className="btn dark-blue-btn add-milestone" onClick={() => {
                                                                                                                                        this.addMilestones()
                                                                                                                                    }}>Add</Button>
                                                                                                                                </Col>
                                                                                                                            </Row>
                                                                                                                        </Col>
                                                                                                                    </Row>
                                                                                
                                                                                                                </div>

                                                                                                                <div className="milestone-details">
                                                                                                                    <span className="block">
                                                                                                                        <span className="label">Total</span>
                                                                                                                        <span className="text">${this.state.milestones_total}</span>
                                                                                                                    </span>
                                                                                                                    <span className="block">
                                                                                                                        <span className="label">You'll Learn</span>
                                                                                                                        <span className="text">${this.state.milestones_total}</span>
                                                                                                                    </span>
                                                                                                                    <span className="block">
                                                                                                                        <span className="label">Deposit</span>
                                                                                                                        <span className="text">${this.state.milestones_total}</span>
                                                                                                                    </span>
                                                                                                                </div>

                                                                                                                <div className="terms">
                                                                                                                    <Row justify="space-between" align="bottom">
                                                                                                                        <Col span={24}>
                                                                                                                            <p>Please note that the minimum service fee is 15% excluding GST. </p>
                                                                                                                            <p>Read more</p>
                                                                                                                            <p className="info">
                                                                                                                                You have <b>15 Proposal Credits available</b>. You can always <span>buy more</span>
                                                                                                                            </p>
                                                                                                                        </Col>
                                                                                                                    </Row>
                                                                                                                    <Row justify="space-between" align="bottom">
                                                                                                                        <Col className="buttons-block align-right" span={24}>
                                                                                                                            <Button type="default" loading={this.state.loading} htmlType="submit" className="btn blank-blue-btn"><span>Send</span></Button>
                                                                                                                            <Button type="primary" loading={this.state.loading} htmlType="submit" className="btn dark-blue-btn ml-10"><span>Send & Feature</span></Button>
                                                                                                                        </Col>
                                                                                                                    </Row>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </Form>
                                                                                                    </div>
                                                                                        :
                                                                                            null
                                                                        :
                                                                            <div className="signup-section">
                                                                                <div className="left">
                                                                                    <div className="block-title">New Proposal</div>
                                                                                    <div className="block-text">Create an account now and send a proposal now to get this project.</div>
                                                                                </div>
                                                                                <div className="right">
                                                                                    <Button htmlType="button" type="primary" className="btn dark-blue-btn" size={this.state.sizeLarge} onClick={() => {
                                                                                        this.setState({login_modal: true})
                                                                                    }}>Sign In</Button>
                                                                                </div>
                                                                            </div>
                                                                    }
                                                                </div>
                                                            {/* :
                                                                null
                                                        } */}

                                                    </div>

                                                </div>
                                            </Tabs.TabPane>

                                            <Tabs.TabPane tab="Clarification Board" key="2">
                                                <div className="clarification-board-tab">

                                                    <div className="section no-messages">
                                                        <div className="left">
                                                            <div className="block-title">Clarification Board</div>
                                                            <div className="block-text">There are no clarification messages.</div>
                                                        </div>
                                                        <div className="right">
                                                            <Button type="primary" className="btn dark-blue-btn" size={this.state.sizeLarge}>Ask a Question</Button>
                                                        </div>
                                                    </div>

                                                    <div className="clarification-details">
                                                        <div className="section">
                                                            <div className="left">
                                                                <div className="block-title">Clarification Board</div>
                                                            </div>
                                                            <div className="right">
                                                                <Button type="primary" className="btn blank-blue-btn dark-blue-btn" size={this.state.sizeLarge}>Ask a Question</Button>
                                                            </div>
                                                        </div>
                                                        <Form layout="vertical" requiredMark="true">
                                                            <Form.Item label="Please type your question">
                                                                <Input.TextArea />
                                                            </Form.Item>
                                                            <Form.Item>
                                                                <Checkbox value="1">I confirm that this question is essential for the submission of my proposal and I understand that it will be publicly posted in the project’s clarification board and in case it is used to spam or solely advertise my skills, my rankings will be severely affected.</Checkbox>
                                                            </Form.Item>
                                                            <Form.Item className="buttons-block align-right">
                                                                <Button type="primary" className="btn dark-blue-btn">Send</Button>
                                                            </Form.Item>
                                                        </Form>
                                                    </div>

                                                    <div className="clarification-chat">
                                                        <SingleQuestion />
                                                        <SingleQuestionReply />
                                                        <SingleQuestion />
                                                    </div>
                                                    
                                                </div>
                                            </Tabs.TabPane>

                                        </Tabs>
                                    </div>
                                </Col>
                                <Col className="gutter-row" span={8}>

                                    <div className="projects-details-right-section">

                                        <div className="section">
                                            <div className="profile-basic-details right-blk">
                                                <div className="top"></div>
                                                <div className="bottom">
                                                    
                                                    {this.props.project.profile_pic_full != ''
                                                        ?
                                                            <Avatar size={100} src={this.props.project.profile_pic_full} />
                                                        :
                                                            <Avatar size={100} icon={<UserOutlined />} />
                                                    }

                                                    <div className="name">{this.props.project.freelancer_name}</div>
                                                    <div className="location"><i className="mat-ico">location_on</i> {this.props.project.country_name}</div>
                                                    <div className="ratings-reviews">
                                                        <Rate disabled tooltips={this.state.rate_tooltips} size={this.state.sizeLarge} value={project.freelancer_avg_rating} />
                                                        <div className="rating-text">{project.freelancer_avg_rating} ({project.freelancer_reviews_count})</div>
                                                    </div>

                                                    {project.last_project_date_formatted != '' &&
                                                        <div className="last-project">
                                                            Last Project: <span className="date-text">{project.last_project_date_formatted}</span>
                                                        </div>
                                                    }
                                                    
                                                    <div className="other-details">
                                                        <div className="">
                                                            <div className="num">{project.project_completed}</div>
                                                            <span className="text">Project Completed</span>
                                                        </div>
                                                        <div className="">
                                                            <div className="num">{project.freelancers_worked_with}</div>
                                                            <span className="text">Freelancers worked with</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </Col>
                            </Row>
                        </div>
                    :
                        <div style={{minHeight:600}}>
                            <Loader />
                        </div>
                }

                <Modal
                    visible={this.state.login_modal}
                    width={600}
                    onCancel={() => {
                        this.setState({login_modal: false})
                    }}
                    footer={null}
                >
                    <div className="login-popup">
                        <LoginBlock
                            onSuccess={(account_token) => {
                                window.location = window.location.href;
                            }}
                            onError={(message) => {
                                this.props.onError(message);
                            }}
                        />
                    </div>
                </Modal>
            </div>
        )
    }

    displayGallery = () => {

        var gallery = this.state.gallery;
        var _gallery = [];

        if(gallery && gallery.length > 0){

            for(var i = 0; i < gallery.length; i++){

                var key = 'gallery_item_' + i;
                
                _gallery.push(
                    <li key={key}>

                        <Popconfirm title="Are you sure" placement="topRight" onConfirm={() => {
                            gallery.splice(gallery.indexOf(i), 1);
                            this.setState({gallery: gallery});

                        }} okText="Yes" cancelText="No">
                            <button className="close" type="button">
                                <FeatherIcon icon="x" size={15} />
                            </button>
                        </Popconfirm>
                        
                        <File
                            url={this.state.media_url + gallery[i]}
                        />
                    </li>
                );
            }

            return <ul>{_gallery}</ul>;
        }
        return '';
    }

    calculateTotal = (milestones) => {

        if(milestones.length > 0){

            var total = 0;
            for(var i = 0; i < milestones.length; i++){
                
                total = parseFloat(total) + parseFloat(milestones[i]['amount']);
            }
            this.setState({milestones_total: total});
        }
    }

    displayMilestones = () => {

        var milestones = this.state.milestones;
        if(milestones.length > 0){

            var _milestones = [];

            for(var n = 0; n < milestones.length; n++){

                var i = n;

                _milestones.push(
                    <Row>
                        <Col span={17} className="pr-15">
                            <Form.Item name={"milestone_title["+i+"]"} rules={[{ required: true, message: 'Please enter milestones details' }]}>
                                <Input value={milestones[i]['title']} onChange={(e) => {
                                    milestones[i]['title'] = e.target.value;
                                    this.setState({milestones: milestones});
                                }} />
                            </Form.Item>
                        </Col>
                        <Col span={7}>
                            <Form.Item name={"milestone_amount["+i+"]"} rules={[{ required: true, message: 'Please enter amount' }]}>
                                <InputNumber min={1} value={milestones[i]['amount']} onChange={(e) => {
                                    milestones[i]['amount'] = e;
                                    this.setState({milestones: milestones});
                                    window.setTimeout(() => {
                                        this.calculateTotal(milestones);
                                    },100);
                                }} />
                            </Form.Item>
                        </Col>

                        {i > 0 &&
                            <Col className="remove-icon" span={2}>
                                <Popconfirm title="Are you sure" onConfirm={() => {
                                    milestones.splice(milestones.indexOf(i), 1);
                                    this.setState({milestones: milestones});

                                }} okText="Yes" cancelText="No">
                                    <span className="close-danger"><CloseOutlined /></span>
                                </Popconfirm>
                            </Col>
                        }
                    </Row>
                );
            }
            return _milestones;
        }
        return '';
    }

    addMilestones = () => {

        var milestones = this.state.milestones;
        milestones.push({
            title: '',
            amount: 0
        })
        this.setState({milestones: milestones});
    }

    onFinish = (values) => {
        var formData = new FormData();
        Object.keys(values).forEach((key) => {

            if(values[key]){
                formData.append(key, values[key]);
            }
        });

        formData.append('account_token', this.state.account_token);

        var milestones = this.state.milestones;
        milestones = JSON.stringify(milestones);
        
        formData.append('milestones_data', milestones);

        var gallery = this.state.gallery;
        gallery = JSON.stringify(gallery);
        
        formData.append('gallery', gallery);
        formData.append('project_id', this.props.project.project_unq_id);
        
        var that = this;

        this.setState({loading: true});

        Api.post('proposals/submission', formData, function(data){
            
            that.setState({loading: false});

            if(data.status == true){

                that.props.onSuccess(data.message);
                that.setState({proposal_submitted: true});

                var project = that.props.project;
                project.proposals_count = data.total_submissions;

                that.props.updateProject(project, true);

                that.scrollToSubmission();

            }else{
                
                that.props.onError(data.message);
            }
        });
    }

    scrollToSubmission = () => {

        window.setTimeout(() => {
            this.proposal_form.current.scrollIntoView({ behavior: 'smooth' });
        }, 100);
    }

    updateGallery = (image) => {

        var gallery = this.state.gallery;
        gallery.push(image);
        this.setState({gallery: gallery});
    }

    _handleImageChange = async (e) => {

        e.preventDefault();
    
        let reader = new FileReader();
        let file = e.target.files[0];

        this.setState({image_uploading: true});
    
        reader.readAsDataURL(file);

        const formData = new FormData();
        formData.append('tmp_file_name', file);
        formData.append('allowed_types', 'jpg,jpeg,png,gif,pdf,doc,docx');
        formData.append('upload_dir', 'project_propsals/');
        formData.append('project_id', this.props.project.project_unq_id);

        this.setState({image_uploading: true});
        var that = this;

        Api.post('imageuploader', formData, function(data){

            that.setState({image_uploading: false});

            if(data.status == 'success'){

                var url = data.random_dir + data.main_file_name;
                that.setState({media_url: data.media_url + 'project_propsals/'});

                that.updateGallery(url);
            }
        });
    }
}

export default ProjectInfo;