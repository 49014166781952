import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

// add form components
import { Form, Input, Button, InputNumber, Radio, Alert, Checkbox } from 'antd';

// import registerimage from '../assets/images/register-bg.jpg';

import FeatherIcon from 'feather-icons-react';

import Loader from '../../components/Loader';
import Api from '../../api/Api';

import Main from '../../components/Main';

class Registerpage extends Component { 
    constructor(props) {
        super();
        this.state = {
            loading: false,
            code: false,
            message: false,
            error_message: false,

            registration_type: '',

            mobile_number: '',
            mobile_error: false,

            type: null,

            accept_tnc: false,
            tnc_error: false
        }
    }

    componentDidMount = () => {
        var account_token = localStorage.getItem('account_token');
        
        if(account_token){
            this.setState({code: true});
        }

        var type = this.props.type;
        if(type){
            this.setState({type: type});
        }else{
            this.setState({type: false})
        }
    }

    render () {
        
        if(this.state.type === false){
            return <Redirect to='/'/>;
        }

        if(this.state.code){
            return <Redirect to={`/pending-verification/${this.state.code}`} />;
        }

        const layout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
        };

        const tailLayout = {
            wrapperCol: { span: 24 },
        };

        const validateMessages = {
            required: '${label} is required!',
            types: {
              email: 'Email is not valid!',
              number: 'Mobile number is not a valid number!',
            },
            // number: {
            //   range: '${label} must be between ${min} and ${max}',
            // },
        };

        const onFinish = values => {
            var formData = new FormData();
            // formData.append(type, _type);
            Object.keys(values).forEach(key => formData.append(key, values[key]))

            var that = this;

            if(!this.state.accept_tnc){

                that.setState({error_message: "Please accept terms & conditions."});
                return false;
            }

            Api.post('auth/registration', formData, function(data){
                
                if(data.status == true){
                
                    that.setState({loading: false});
                    that.setState({message: true});

                    window.setTimeout(() => {
                        that.setState({code: data.code});
                    }, 500);
                
                }else{
                    that.setState({loading: false});
                    that.setState({error_message: data.message});
                }
                window.setTimeout(() => {
                    that.setState({error_message: false});
                }, 10000);
            });
        }

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        return (

            <>
                <Main className="signup-main-page" page="signup">

                    <div className="container">
                        <div className="register-page d-flex items-end">
                            
                            <div className="right half-container self-right auth-block" style={{position:'relative'}}>
                                <div className="section-heading">Create a Partner Account</div>

                                {/* using if statement */}
                                {this.state.message &&
                                    <Alert message="Success" description={this.state.message} type="success" closable />
                                }

                                {this.state.error_message &&
                                    <Alert message="Error" description={this.state.error_message} type="error" closable />
                                }
                                
                                <Form {...layout} name="register_form" initialValues={{ remember: true }} validateMessages={validateMessages} onFinish={onFinish} onFinishFailed={onFinishFailed}>

                                    <div className="form-item-block d-flex w-100">
                                        <Form.Item className="type-box" name="type" label="" rules={[{required:true}]}>
                                            <Radio.Group onChange={(e) => {
                                                this.setState({registration_type: e.target.value});
                                            }}>
                                                <Radio value="company">Agency</Radio>
                                                <Radio value="individual">Individual</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </div>

                                    <div className="form-item-block d-flex">
                                        {this.state.registration_type == 'company' &&
                                            <div className="w-100">
                                                <Form.Item name="company_name" rules={[{required:true, message: 'This is a equired entry'}]}>
                                                    <Input placeholder="Company Name" />
                                                </Form.Item>
                                            </div>
                                        }
                                    </div>
                                    
                                    <div className="form-item-block d-flex">
                                        <div className="w-100">
                                            <Form.Item name="name" rules={[{required:true, message: 'This is a equired entry'}]}>
                                                <Input placeholder="Full Name" />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="form-item-block d-flex">
                                        <div className="w-50">
                                            <Form.Item name="email" rules={[{required:true, message: 'Please enter valid email address'}, {type:'email'}]}>
                                                <Input placeholder="Email Address" />
                                            </Form.Item>
                                        </div>                                        
                                        <div className="w-50">
                                            <Form.Item  name="contact_no" rules={[
                                                {required:true, message: 'Please enter valid mobile number'},
                                                { min: 9, message: 'Please enter valid mobile number' }
                                            ]}>
                                                <InputNumber placeholder="Mobile number" />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="form-item-block d-flex">
                                        <div className="w-50">
                                            <Form.Item name="password" rules={[{required:true, message:'Please enter your password!'}]}>
                                                <Input.Password placeholder="Password" />
                                            </Form.Item>
                                        </div>
                                        <div className="w-50">
                                            <Form.Item name="con_password" dependencies={['password']} rules={[{required:true, message:'Please enter confirm password!'},
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject('Entered passwords do not match!');
                                                },
                                            }),]}>
                                                <Input.Password placeholder="Confirm Password" />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="form-item-block d-flex mb-2">
                                        <div className="w-100">
                                            <div>
                                                <Checkbox checked={this.state.accept_tnc} onChange={() => {
                                                    this.setState({accept_tnc: !this.state.accept_tnc})
                                                }} />

                                                <span className="ml-5">By Signing up, I confirm that I accept the <Link>terms of service</Link> and <Link>privacy policy</Link>.</span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <Form.Item {...tailLayout} className="text-center">
                                        <Button type="primary" size="large" htmlType="submit" className="btn-orange btn-wide">Register</Button>
                                    </Form.Item>
                                </Form>

                                <div className="text-center login-link">
                                    Already have an account? <Link to="/login" className="pink-txt">Login</Link>
                                </div>

                                {/* using if statement */}
                                {this.state.loading &&
                                    <Loader />
                                }
                            </div>
                        </div>
                    </div>
                </Main>
            </>
        )
    }
}

export default Registerpage;