import React, { Component } from 'react';

// add form components
import { Progress } from 'antd';

import FeatherIcon from 'feather-icons-react';

import { StarFilled } from '@ant-design/icons';

import ProfilePic from '../../components/ProfilePic';

import Api from '../../api/Api';

class ProjectFlowSidebar extends Component { 
    constructor(props) {
        super();
        this.state = {

            loading: false,

        }
    }
    
    render () {

        var user = this.props.project.project_view == 1 ? this.props.buyer : this.props.seller;
        return (

            <>
                <div className="client-details-block">
                    <div className="item top--item client-details">
                        <div className="img-block">
                            <ProfilePic src={user.profile_pic_full} />
                        </div>
                        <div className="details">
                            <div className="name">{user.name}</div>
                            <div className="rating">
                                <div className="ratings">
                                    <span>{user.avg_rating}</span>
                                    <StarFilled className="ico" />
                                </div>
                                <div className="top-text">
                                    <Progress percent={2} showInfo={false} strokeColor="#FF4500" trailColor="#e6e6e6" type="line" strokeWidth={6} />
                                    <span>{user.reviews_count} Reviews</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <span className="label">From</span>
                        <span className="text">{user.country}</span>
                    </div>
                    <div className="item">
                        <span className="label">Earnings</span>
                        <span className="text">$115</span>
                    </div>
                    <div className="item">
                        <span className="label">In this Escrow</span>
                        <span className="text">$1</span>
                    </div>
                    <div className="item">
                        <span className="label">Unpaid Invoices</span>
                        <span className="text">$1</span>
                    </div>
                </div>
            </>
        )
    }
}

export default ProjectFlowSidebar;