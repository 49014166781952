'use strict';

const Constants = {
    URL: {
        // root: 'http://localhost:3000/',
        // api_url: 'http://localhost/cogniticx_app/req/'
        root: 'https://cogniticx.digitalflic.com/',
        api_url: 'https://cogniticx.digitalflic.com/app/req/'
    },
    API_KEY: '451SDFrs'
}

module.exports = Constants;