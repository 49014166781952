import React, { Component } from 'react';

import { Row, Col, Tabs, Form, Input, InputNumber, Upload, Rate, message, Checkbox, Modal, Popconfirm, Button } from 'antd';

import RelatedProjectsSection from '../../components/sections/projects/RelatedProjectsSection';

import Main from '../../components/Main';

import Api from '../../api/Api';

import LayoutHelper from '../../helpers/LayoutHelper';

import ProjectInfo from './blocks/ProjectInfo';

class ProjectDetailsPage extends Component { 
    constructor(props) {
        super();
        this.state = {

            error_message: '',
            success_message: '',
            
            back: false,
            project: false,
            related_projects: [],
            
            user: false,
            account_token: false,

            proposal_submitted: false,

            loading: false,
        }

        this.proposal_form = React.createRef();
        this.proposal_submission = React.createRef();
    }

    componentDidMount = () => {

        var user = localStorage.getItem('user');
        user = JSON.parse(user);

        if(user){

            this.setState({user: user});
        }

        var account_token = localStorage.getItem('account_token');
        if(account_token){

            this.setState({account_token: account_token});
        }

        const project_slug = this.props.slug;

        if(project_slug && project_slug != ''){

            this.loadData(project_slug, account_token);
        }else{

            this.setState({back: true});
        }
        
    }

    render () {

        return (

            <Main full_content={true} error_message={this.state.error_message} success_message={this.state.success_message}>
                <ProjectInfo
                    project={this.state.project}
                    onError={(message) => {
                        LayoutHelper.addErrorMessage(this, message)
                    }}
                    onSuccess={(message) => {
                        LayoutHelper.addSuccessMessage(this, message)
                    }}
                    updateProject={(project, status) => {
                        this.setState({project: project, proposal_submitted: status});
                    }}
                    proposal_submitted={this.state.proposal_submitted}
                    updateProposalSubmitted={(status) => {
                        this.setState({proposal_submitted: status})
                    }}
                />
                
                <RelatedProjectsSection
                    related_projects={this.state.related_projects}
                    loadData={(slug) => {

                        this.loadData(slug, this.props.account_token);
                    }}
                />
            </Main>
            
        )
    }

    loadData = (slug, account_token) => {

        var formData = new FormData();
        formData.append('slug', slug);
        formData.append('source', 'project_view');

        if(account_token){

            formData.append('account_token', account_token);
        }

        var that = this;
        this.setState({loading: true});

        Api.post('projects/single', formData, function(data){
            
            that.setState({loading: false});

            if(data.status){

                if(data.submitted){
                    that.setState({proposal_submitted: data.submitted});
                }

                that.setState({project: data.project});
                that.setState({related_projects: data.related_projects});
                console.log(data.related_projects);
            }else{
                that.setState({back: true});
            }
        });
    }
}

export default ProjectDetailsPage;