import React, { Component } from 'react';

import { Tabs, Form, Input, InputNumber, Button, Select, Row, Col, DatePicker, Tag } from 'antd';

import { Carousel  } from 'antd';
import FeatherIcon from 'feather-icons-react';

import Employement from './blocks/Employement';
import Education from './blocks/Education';
import Certifications from './blocks/Certifications';
import Experience from './blocks/Experience';

import Api from '../../api/Api';

const { Option } = Select;

class ProfessionalInfo extends Component { 
    constructor(props) {
        super();
        this.state = {
            dateFormat: 'DD-MM-YYYY',
            redirect: false,

            user: false,

            loading: false,

            display_employement_form: false,
            
            display_education_form: false,

            display_certification_form: false,

            display_experience_form: false,

            job_industry: false,
            domain: false,

            univercity: [],
            courses_programms_data: [],
            data_science_domains: [],
            highest_qualification: [],

            continue: false,
        }

        this.education_block = React.createRef();
        this.employment_block = React.createRef();
        this.certification_block = React.createRef();
        this.experience_block = React.createRef();
    }

    componentDidMount = () => {
        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');

        var _user = JSON.parse(user);
        this.setState({user: _user});

        this.populateForm(_user);
        
        if(account_token){

            this.setState({job_industry: _user.job_industry});
            
            this.setState({account_token: account_token});

            this.loadInputsData();
        }else{
            this.setState({redirect: true});
        }
    }

    populateForm = (details) => {

        if(details.domain == ''){
            delete details.domain;
        }
        if(details.tools == ''){
            delete details.tools;
        }
        if(details.tools_others == ''){
            delete details.tools_others;
        }
        if(details.skills == ''){
            delete details.skills;
        }
        if(details.skills_others == ''){
            delete details.skills_others;
        }
        if(details.course == ''){
            delete details.course;
        }
        if(details.job_industry == ''){
            delete details.job_industry;
        }/*else{

            details.job_industry = details.job_industry;
        }*/

        if(details.data_science_domains == ''){
            delete details.data_science_domains;
        }
        if(details.industry_activity == ''){
            delete details.industry_activity;
        }
        if(details.industry_market == ''){
            delete details.industry_market;
        }
        if(details.industry_language_proficiency == ''){
            delete details.industry_language_proficiency;
        }
        if(details.industry_industry == ''){
            delete details.industry_industry;
        }

        this.refs.update_professional_info_form.setFieldsValue(details);
    }

    onFinish = (values) => {
        var formData = new FormData();
        formData.append('account_token', this.state.account_token);

        Object.keys(values).forEach((key) => {

            if(values[key]){
                formData.append(key, values[key])
            }
        });
        
        var that = this;

        this.setState({loading: true});
        Api.post('freelancer/profile_update_professional_info', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){
            
                that.props.updateUser(data.user);
                that.props.onSuccess(data.message, that.state.continue);
                that.populateForm(data.user);
            
            }else{
                that.props.onError(data.message);
            }
        });
    }

    get_data = (industry, type) => {

        var industries_list = this.props.industries_list;
        for(var i in industries_list){

            if(industries_list[i].hasOwnProperty(type) && industries_list[i]['label'] == industry){

                return industries_list[i][type];
            }
        }

        return false;
    }

    render_sub_data = () =>{

        if(this.state.job_industry && this.state.job_industry != ''){

            var list = this.get_data(this.state.job_industry, 'role');
            var experiences = this.get_data(this.state.job_industry, 'experiences');

            var data = [];

            if(list != false && list.length > 0){

                data.push(
                    <Form.Item className="w-33" label="Roles" name="industry_role" rules={[{required:true}]}>
                        <Select placeholder="Select role" allowClear>
                            {list.map((_role) => {
                                
                                var key = 'role_item_' + _role.id;
                                
                                return <Option key={key} value={_role.label}>{_role.label}</Option>
                            })}
                        </Select>
                    </Form.Item>
                )
            }

            if(experiences != false && experiences.length > 0){

                data.push(
                    <Form.Item className="w-33" label="Experience" name="industry_experience" rules={[{required:true}]}>
                        <Select placeholder="Select experience" allowClear>
                            {experiences.map((_experiences) => {
                                
                                var key = 'experiences_item_' + _experiences.id;
                                
                                return <Option key={key} value={_experiences.label}>{_experiences.label}</Option>
                            })}
                        </Select>
                    </Form.Item>
                )
            }

            return data;
        }
    }

    render () {
        const layout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
        };

        const tailLayout = {
            wrapperCol: { span: 24 },
        };

        const validateMessages = {
            required: '${label} is required!',
            types: {
              email: '${label} is not valid!',
              number: '${label} is not a valid number!',
            },
            number: {
              range: '${label} must be between ${min} and ${max}',
            },
        };

        return (

            <div className="update-profile-page d-flex">
                    
                <div className="profile-wrapper">
                    <Row>
                        <Col span={24}>
                            
                            <div className="profile-main-wrapper">

                                <div className="page-heading">Professional Information</div>

                                <Form {...layout} scrollToFirstError={true} name="update_professional_info_form" ref="update_professional_info_form" initialValues={{ remember: true }} validateMessages={validateMessages} onFinish={this.onFinish.bind(this)}>

                                    <div className="d-flex form-item-block">
                                        <Form.Item className="w-50" label="Choose Category" extra="Please choose max 3 categories." name="job_industry" rules={[{required:true, type:'array'}]}>
                                            <Select
                                                showSearch
                                                mode="multiple"
                                                placeholder="Select category"
                                                allowClear
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                onChange={(value) => {
                                                    var values = this.refs.update_professional_info_form.getFieldValue('job_industry');
                                                    if(parseInt(values.length) > 3){
                                                        values.splice(-1, 1);
                                                    }
                                                    this.setState({job_industry: values});
                                                }
                                                
                                            }>
                                                {this.props.industries_list && this.props.industries_list.length > 0
                                                    ?
                                                        this.props.industries_list.map((_industries_list) => {
                                                            
                                                            var key = 'industry_item_' + _industries_list.id;
                                                            
                                                            return <Option key={key} value={_industries_list.label}>{_industries_list.label}</Option>
                                                        })
                                                    :
                                                        null
                                                }
                                            </Select>
                                        </Form.Item>

                                        <Form.Item className="w-25" label="Choose Industry" extra="Please choose max 3 industry." name="domain" rules={[{required:true, type:'array'}]}>
                                            <Select
                                                showSearch
                                                mode="multiple"
                                                placeholder="Select Industry"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                onChange={(value) => {
                                                    var values = this.refs.update_professional_info_form.getFieldValue('domain');
                                                    if(parseInt(values.length) > 3){
                                                        values.splice(-1, 1);
                                                    }
                                                }}
                                            >
                                                {this.props.industry && this.props.industry.length > 0
                                                    ?
                                                        this.props.industry.map((industry_item) => {
                                                            
                                                            var key = 'industry_' + industry_item.id;
                                                            
                                                            return <Option key={key} value={industry_item.id}>{industry_item.label}</Option>
                                                        })
                                                    :
                                                        null
                                                }
                                            </Select>
                                        </Form.Item>

                                        <Form.Item className="w-25" label="Others" name="domain_others" rules={[{required:false}]}>
                                            <Input placeholder="If others" />
                                        </Form.Item>

                                    </div>

                                    <div className="d-flex form-item-block">
                                        <Form.Item className="w-50" label="Choose Skills" name="skills" rules={[{required:true, type:'array'}]}>
                                            <Select mode="tags" placeholder="Select skills" allowClear>
                                                {this.props.skills && this.props.skills.length > 0
                                                    ?
                                                        this.props.skills.map((skill) => {
                                                            
                                                            var key = 'skill_' + skill.id;
                                                            
                                                            return <Option key={key} value={skill.id}>{skill.label}</Option>
                                                        })
                                                    :
                                                        null
                                                }
                                            </Select>
                                        </Form.Item>

                                        <Form.Item className="w-50" label="Others" name="skills_others" rules={[{required:false}]}>
                                            <Input placeholder="If others" />
                                        </Form.Item>
                                    </div>

                                    <div className="d-flex form-item-block">
                                        <Form.Item className="w-50" label="Choose Tools" name="tools" rules={[{required:true, type:'array'}]}>
                                            <Select mode="tags" placeholder="Select tools" allowClear>
                                                {this.props.tools && this.props.tools.length > 0
                                                    ?
                                                        this.props.tools.map((tool) => {
                                                            
                                                            var key = 'tool_' + tool.id;
                                                            
                                                            return <Option key={key} value={tool.id}>{tool.label}</Option>
                                                        })
                                                    :
                                                        null
                                                }
                                            </Select>
                                        </Form.Item>

                                        <Form.Item className="w-50" label="Others" name="tools_others" rules={[{required:false}]}>
                                            <Input placeholder="If others" />
                                        </Form.Item>
                                    </div>

                                    <div className="block-heading with-line mt-2">
                                        <span className="">Data Science Details & Experience</span>
                                    </div>

                                    <div className="d-flex form-item-block">
                                        <Form.Item className="w-25" label="Total Work Experience (yrs)" name="total_work_experience" rules={[{required:true}]}>
                                            <Select placeholder="Choose option" allowClear>
                                                <Option value="1">1</Option>
                                                <Option value="2">2</Option>
                                                <Option value="3">3</Option>
                                                <Option value="4">4</Option>
                                                <Option value="5">5</Option>
                                                <Option value="6">6</Option>
                                                <Option value="7">7</Option>
                                                <Option value="8">8</Option>
                                                <Option value="9">9</Option>
                                                <Option value="10+">10+</Option>
                                            </Select>
                                        </Form.Item>

                                        <Form.Item className="w-25" label="Data Science Experience (yrs)" name="data_science_experience" rules={[{required:true}]}>
                                            <Select placeholder="Choose option" allowClear>
                                                <Option value="1">1</Option>
                                                <Option value="2">2</Option>
                                                <Option value="3">3</Option>
                                                <Option value="4">4</Option>
                                                <Option value="5">5</Option>
                                                <Option value="6">6</Option>
                                                <Option value="7">7</Option>
                                                <Option value="8">8</Option>
                                                <Option value="9">9</Option>
                                                <Option value="10+">10+</Option>
                                            </Select>
                                        </Form.Item>

                                        <Form.Item className="w-25" label="High Qualification" name="high_qualification" rules={[{required:true}]}>
                                            <Select
                                                showSearch
                                                placeholder="Choose option"
                                                allowClear
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {this.state.highest_qualification && this.state.highest_qualification.length > 0
                                                    ?
                                                        this.state.highest_qualification.map((_highest_qualification) => {
                                                            
                                                            var key = 'q_' + _highest_qualification.id;
                                                            
                                                            return <Option key={key} value={_highest_qualification.label}>{_highest_qualification.label}</Option>
                                                        })
                                                    :
                                                        null
                                                }
                                            </Select>
                                        </Form.Item>

                                        <Form.Item className="w-25" label="Others" name="high_qualification_others" rules={[{required:false}]}>
                                            <Input placeholder="If others" />
                                        </Form.Item>
                                    </div>

                                    {/* <div className="d-flex form-item-block">
                                        <Form.Item className="w-25" label="Course / Programs" name="course" rules={[{required:true, type: 'array'}]}>
                                            <Select
                                                showSearch
                                                mode="multiple"
                                                placeholder="Choose option"
                                                allowClear
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {this.state.courses_programms_data && this.state.courses_programms_data.length > 0
                                                    ?
                                                        this.state.courses_programms_data.map((_courses_programms_data) => {
                                                            
                                                            var key = 'course_pro_' + _courses_programms_data.id;
                                                            
                                                            return <Option key={key} value={_courses_programms_data.label}>{_courses_programms_data.label}</Option>
                                                        })
                                                    :
                                                        null
                                                }
                                            </Select>
                                        </Form.Item>

                                        <Form.Item className="w-25" label="Others" name="course_others" rules={[{required:false}]}>
                                            <Input placeholder="If others" />
                                        </Form.Item>

                                        <Form.Item className="w-25" label="University / College" name="university" rules={[{required:true}]}>
                                            <Select
                                                showSearch
                                                placeholder="Choose option"
                                                allowClear
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {this.state.univercity && this.state.univercity.length > 0
                                                    ?
                                                        this.state.univercity.map((_univercity) => {
                                                            
                                                            var key = 'univercity_' + _univercity.id;
                                                            
                                                            return <Option key={key} value={_univercity.name}>{_univercity.name}</Option>
                                                        })
                                                    :
                                                        null
                                                }
                                            </Select>
                                        </Form.Item>

                                        <Form.Item className="w-25" label="Others" name="university_others" rules={[{required:false}]}>
                                            <Input placeholder="If others" />
                                        </Form.Item>
                                    </div> */}

                                    <div className="d-flex form-item-block">
                                        <Form.Item className="w-50" label="Data Science Domains" extra="Please choose max 3 domains." name="data_science_domains" rules={[{required:true, type: 'array'}]}>
                                            <Select
                                                showSearch
                                                mode="multiple"
                                                placeholder="Choose option"
                                                allowClear
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                onChange={(value) => {
                                                    var values = this.refs.update_professional_info_form.getFieldValue('data_science_domains');
                                                    if(parseInt(values.length) > 3){
                                                        values.splice(-1, 1);
                                                    }
                                                }}
                                            >
                                                {this.state.data_science_domains && this.state.data_science_domains.length > 0
                                                    ?
                                                        this.state.data_science_domains.map((_data_science_domains) => {
                                                            
                                                            var key = 'ds_' + _data_science_domains.id;
                                                            
                                                            return <Option key={key} value={_data_science_domains.label}>{_data_science_domains.label}</Option>
                                                        })
                                                    :
                                                        null
                                                }
                                            </Select>
                                        </Form.Item>

                                        <Form.Item className="w-50" label="Others" name="data_science_domains_others" rules={[{required:false}]}>
                                            <Input placeholder="If others" />
                                        </Form.Item>
                                    </div>
                                    
                                </Form>

                                <div className="block-heading with-line mt-2">
                                    <span className="">Education</span>
                                </div>

                                <div className="custom-form-block" ref={this.education_block}>

                                    <Education
                                        hide_form={() => {
                                            this.setState({display_education_form: false});
                                            this.education_block.current.scrollIntoView({ behavior: 'smooth' });
                                        }}
                                        display_form={() => {
                                            this.setState({display_education_form: true});
                                            this.education_block.current.scrollIntoView({ behavior: 'smooth' });
                                        }}
                                        courses_programms_data={this.state.courses_programms_data}
                                        univercity={this.state.univercity}
                                        display={this.state.display_education_form}
                                        user={this.state.user}
                                        onError={(message) => {
                                            this.props.onError(message);
                                        }}
                                        account_token={this.state.account_token}
                                        updateUser={(user) => {
                                            this.props.updateUser(user);
                                        }}
                                    />
                                    {!this.state.display_education_form &&
                                        <div className="form-item-block education-add-block text-center mt-5">
                                            <Button type="primary" onClick={() => {
                                                this.setState({display_education_form: true});
                                                this.education_block.current.scrollIntoView({ behavior: 'smooth' });
                                            }}>+ Add Education</Button>
                                        </div>
                                    }
                                </div>

                                <div className="block-heading with-line mt-4">
                                    <span className="">Employment / Experience</span>
                                </div>

                                <div className="custom-form-block" ref={this.employment_block}>

                                    <Employement
                                        hide_form={() => {
                                            this.setState({display_employement_form: false});
                                            this.employment_block.current.scrollIntoView({ behavior: 'smooth' });
                                        }}
                                        display_form={() => {
                                            this.setState({display_employement_form: true});
                                            this.employment_block.current.scrollIntoView({ behavior: 'smooth' });
                                        }}
                                        display={this.state.display_employement_form}
                                        user={this.state.user}
                                        onError={(message) => {
                                            this.props.onError(message);
                                        }}
                                        account_token={this.state.account_token}
                                        updateUser={(user) => {
                                            this.props.updateUser(user);
                                        }}
                                    />

                                    {!this.state.display_employement_form &&
                                        <div className="form-item-block employment-add-block text-center mt-5">
                                            <Button type="primary" onClick={() => {
                                                this.setState({display_employement_form: true});
                                                this.employment_block.current.scrollIntoView({ behavior: 'smooth' });
                                            }}>+ Add Employment</Button>
                                        </div>
                                    }
                                </div>
                                
                                <div className="block-heading with-line mt-4">
                                    <span className="">Certifications</span>
                                </div>

                                <div className="custom-form-block" ref={this.certification_block}>
                                    <Certifications
                                        hide_form={() => {
                                            this.setState({display_certification_form: false});
                                            this.certification_block.current.scrollIntoView({ behavior: 'smooth' });
                                        }}
                                        display_form={() => {
                                            this.setState({display_certification_form: true});
                                            this.certification_block.current.scrollIntoView({ behavior: 'smooth' });
                                        }}
                                        display={this.state.display_certification_form}
                                        user={this.state.user}
                                        onError={(message) => {
                                            this.props.onError(message);
                                        }}
                                        account_token={this.state.account_token}
                                        updateUser={(user) => {
                                            this.props.updateUser(user);
                                        }}
                                    />

                                    {!this.state.display_certification_form &&
                                        <div className="form-item-block certifications-add-block text-center">
                                            <Button type="primary" className="mt-5" onClick={() => {
                                                this.setState({display_certification_form: true});
                                                this.certification_block.current.scrollIntoView({ behavior: 'smooth' });
                                            }}>+ Add Certifications</Button>
                                        </div>
                                    }
                                </div>
                                
                                {/* <div className="block-heading with-line mt-4">
                                    <span className="">Experience</span>
                                </div>

                                <div className="custom-form-block" ref={this.experience_block}>
                                    <Experience
                                        hide_form={() => {
                                            this.setState({display_experience_form: false});
                                            this.experience_block.current.scrollIntoView({ behavior: 'smooth' });
                                        }}
                                        display_form={() => {
                                            this.setState({display_experience_form: true});
                                            this.experience_block.current.scrollIntoView({ behavior: 'smooth' });
                                        }}
                                        display={this.state.display_experience_form}
                                        user={this.state.user}
                                        onError={(message) => {
                                            this.props.onError(message);
                                        }}
                                        account_token={this.state.account_token}
                                        updateUser={(user) => {
                                            this.props.updateUser(user);
                                        }}
                                    />

                                    {!this.state.display_experience_form &&
                                        <div className="form-item-block experience-add-block text-center">
                                            <button type="button" className="btn btn-sm btn-blue-txt add-experience-block" onClick={() => {
                                                this.setState({display_experience_form: true});
                                                this.experience_block.current.scrollIntoView({ behavior: 'smooth' });
                                            }}>+ Add Experience</button>
                                        </div>
                                    }
                                </div> */}

                                <div className="d-flex form-item-block justify-content-end">
                                    <Form.Item {...tailLayout}>

                                        <Button className="btn" onClick={() => {
                                            this.setState({continue: false});
                                            this.refs.update_professional_info_form.submit();
                                        }} type="secondary" htmlType="submit" loading={this.state.loading}>Save</Button>
                                        <Button className="btn ml-10" onClick={() => {
                                            this.setState({continue: true});
                                            this.refs.update_professional_info_form.submit();
                                        }} type="primary" htmlType="submit" loading={this.state.loading}>Save & Continue</Button>
                                    </Form.Item>
                                </div>
                                    
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }

    loadInputsData = () => {
        var formData = new FormData();
        formData.append('account_token', this.state.account_token);

        var that = this;

        this.setState({loading: true});
        Api.post('data/inputs_data', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){
            
                that.setState({
                    univercity: data.univercity,
                    courses_programms_data: data.courses_programms_data,
                    data_science_domains: data.data_science_domains,
                    highest_qualification: data.highest_qualification,
                })
            
            }
        });
    }
}

export default ProfessionalInfo;