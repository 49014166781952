import React, { Component } from 'react';

export default class Loader extends Component { 
    
    render () {                                   
        return (

            <div className={"wd-loader-block " + (this.props.is_small ? "wd-loader-block-sm" : '')}>
                <img className={"wd-loader " + (this.props.is_small ? "wd-loader-sm" : '')} src={require('../assets/images/loader.svg')} />
            </div>
        )
    }
}