import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

// add form components
import { Empty, Button, Menu, Select, Row, Col, Dropdown, List, Avatar } from 'antd';

import FeatherIcon from 'feather-icons-react';

import{ MessageOutlined, UserOutlined } from '@ant-design/icons';

import ProfilePic from '../../components/ProfilePic';

import Api from '../../api/Api';

import Main from '../../components/Main';
import LayoutHelper from '../../helpers/LayoutHelper';

const { Option } = Select;

class Messages extends Component { 
    constructor(props) {
        super();
        this.state = {

            error_message: '',
            success_message: '',

            account_token: false,
            redirect: false,
            loading: false,

            users: [],
            no_users: false,

            messages: [],

            message_text: '',

            active_project: false,

            last_row_id: false
        }

        this.timeline = React.createRef();
        this.last_message = React.createRef();
    }

    componentDidMount = () => {
        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');
        
        if(account_token){
            
            this.setState({account_token: account_token, user: user})
        }else{
            this.setState({redirect: true});
        }

        if(user){
            this.setState({user: JSON.parse(user)});

            this.loadProjects(account_token);
        }
    }

    loadProjects = (account_token) => {
        
        var formData = new FormData();
        
        formData.append('account_token', account_token);
        
        var that = this;

        this.setState({loading: true});
        Api.post('messages/users', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){
            
                if(data.users.length > 0){

                    that.setState({users: data.users, no_users: false});
                    
                    var project_id = that.props.project_id;
                    if(project_id){
                        
                        that.loadMessages(project_id, false);
                    }

                }else{

                    that.setState({no_users: true, users: []});
                }
            }else{

                // if(data.code == 'no_account'){
                //     window.location = Api.url + '/logout';
                // }
                // LayoutHelper.addErrorMessage(data.message);
            }
        });
    }

    filterDropdown = () => {

        return (
            <Menu>
                <Menu.Item key="0">
                    <Link to="/post-a-project">Post a project</Link>
                </Menu.Item>
                <Menu.Item key="0">
                    <Link>Find An Expert</Link>
                </Menu.Item>
                <Menu.Item key="0">
                    <Link>Browse Categories</Link>
                </Menu.Item>
                <Menu.Item key="0">
                    <Link>How It Works</Link>
                </Menu.Item>
            </Menu>
        )
    }

    msgFilterDropdown = () => {

        return (
            <Menu>
                <Menu.Item key="0">
                    <Link to="/">Oldest</Link>
                </Menu.Item>
            </Menu>
        )
    }

    getUserImage = (user) => {

        if(user.freelancer_id == this.state.user.unq_index_id){

            if(user.buyer_profile_pic != ''){

                return <Avatar src={user.buyer_profile_pic} size={45} />
            }else{

                return <Avatar icon={<UserOutlined />} />
            }
        }else{

            if(user.freelancer_profile_pic != ''){

                return <Avatar src={user.freelancer_profile_pic} />
            }else{

                return <Avatar icon={<UserOutlined />} />
            }
        }
    }

    userName = (user) => {

        if(user.freelancer_id == this.state.user.unq_index_id){

            return user.buyer_name;
        }else{

            return user.freelancer_name;
        }
    }

    usersList = () => {

        if(this.state.users.length > 0){

            return (

                <List
                    className="user-messages-sidebar-list"
                    itemLayout="horizontal"
                    dataSource={this.state.users}
                    renderItem={item => (
                        <List.Item className={this.state.active_project && this.state.active_project.project_id == item.project_id ? 'active' : ''}>
                            <List.Item.Meta
                                avatar={this.getUserImage(item)}
                                title={<a className="title" onClick={() => {
                                    this.loadMessages(item.project_id, false);
                                    this.setState({active_project: item});
                                    this.repeatLoadMessages();
                                }}>
                                    <strong>{this.userName(item)}</strong>
                                    <span>{item.project_title}</span>
                                </a>}
                                description={item.message}
                            />
                        </List.Item>
                    )}
                />
            )
        }else{

            return <Empty />
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    repeatLoadMessages = () => {

        var that = this;
        this.interval = setInterval(() => {

            if(!that.state.loading){

                that.loadMessages(this.state.active_project.project_id, false);
            }
        }, 5000);
    }

    render () {

        return (

            <Main page_title="PostProjectpage" hide_page_title="true" className="messages account-bg" page="messages" error_message={this.state.error_message} success_message={this.state.success_message}>

                <div className="section container-lg d-flex">
                    
                    <div className="messages-wrapper">
                        <Row>
                            <Col span={6}>
                                
                                <div className="sidebar-filter-dropdown-wrapper">
                                    <div className="sidebar-filter-dropdown">
                                        <Dropdown overlay={this.msgFilterDropdown()} trigger={['click']} placement="bottomCenter">
                                            <div>
                                                <span>Filter By:</span>
                                                <strong>All Recent</strong>
                                                <FeatherIcon icon="chevron-down" />
                                            </div>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className="sidebar-col">
                                    <div className="sidebar-col-section">
                                        <h3>Clarifications</h3>
                                    </div>
                                    <div className="sidebar-col-section main">
                                        
                                        {this.usersList()}
                                    </div>
                                </div>
                            </Col>
                            <Col span={13}>
                                
                                <div className="messages-body">

                                    <div className="project-details">
                                        <div className="timeline-block" ref={this.timeline}>

                                            <List
                                                className="user-messages-list"
                                                itemLayout="horizontal"
                                                dataSource={this.state.messages}
                                                renderItem={item => (
                                                    <List.Item className="message-row">
                                                        <List.Item.Meta
                                                            description={this.messageView(item)}
                                                        />
                                                    </List.Item>
                                                )}
                                            />

                                            <div ref={this.last_message}></div>

                                            {this.state.messages.length <= 0
                                                ?
                                                    <Empty className="empty-lg" image={<MessageOutlined className="empty-icon" />} description="Please choose a client from left to start the conversation" />
                                                :
                                                    null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="message-footer">
                                    <div>
                                        <FeatherIcon icon="paperclip" />
                                    </div>
                                    <div className="message-input">
                                        <textarea id="message_input" onChange={(e) => {
                                            this.setState({message_text: e.target.value})
                                        }} style={{padding:10, fontSize:14}}></textarea>
                                    </div>
                                    <div className="message-action">
                                        <Button loading={this.state.loading} className="btn" type="danger" onClick={() => {
                                            this.sendMessage()
                                        }}>Send</Button>
                                    </div>
                                </div>
                                
                            </Col>
                            <Col span={5}>
                                <div className="sidebar-col">
                                    <div className="sidebar-col-section">
                                        <h3>Orders</h3>
                                    </div>
                                    <div className="sidebar-col-section main">
                                        <Empty />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Main>
        )
    }

    messageView = (message) => {

        var type = 'owner';
        if(this.state.active_project.freelancer_id == this.state.user.unq_index_id){

            type = 'freelancer';
        }

        var position = 'left';
        if(type == 'owner' && message.buyer_profile_pic != ''){

            position = 'right';
        }

        if(type == 'freelancer' && message.freelancer_profile_pic != ''){

            position = 'right';
        }

        var profile_pic = message.buyer_profile_pic;
        if(type == 'freelancer' && message.freelancer_profile_pic != ''){

            var profile_pic = message.freelancer_profile_pic;
        }

        if(type == 'owner' && message.freelancer_profile_pic != ''){

            var profile_pic = message.freelancer_profile_pic;
        }

        return (

            <div className={"block chat " + position}>
            
                <div className="icon">
                    <ProfilePic src={profile_pic} />
                </div>

                <div className="details">
                    <div className="top">
                        <div className="text lg">{message.message}</div>
                    </div>
                    <div className="time-block">{message.time}</div>
                </div>
            </div>
        )
    }

    scrollToBottom = () => {

        this.timeline.current.scrollTo(0, this.last_message.current.offsetTop);
    }

    sendMessage = () => {

        if(this.state.active_project){

            var formData = new FormData();
            formData.append('account_token', this.state.account_token);
            formData.append('project', this.state.active_project.project_id);

            var message = this.state.message_text;

            if(message == ''){

                return false;
            }else{

                formData.append('message', message);
            
                var that = this;

                this.setState({loading: true});
                Api.post('messages/send', formData, function(data){
                    
                    that.setState({loading: false, message_text: ''});
                    document.getElementById('message_input').value = '';
                    
                    if(data.status == true){
                    
                        var messages = that.state.messages;
                        messages.push(data.row);

                        var last_row_id = data.row.ref;

                        that.setState({messages: messages, last_row_id: last_row_id});
                        that.scrollToBottom();
                    
                    }else{
                        // that.props.onError(data.message);
                    }
                });
            }
        }
    }

    loadMessages = (project_id, reload) => {

        var formData = new FormData();
        
        formData.append('account_token', this.state.account_token);
        formData.append('project', project_id);
        
        if(this.state.last_row_id){

            formData.append('ref', this.state.last_row_id);
        }
        
        var that = this;

        this.setState({loading: reload});
        Api.post('messages/load', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){
            
                // that.setState({messages: data.messages});
                // that.scrollToBottom();

                var last_row_id = that.state.last_row_id;
                var has_new_entry = false;

                var messages = that.state.messages;

                if(data.messages.length > 0){

                    has_new_entry = true;
                    for(var i in data.messages){

                        var entry = data.messages[i];
                        last_row_id = entry.ref;
                    }
                }
                
                messages.push(...data.messages);

                that.setState({
                    last_row_id: last_row_id,
                    no_data: false,
                });

                if(has_new_entry){

                    that.scrollToBottom();
                }
            }else{

                // if(data.code == 'no_account'){
                //     window.location = Api.url + '/logout';
                // }
                // LayoutHelper.addErrorMessage(data.message);
            }
        });
    }
}

export default Messages;