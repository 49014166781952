import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { CheckCircleTwoTone } from '@ant-design/icons';

import Main from '../../components/Main';

class EmailVerification extends Component { 
    constructor(props) {
        super();
        this.state = {
            loading: false,
            login: false,

            error_message: '',
            success_message: '',
        }
    }

    render () {

        return (

            <>
                <Main page_title="Email Verification" page="email_verification" error_message={this.state.error_message} success_message={this.state.success_message}>

                    <div className="align-center vertical" style={{margin:0}}>
                        
                        <CheckCircleTwoTone twoToneColor="#10f582" style={{fontSize:100, marginBottom:10}} />

                        <h3>Email verified successfully</h3>
                        <p>Your email has been verified. Please click below to login into your account.</p>
                        <Link className="btn solid purple square btn-sm" to="/login">Login</Link>
                    </div>
                </Main>
            </>
        )
    }
}

export default EmailVerification;