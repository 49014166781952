import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import { Card, Avatar, Progress, Typography } from 'antd';

import { StarFilled } from '@ant-design/icons';

import Tags from '../Tags';

class FreelancerCard extends Component { 
    
    cardDescription = (user) => {

        return (

            <div className="article-card-info">

                <div className="article-card-location">
                    {user.city}, {user.country}
                </div>
                <div className="article-card-reviews">
                    
                    <span className="reviews-sum">{user.ratings_count} <StarFilled className="star" /></span>

                    <div className="review-line">
                        <Progress percent={0} showInfo={false} strokeColor="#FF4500" />
                    </div>
                    
                    <div className="reviews-count">
                        {user.reviews_count} Reviews
                    </div>
                </div>
            </div>
        )
    }

    title = (user) => {

        if(this.props.no_link){

            return <div>{user.name}</div>
        }else{

            return <Link to={"users/" + user.slug}>{user.name}</Link>
        }
    }

    render () {

        var user = this.props.freelancer;
        
        if(user){
            
            return (

                <Card
                    className="article-card"
                    cover={<div style={{backgroundImage:'url('+(user.profile_banner_full != '' ? user.profile_banner_full : require('../../assets/images/profile-bg.jpg'))+')'}}></div>}
                >
                    <Card.Meta
                        avatar={<Avatar className={user.profile_pic_full != '' ? '' : 'empty'} size={100} src={user.profile_pic_full != '' ? user.profile_pic_full : require('../../assets/graphics/user.svg')} />}
                        title={this.title(user)}
                        description={this.cardDescription(user)}
                    />
                    <div className="article-card-content">
                        <Typography.Paragraph className="user-about" ellipsis={{rows: 2}}>{user.description}</Typography.Paragraph>
                        <Tags size="small" tags={user.skills_formatted} />
                    </div>
                    <div className="article-card-footer">
                        <div className="price">{user.rate_type_formatted}/{user.price_formatted}</div>

                        {this.props.onRightActionClick
                            ?
                                <button onClick={() => {
                                    this.props.onRightActionClick(user)
                                }} className="btn outlined purple btn-xs" to={"users/" + user.slug}>
                                    {this.props.right_action_label}
                                </button>
                            :
                                <Link className="btn outlined purple btn-xs" to={"users/" + user.slug}>
                                    View Profile
                                </Link>
                        }
                    </div>
                </Card>
            )
        }else{

            return null;
        }
    }
}

export default FreelancerCard;