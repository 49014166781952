import React, { Component } from 'react';

import { FilePdfFilled, FileWordFilled, FileTextFilled } from '@ant-design/icons';

class File extends Component { 

    render () {

        var url = this.props.url;

        if(url && url != ''){

            var _format = this.fileExtension(url);
            _format = _format.toLowerCase();

            return (

                <div className="file-gallery-preview">

                    {this.fileFormat(_format)}
                </div>
            )
        }else{

            return null;
        }
    }

    fileFormat = (format) => {

        if(format == 'pdf'){

            return (
                <div className="icon">
                    <FilePdfFilled />
                </div>
            )
        }else if(format == 'doc' || format == 'docm' || format == 'docx' || format == 'docx' || format == 'odt' || format == 'rtf'){

            return (
                <div className="icon">
                    <FileWordFilled />
                </div>
            )
        }else if(format == 'jpg' || format == 'jpeg' || format == 'png' || format == 'gif'){

            return <img src={this.props.url} className="preview-image" />
        }else{

            return (
                <div className="icon">
                    <FileTextFilled />
                </div>
            )
        }
    }

    fileExtension = (file) => {

        return file.split('.').pop();
    }
}

export default File;