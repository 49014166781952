import React, { Component } from 'react';

// import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import AppHeader from './AppHeader';
import AppFooter from './AppFooter';

import { Layout, Alert } from 'antd';
const { Content } = Layout;

class Main extends Component { 
    constructor(props) {
        super();
        this.state = {

            categories: []
        }
    }

    render () {

        return (

            <Layout>
                
                <AppHeader
                    page_title={this.props.page_title}
                    page={this.props.page}
                    categories={this.state.categories}
                    keyword={this.props.keyword}
                />

                {this.props.error_message && this.props.error_message != ''
                    ?
                        <div className="header-alerts">
                            <Alert description={this.props.error_message} type="error" showIcon />
                        </div>
                    :
                        null
                }
                {this.props.success_message && this.props.success_message != ''
                    ?
                        <div className="header-alerts">
                            <Alert description={this.props.success_message} type="success" showIcon />
                        </div>   
                    :
                        null
                }

                <Content className={(this.props.full_content ? 'main-content-full' : 'main-content') + ' ' + (this.props.className ? this.props.className: '')}>

                    {(this.props.page_title && this.props.page_title != '') && !this.props.hide_page_title &&
                        <div className="main-content-title container">
                            <h1>{this.props.page_title}</h1>

                            {(this.props.page_subtitle && this.props.page_subtitle != '') &&
                                <strong className="page-subtitle">{this.props.page_subtitle}</strong>
                            }
                        </div>
                    }

                    {this.props.children}
                </Content>
                <AppFooter
                    updateCategories={(categories) => {

                        this.setState({categories: categories})
                    }}
                />
            </Layout>
        )
    }
}

export default Main;