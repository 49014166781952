import React, { Component } from 'react';

// import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { List, Button} from 'antd';

// import bannerbgimage from '../assets/images/projects-bg.jpg';
// import pricestop1 from '../assets/images/prices-top-1.png';
// import pricestop2 from '../assets/images/prices-top-2.png';
// import pricestop3 from '../assets/images/prices-top-3.png';

import Api from '../api/Api';
import Main from '../components/Main';

const data1 = [
    {
      title: 'Verified freelancer work history and reviews',
    },
    {
      title: 'Verified freelancer work history and reviews',
    },
    {
      title: 'Built-in collaboration tools and easy payments',
    },
];

const data2 = [
    {
      title: 'Dedicated advisors to help you find and hire quality talent fast',
    },
    {
      title: 'Project tracking and collaboration tools',
    },
    {
      title: 'Advanced filtering options for improved and tailored search results',
    },
];


const data3 = [
    {
      title: 'Consolidated invoicing and ability to pay after 30 days',
    },
    {
      title: 'Advanced services with a dedicated account advisor',
    },
    {
      title: 'Detailed reporting with company insights and trends',
    },
];

const data4 = [
    {
      title: 'Configurable contracting and onboarding process',
    },
    {
      title: 'Worker classification compliance services',
    },
    {
      title: 'Project-based or managed solutions',
    },
];

class PricesPage extends Component { 
    constructor(props) {
        super();
        this.state = {
            main_banner: '',
            main_heading: '',
            main_heading_text: '',

            prices_heading: '',
            prices_content: '',
            prices_data: [],

            packages_heading: '',
            packages_content: '',
            packages_data: []
        }

        this.page = React.createRef();
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        this.setState({account_token: account_token});

        this.loadData();
    }


    renderPrices = () => {

        const pricesList = [];
        for(let i = 0; i < this.state.prices_data.length; i++) {
            let key = 'price_'+i;
            pricesList.push(
                <div className="block" key={key}>
                    <div className="img-block">
                        <img className="img-fluid" src={this.state.prices_data[i].image_url} alt={this.state.prices_data[i].image_alt} />
                    </div>
                    <div className="heading">{this.state.prices_data[i].title}</div>
                    <div className="text" dangerouslySetInnerHTML={{ __html: this.state.prices_data[i].text }} />
                </div>
            );
        }
        return pricesList;
    }


    renderPackages = () => {

        const packagesList = [];
        for(let i = 0; i < this.state.packages_data.length; i++) {
            let key = 'package_'+i;
            packagesList.push(
                // <div className="block" key={key}>
                //     <div className="img-block">
                //         <img className="img-fluid" src={this.state.packages_data[i].image_url} alt={this.state.packages_data[i].image_alt} />
                //     </div>
                //     <div className="heading">{this.state.packages_data[i].title}</div>
                //     <div className="text" dangerouslySetInnerHTML={{ __html: this.state.packages_data[i].text }} />
                // </div>

                <div className={(this.state.packages_data[i].active == '1') ? 'single-package active': 'single-package'} key={key}>
                    <div className="top-block">
                        {this.state.packages_data[i].highlight_text
                            ?
                                <div className="highlight-text">{this.state.packages_data[i].highlight_text}</div>
                            :
                                null
                        }
                        <div className="package-type">{this.state.packages_data[i].type}</div>
                        <div className="package-price">{this.state.packages_data[i].price}</div>
                        {this.state.packages_data[i].per_month
                            ?
                                <div className="pay-per-month">{this.state.packages_data[i].per_month}</div>
                            :
                                null
                        }
                    </div>
                    <div className="bottom-block">
                        <List itemLayout="horizontal" dataSource={this.state.packages_data[i].points}
                            renderItem={item => (
                                <List.Item>
                                    <List.Item.Meta avatar={<i className="mat-ico">done</i>} title={item} />
                                </List.Item>
                            )}
                        />

                        <Button type="primary" className="bt btn-blue" block>{this.state.packages_data[i].button_title}</Button>
                    </div>
                </div>
            );
        }
        return packagesList;
    }

    render () {

        return (

            <Main page_title="PricesPage">
                <div>
                    <div className="banner-block orange-bg-img">
                        <img src={this.state.main_banner} alt="Banner Image" />
                        <div className="container">
                            <div className="heading">{this.state.main_heading}</div>
                            <div className="text" dangerouslySetInnerHTML={{ __html: this.state.main_heading_text }} />
                        </div>
                    </div>
                </div>

                <div className="prices-top-section">
                    <div className="container">
                        <div className="heading">{this.state.prices_heading}</div>
                        <div className="text" dangerouslySetInnerHTML={{ __html: this.state.prices_content }} />

                        <div className="blocks">
                            {this.renderPrices()}
                        </div>
                    </div>
                </div>
                
                <div className="prices-bottom-section">
                    <div className="container">
                        <div className="heading">{this.state.packages_heading}</div>
                        <div className="text" dangerouslySetInnerHTML={{ __html: this.state.packages_content }} />

                        <div className="packages">
                            
                            {this.renderPackages()}

                        </div>
                    </div>
                </div>
                
            </Main>
            
        )
    }

    loadData = () => {

        const formData = new FormData();
        var that = this;

        Api.post('cms/prices', formData, function(data){
            
            that.setState({
                main_banner: data.main_banner,
                main_heading: data.main_heading,
                main_heading_text: data.main_heading_text,

                prices_heading: data.prices_heading,
                prices_content: data.prices_content,
                prices_data: data.prices_data,
                
                packages_heading: data.packages_heading,
                packages_content: data.packages_content,
                packages_data: data.packages_data
            });
        });
    }
}

export default PricesPage;