import React, { Component } from 'react';

import { Link } from "react-router-dom";

import { Carousel  } from 'antd';

class BannerSlider extends Component { 
    constructor(props) {
        super();
        this.state = {
        }
    }

    description = (text) => {

        if(text.length > 100){
        
            return `${text.substring(0, 100)}...`;
        }
        
        return text;
    }

    renderBanners = () => {

        const bannersList = [];
        for(let i = 0; i < this.props.banners.length; i++) {
            let key = 'mp_'+i;

            bannersList.push(

                <div className="banners-block" key={key}>
                    <div className="bg-block">
                        <img className="banner-image" src={this.props.banners[i].image} alt={this.props.banners[i].heading} />
                    </div>
                    <div className="container">
                        <div className="block">
                            <div className="head-text">{this.props.banners[i].heading}</div>
                            <div className="description">{this.description(this.props.banners[i].short_description)}</div>
                            <div className="links">
                                {this.props.banners[i].link_1 && this.props.banners[i].link_1_title
                                    ?
                                        <Link to={this.props.banners[i].link_1} className="btn btn-brown">{this.props.banners[i].link_1_title}</Link>
                                    :
                                        null
                                }

                                {this.props.banners[i].link_2 && this.props.banners[i].link_2_title
                                    ?
                                        <Link to={this.props.banners[i].link_2} className="btn btn-white">{this.props.banners[i].link_2_title}</Link>
                                    :
                                        null
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return bannersList;
    }

    render () {

        return (
            <div className="banner-slider">
                <Carousel autoplay>
                    {this.props.banners && this.props.banners.length > 0
                        ?
                            this.renderBanners()
                        :
                            null
                    }
                    
                </Carousel>
            </div>
        )
    }
}

export default BannerSlider;