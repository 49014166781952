import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

// add form components
import { Table, Tabs, Empty, Skeleton, Typography, Button, Menu, Popconfirm } from 'antd';

import FeatherIcon from 'feather-icons-react';
import Main from '../../components/Main';
import File from '../../components/File';

import ProfilePic from '../../components/ProfilePic';

import LayoutHelper from '../../helpers/LayoutHelper';

import Footer from './Footer';
import ProposalView from './ProposalView';

import ProjectFlowSidebar from './ProjectFlowSidebar';

import Api from '../../api/Api';

class Projects extends Component { 
    constructor(props) {
        super();
        this.state = {

            loading: false,

            error_message: '',
            success_message: '',

            account_token: false,
            user: false,

            buyer: false,
            seller: false,

            go_back: false,

            has_data: false,

            project: false,
            proposals: [],

            attachments: [],
            attachments_type: 'list',

            action_menu_visible: false,

            cancel_proposal_loading: false,

            can_resend: true,

            last_row_id: false
        }

        this.timeline = React.createRef();
        this.last_message = React.createRef();
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');

        var _user = JSON.parse(user);
        this.setState({user: _user, account_token: account_token});

        var project_id = this.props.project_id;

        if(project_id){
         
            this.loadProject(account_token, project_id);
            this.repeatLoadMessages();
        }else{

            this.setState({go_back: true});
        }
    }
    
    render () {

        if(this.state.go_back){

            return <Redirect to="/" />
        }

        return (

            <>
                <Main page_title="Manage Projects" page="as_seller" error_message={this.state.error_message} success_message={this.state.success_message} hide_page_title={true}>
                    
                    <div className="container-lg page-main-content project-details project-details-page d-flex justify-content-between">

                        {this.state.loading
                            ?
                                <Skeleton paragraph={{ rows: 12, size: 'large' }} active size="large" />
                            :
                                this.state.project
                                    ?
                                        <>
                                            <div className="left-block">
                                                <div className="name-block">
                                                    <div className="name">{this.state.project.project_view == 1 ? this.state.buyer.name : this.state.seller.name}</div>
                                                    <div className="job">{this.state.project.title}</div>
                                                </div>
                                                <div className="timeline-block" ref={this.timeline}>
                                                    
                                                    <ProposalView
                                                        area="main"
                                                        buyer={this.state.buyer}
                                                        seller={this.state.seller}
                                                        can_resend={this.state.can_resend}
                                                        updateCanResend={(status) => {
                                                            this.setState({can_resend: status})
                                                        }}

                                                        updateProject={(project) => {
                                                            this.setState({project: project});
                                                        }}

                                                        user={this.state.user}
                                                        
                                                        project={this.state.project}
                                                        proposals={this.state.proposals}
                                                        
                                                        updateProposals={(proposals) => {

                                                            this.setState({proposals: proposals});
                                                            this.scrollToBottom();
                                                            this.updateLastRowId(proposals);
                                                        }}
                                                        appendRow={(row) => {

                                                            var proposals = this.state.proposals;
                                                            proposals.push(row);
                                                            this.setState({proposals: proposals});
                                                            this.scrollToBottom();
                                                            this.updateLastRowId(proposals);
                                                            window.setTimeout(() => {
                                                                this.updateAttachments()
                                                            }, 200)
                                                        }}
                                                        onError={(message) => {
                                                            LayoutHelper.addErrorMessage(this, message)
                                                        }}
                                                        onSuccess={(message) => {
                                                            LayoutHelper.addSuccessMessage(this, message)
                                                        }}
                                                        account_token={this.state.account_token}
                                                    />
                                                    <div ref={this.last_message}></div>
                                                </div>

                                                <Footer
                                                    project={this.state.project}
                                                    proposals={this.state.proposals}
                                                    onError={(message) => {
                                                        LayoutHelper.addErrorMessage(this, message)
                                                    }}
                                                    
                                                    updateProject={(project) => {
                                                        this.setState({project: project});
                                                    }}

                                                    onSuccess={(message) => {
                                                        LayoutHelper.addSuccessMessage(this, message)
                                                    }}
                                                    account_token={this.state.account_token}
                                                    updateProposals={(row) => {

                                                        var proposals = this.state.proposals;
                                                        proposals.push(row);
                                                        this.setState({proposals: proposals});
                                                        this.scrollToBottom();
                                                        this.updateLastRowId(proposals);
                                                    }}
                                                    seller_id={this.props.seller_id}
                                                />
                                            </div>

                                            <div className="right-block">

                                                <ProjectFlowSidebar
                                                    buyer={this.state.buyer}
                                                    seller={this.state.seller}
                                                    project={this.state.project}
                                                />

                                                <Tabs animated={false} defaultActiveKey="workstream">

                                                    <Tabs.TabPane tab={<a><span>Work Streamfeed</span></a>} key="workstream">
                                                        
                                                        <div class="sidebar-tabs-block">

                                                            <ProposalView
                                                                area="sidebar"
                                                                buyer={this.state.buyer}
                                                                seller={this.state.seller}
                                                                can_resend={this.state.can_resend}
                                                                updateCanResend={(status) => {
                                                                    this.setState({can_resend: status})
                                                                }}

                                                                updateProject={(project) => {
                                                                    this.setState({project: project});
                                                                }}

                                                                user={this.state.user}
                                                                
                                                                project={this.state.project}
                                                                proposals={this.state.proposals}
                                                                
                                                                updateProposals={(proposals) => {

                                                                    this.setState({proposals: proposals});
                                                                    this.scrollToBottom();
                                                                    this.updateLastRowId(proposals);
                                                                }}
                                                                appendRow={(row) => {

                                                                    var proposals = this.state.proposals;
                                                                    proposals.push(row);
                                                                    this.setState({proposals: proposals});
                                                                    this.scrollToBottom();
                                                                    this.updateLastRowId(proposals);
                                                                }}
                                                                onError={(message) => {
                                                                    LayoutHelper.addErrorMessage(this, message)
                                                                }}
                                                                onSuccess={(message) => {
                                                                    LayoutHelper.addSuccessMessage(this, message)
                                                                }}
                                                                account_token={this.state.account_token}
                                                            />
                                                        </div>
                                                    </Tabs.TabPane>
                                                    <Tabs.TabPane tab={<a><span>Attachements</span></a>} key="attachements">
                                                        
                                                        <div className="proposal-attachments">
                                                            <div className="proposal-attachments-header">
                                                                <span>&nbsp;</span>
                                                                <div className="proposal-attachments-header-tools">
                                                                    <span onClick={() => {
                                                                        this.setState({attachments_type: 'list'})
                                                                    }}>
                                                                        <FeatherIcon icon="list" style={{color:this.state.attachments_type == 'list' ? '#7303c1' : '#666'}} />
                                                                    </span>
                                                                    <span onClick={() => {
                                                                        this.setState({attachments_type: 'grid'})
                                                                    }}>
                                                                        <FeatherIcon icon="grid" style={{color:this.state.attachments_type == 'grid' ? '#7303c1' : '#666'}} />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="proposal-attachments-list">

                                                                {this.state.attachments && this.state.attachments.length > 0
                                                                    ?

                                                                        <ul className={this.state.attachments_type}>

                                                                            {this.state.attachments.map((_file) => {

                                                                                return (
                                                                                    <li>
                                                                                        <File url={_file} />

                                                                                        {this.state.attachments_type == 'list' &&
                                                                                        
                                                                                            <div className="proposal-attachments-extra">

                                                                                                <a target="_blank" href={_file}>
                                                                                                    <p>{_file.split('/').pop()}</p>
                                                                                                    <FeatherIcon icon="download" />
                                                                                                </a>
                                                                                            </div>
                                                                                        }
                                                                                    </li>
                                                                                )
                                                                            })}
                                                                        </ul>
                                                                    :
                                                                        null
                                                                }
                                                            </div>
                                                        </div>
                                                    </Tabs.TabPane>
                                                </Tabs>
                                            </div>
                                        </>
                                    :
                                        null
                        }
                    </div>
                </Main>
            </>
        )
    }

    updateLastRowId = (proposals) => {

        var last_row_id = this.state.last_row_id;

        if(proposals.length > 0){

            for(var i in proposals){

                var proposal = proposals[i];
                last_row_id = proposal.inc_id;
            }
        }

        this.setState({last_row_id: last_row_id});
    }

    scrollToBottom = () => {

        this.timeline.current.scrollTo(0, this.last_message.current.offsetTop);
    }

    updateAttachments = () => {

        var attachments = [];
        var proposals = this.state.proposals;

        proposals.forEach(_proposal => {

            if(_proposal.hasOwnProperty('attachments') && _proposal.attachments.length > 0){
            
                var _attachments = _proposal.attachments;

                _attachments.forEach(_file => {

                    attachments.push(_file);
                })
            }
        });

        this.setState({attachments: attachments});
    }

    loadProject = (account_token, project_id) => {

        var formData = new FormData();
        
        formData.append('account_token', account_token);
        formData.append('project', project_id);

        var seller_id = this.props.seller_id;
        if(seller_id){

            formData.append('seller', seller_id);
        }
        
        var that = this;

        this.setState({loading: true});
        Api.post('proposals/load', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){

                if(data.list_ref){

                    that.setState({can_resend: data.list_ref});
                }else{

                    that.setState({can_resend: false});
                }
            
                if(data.project){

                    var last_row_id = false
                    if(data.proposals.length > 0){

                        for(var i in data.proposals){

                            var proposal = data.proposals[i];
                            last_row_id = proposal.inc_id;
                        }
                    }

                    that.setState({
                        last_row_id: last_row_id,
                        project: data.project,
                        proposals: data.proposals,
                        no_data: false,
                    });

                    if(data.buyer){
                        that.setState({buyer: data.buyer});
                    }

                    if(data.seller){
                        that.setState({seller: data.seller});
                    }

                    that.updateAttachments();
                    that.scrollToBottom();
                }else{

                    that.setState({no_data: true, project: false, can_resend: false});
                }
            }else{

                // if(data.code == 'no_account'){
                //     window.location = Api.url + '/logout';
                // }
                // that.props.errorMessage(data.message);
            }
        });
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    repeatLoadMessages = () => {

        var that = this;
        this.interval = setInterval(() => {

            if(!that.state.loading){

                that.loadMessages();
            }
        }, 5000);
    }

    loadMessages = () => {

        var formData = new FormData();
        
        formData.append('account_token', this.state.account_token);
        formData.append('ref_id', this.state.last_row_id);
        formData.append('project', this.state.project.project_unq_id);

        var seller_id = this.props.seller_id;
        if(seller_id){

            formData.append('seller', seller_id);
        }
        
        var that = this;

        Api.post('proposals/load', formData, function(data){
            
            if(data.status == true){

                if(data.list_ref){

                    that.setState({can_resend: data.list_ref});
                }else{

                    that.setState({can_resend: false});
                }

                if(data.project){

                    if(data.project.status != that.state.project.status){
                        that.setState({project: data.project});
                    }
                    if(data.project.nda_requested != that.state.project.nda_requested){
                        that.setState({project: data.project});
                    }
                    if(data.project.nda_accepted != that.state.project.nda_accepted){
                        that.setState({project: data.project});
                    }
                    if(data.project.invoice_raised != that.state.project.invoice_raised){
                        that.setState({project: data.project});
                    }
                    if(data.project.buyer_feedback != that.state.project.buyer_feedback){
                        that.setState({project: data.project});
                    }
                    if(data.project.seller_feedback != that.state.project.seller_feedback){
                        that.setState({project: data.project});
                    }
                }

                var proposals = that.state.proposals;

                if(data.proposal_refs && Object.keys(data.proposal_refs).length > 0){

                    for(var i in proposals){

                        if(data.proposal_refs.hasOwnProperty(proposals[i]['inc_id'])){

                            proposals[i]['status'] = data.proposal_refs[proposals[i]['inc_id']];
                        }
                    }
                }
            
                if(data.entries && data.entries.length > 0){

                    var last_row_id = that.state.last_row_id;
                    var has_new_entry = false;

                    if(data.entries.length > 0){

                        has_new_entry = true;
                        for(var i in data.entries){

                            var entry = data.entries[i];
                            last_row_id = entry.inc_id;
                        }
                    }
                    
                    proposals.push(...data.entries);

                    that.setState({
                        last_row_id: last_row_id,
                        no_data: false,
                    });

                    that.updateAttachments();

                    if(has_new_entry){

                        that.scrollToBottom();
                    }
                }

                that.setState({proposals: proposals});
            }else{

            }
        });
    }
}

export default Projects;