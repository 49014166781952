import React, { Component } from 'react';

// add form components
import { Dropdown, Button, Menu, Modal, Tabs, Form, Input, InputNumber, Checkbox, Radio, DatePicker, Rate, Row, Col } from 'antd';

import FeatherIcon from 'feather-icons-react';

import { MinusCircleOutlined, CheckOutlined, DownOutlined, ExclamationCircleOutlined, StarFilled } from '@ant-design/icons';

import Api from '../../api/Api';

class Footer extends Component { 
    constructor(props) {
        super();
        this.state = {

            loading: false,

            button_disabled: false,

            message: '',

            invoice_items: [{
                description: '',
                qty: 1,
                fixed_cost: 0,
                time_period: '',
                amount: 0
            }],

            invoice_tax: 0,
            invoice_total: 0,

            nda_button: false,
            nda_terms: true,

            rating_popup: false
        }
    }

    tabExtraActions = () => {

        var that = this;

        if(this.props.project.status == 3 && this.props.project.project_view == 0){

            if(this.props.project.buyer_feedback == 0){

                return (
                    <Button className="transparent" onClick={() => {
                        this.setState({rating_popup: true})
                    }}>
                        <StarFilled /> Share Feedback
                    </Button>
                )
            }else{

                return (
                    <>
                        <StarFilled /> <strong>Feedback submitted</strong>
                    </>
                )
            }
        }

        if(this.props.project.invoice_raised == 1 && this.props.project.project_view == 1 && this.props.project.status == 1){

            const menu = (
                <Menu>
                    <Menu.Item key="1" icon={<CheckOutlined />} onClick={() => {

                        Modal.confirm({
                            title: 'Do you want to deliver this project?',
                            icon: <ExclamationCircleOutlined />,
                            content: '',
                            onOk(){
                                that.updateProjectStatus('delivered')
                            },
                            onCancel() {
                                
                            },
                        });
                    }}>
                        Mark as Delivered
                    </Menu.Item>
                </Menu>
            );

            return (

                <div>
                    <Dropdown className="transparent" overlay={menu}>
                        <Button>
                            ACTION <DownOutlined />
                        </Button>
                    </Dropdown>
                </div>
            )
        }

        if(this.props.project.status == 3 && this.props.project.project_view == 1){

            if(this.props.project.seller_feedback == 0){

                return (
                    <Button className="transparent" onClick={() => {
                        this.setState({rating_popup: true})
                    }}>
                        <StarFilled /> Share Feedback
                    </Button>
                )
            }else{

                return (
                    <>
                        <StarFilled /> <strong>Feedback submitted</strong>
                    </>
                )
            }
        }
    }

    updateProjectStatus = (status) => {
        var formData = new FormData();

        formData.append('account_token', this.props.account_token);
        formData.append('project', this.props.project.project_unq_id);

        var that = this;

        this.setState({loading: true});

        if(status == 'delivered'){
        
            var url = 'projects/delivered';
        }else{
        
            var url = 'proposals/request_nda';
        }

        Api.post(url, formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){
            
                that.props.updateProposals(data.row);
                that.props.onSuccess(data.message);
                that.props.updateProject(data.project);
            
            }else{
                that.props.onError(data.message);
            }
        });
    }
    
    render () {

        const layout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
        };

        return (

            <>
                <div className="send-message-block">
                    <Tabs defaultActiveKey="1" animated={false} tabBarExtraContent={this.tabExtraActions()}>
                        <Tabs.TabPane tab={
                            <a>
                                <FeatherIcon icon="mail" /><span>Send Message</span>
                            </a>} key="1">

                            <Form name="send_message_form" id="send_message_form" ref="send_message_form" onFinish={this.sendMessage.bind(this)} initialValues={{ tnc: true }}>
                                <div className="d-flex form-item-block">
                                    <Form.Item className="w-100" name="message" rules={[{required:true}]}>
                                        <Input.TextArea onChange={(e) => {
                                            this.setState({message: e.target.value})
                                        }} />
                                    </Form.Item>
                                </div>

                                <div className="d-flex form-item-block">
                                    <Form.Item name="tnc" valuePropName="checked">
                                        <Checkbox onChange={(e) => {
                                            this.setState({button_disabled: !e.target.checked})
                                        }}>You are agree with our terms & conditions</Checkbox>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button disabled={this.state.button_disabled} className="btn btn-sm btn-pink-bg" htmlType="submit">Send</Button>
                                    </Form.Item>
                                </div>
                            </Form>
                        </Tabs.TabPane>
                        
                        {this.props.project.project_view == 1 && (this.props.project.status != 1 && this.props.project.status != 5 && this.props.project.status != 6 && this.props.project.status != 7) &&
                            <Tabs.TabPane tab={
                                <a>
                                    <FeatherIcon icon="file-text" /><span>Raise Invoice</span>
                                </a>} key="2">
                                
                                <div className="invoice-form-block">
                                    <div className="block-heading">
                                        <div className="">New Invoice</div>
                                    </div>
                                    <Form name="invoice_form" id="invoice_form" ref="invoice_form" onFinish={this.generateInvoice.bind(this)}>
                                        <div className="d-flex form-item-block">
                                            <Form.Item className="w-100" name="unite" label="Unite" rules={[{required:true}]}>
                                                <Radio.Group>
                                                    <Radio value="Fixed">Fixed</Radio>
                                                    <Radio value="Hourly">Hourly</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </div>

                                        <div className="d-flex form-item-block">
                                            <label className="w-30">Description</label>
                                            <label className="w-15">Qty</label>
                                            <label className="w-15">Cost</label>
                                            <label className="w-20">Time Period</label>
                                            <label className="w-20" style={{textAlign:'right'}}>Amount</label>
                                        </div>

                                        {this.invoiceItemRow()}

                                        <div className="d-flex form-item-block">
                                            <button onClick={() => {
                                                this.addInvoiceRow()
                                            }} type="button" className="btn btn-xs blue-bg add-invoice-block" id="add_milestone_btn">+</button>

                                            <div className="d-flex justify-content-between w-40">
                                                <label className="w-50">Includes Tax</label>
                                                
                                                <Form.Item className="w-50" name="tax">
                                                    <InputNumber onChange={(tax) => {
                                                        this.setState({invoice_tax: tax});
                                                        window.setTimeout(() => {
                                                            this.calculateTotal()
                                                        }, 200)
                                                    }} />
                                                </Form.Item>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-end form-item-block">
                                            <div className="d-flex justify-content-between w-40">
                                                <label className="w-50">Total</label>
                                                
                                                <Form.Item className="w-50" name="total">
                                                    <p style={{textAlign:'right'}}>{this.state.invoice_total}</p>
                                                </Form.Item>
                                            </div>
                                        </div>

                                        <div className="d-flex form-item-block">
                                            <Form.Item name="" valuePropName="checked">
                                                <Checkbox>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sit amet massa interdum.</Checkbox>
                                            </Form.Item>

                                            <Form.Item>
                                                <Button className="btn btn-sm btn-pink-bg" type="submit" htmlType="submit">Send</Button>
                                            </Form.Item>
                                        </div>
                                    </Form>
                                </div>
                            </Tabs.TabPane>
                        }
                        {/* <Tabs.TabPane tab={
                            <a>
                                <FeatherIcon icon="flag" /><span>Milestone</span>
                            </a>} key="3">
                            Content of Tab Pane 3
                        </Tabs.TabPane> */}

                        {this.props.project.project_view == 1
                            ?

                                this.props.project.nda_requested == 1
                                    ?
                                        <Tabs.TabPane tab={
                                            <a>
                                                <FeatherIcon icon="edit-2" /><span>NDA</span>
                                            </a>} key="4">
                                            <div class="block-content">
                                                <div className="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sit amet massa interdum, vehicula ipsum nec, mattis erat. Nunc sed velit volutpat, placerat sem ac, aliquam magna. Phasellus tincidunt nisi ante, a porta nisl ultricies sed. Sed rhoncus, arcu ut semper finibus, urna eros lobortis odio, a varius ante enim rutrum tellus. Nam elementum, ante in semper laoreet, nibh arcu rhoncus metus, a accumsan ligula metus id ex.</div>
                                            </div>

                                            <div className="d-flex form-item-block">
                                            
                                                {this.props.project.nda_requested == 1
                                                    ?

                                                        this.props.project.nda_accepted == 1
                                                            ?
                                                                <>
                                                                    <span></span>
                                                                    <strong>NDA Accepted</strong>
                                                                </>
                                                            :
                                                                <>
                                                                    <Checkbox checked={this.state.nda_terms} onChange={(e) => {
                                                                        this.setState({nda_button: !e.target.checked, nda_terms: !this.state.nda_terms})
                                                                    }}>You are agree with our terms & conditions</Checkbox>
                                                                    <Button onClick={() => {
                                                                        this.requestNda('accept')
                                                                    }} disabled={this.state.nda_button} className="btn btn-sm btn-pink-bg" htmlType="submit">Accept</Button>
                                                                </>
                                                        
                                                    :
                                                        null
                                                }
                                            </div>

                                        </Tabs.TabPane>
                                    :
                                        null
                            :
                                <Tabs.TabPane tab={
                                    <a>
                                        <FeatherIcon icon="edit-2" /><span>NDA</span>
                                    </a>} key="4">
        
                                    {this.props.project.project_view == 0 &&
                                    
                                        <>
                                            <div class="block-content">
                                                <div className="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sit amet massa interdum, vehicula ipsum nec, mattis erat. Nunc sed velit volutpat, placerat sem ac, aliquam magna. Phasellus tincidunt nisi ante, a porta nisl ultricies sed. Sed rhoncus, arcu ut semper finibus, urna eros lobortis odio, a varius ante enim rutrum tellus. Nam elementum, ante in semper laoreet, nibh arcu rhoncus metus, a accumsan ligula metus id ex.</div>
                                            </div>

                                            <div className="d-flex form-item-block">
                                                
                                                {this.props.project.nda_requested == 0
                                                    ?
                                                        <>
                                                            <Checkbox checked={this.state.nda_terms} onChange={(e) => {
                                                                this.setState({nda_button: !e.target.checked, nda_terms: !this.state.nda_terms})
                                                            }}>You are agree with our terms & conditions</Checkbox>
                                                            <Button onClick={() => {
                                                                this.requestNda('request')
                                                            }} disabled={this.state.nda_button} className="btn btn-sm btn-pink-bg" htmlType="submit">Send</Button>
                                                        </>
                                                    :
                                                        this.props.project.nda_accepted == 1
                                                            ?
                                                                <>
                                                                    <span></span>
                                                                    <strong>NDA Accepted</strong>
                                                                </>
                                                            :
                                                                <>
                                                                    <span></span>
                                                                    <strong>NDA Request Sent</strong>
                                                                </>
                                                }

                                            </div>
                                        </>
                                    }
                                </Tabs.TabPane>
                        }
                        
                    </Tabs>

                    <Modal
                        title={null}
                        visible={this.state.rating_popup}
                        // onOk={this.handleOk}
                        closable={true}
                        onCancel={() => {
                            this.setState({rating_popup: false})
                        }}
                        width={700}
                        footer={null}
                    >
                        <div className="new-proposal-form">

                            <Form {...layout} scrollToFirstError={true} name="rating_form" initialValues={{ remember: true }} onFinish={this.submitRating.bind(this)}>

                                <div>
                                    <div className="form-wrap">
                                        <Form.Item name="rating" label="Rate the freelancer" rules={[{required: true}]}>
                                            <Rate tooltips={['terrible', 'bad', 'normal', 'good', 'wonderful']} allowClear={true} />
                                        </Form.Item>
                                    </div>

                                    <div className="form-wrap">
                                        <Form.Item name="review" label="Write about your experience" rules={[{required: true}]}>
                                            <Input.TextArea />
                                        </Form.Item>
                                    </div>
                                    
                                    <div className="terms">
                                        <Row justify="space-between" align="bottom">
                                            <Col span={24}>
                                                <Button loading={this.state.loading} htmlType="submit" className="ant-btn btn btn-sm btn-pink-outline ant-btn-submit"><span>Send</span></Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </Modal>
                </div>
            </>
        )
    }

    submitRating = (values) => {

        var formData = new FormData();
        Object.keys(values).forEach((key) => {

            if(values[key]){
                formData.append(key, values[key]);
            }
        });

        formData.append('account_token', this.props.account_token);
        formData.append('project', this.props.project.project_unq_id);
        
        var that = this;

        this.setState({loading: true});

        Api.post('proposals/feedback', formData, function(data){
            
            that.setState({loading: false, rating_popup: false});

            if(data.status == true){

                that.props.onSuccess(data.message);
                that.props.updateProposals(data.row);
                that.props.updateProject(data.project);

            }else{
                
                that.props.onError(data.message);
            }
        });
    }

    requestNda = (type) => {
        var formData = new FormData();

        formData.append('account_token', this.props.account_token);
        formData.append('project', this.props.project.project_unq_id);

        var that = this;

        this.setState({loading: true});

        if(type == 'accept'){
        
            var url = 'proposals/accept_nda';
        }else{
        
            var url = 'proposals/request_nda';
        }

        Api.post(url, formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){
            
                that.props.updateProposals(data.row);
                that.props.onSuccess(data.message);
                that.props.updateProject(data.project);
            
            }else{
                that.props.onError(data.message);
            }
        });
    }

    generateInvoice = (values) => {
        var formData = new FormData();

        formData.append('account_token', this.props.account_token);
        formData.append('project', this.props.project.project_unq_id);

        Object.keys(values).forEach((key) => {

            if(values[key]){
                formData.append(key, values[key])
            }
        });

        var that = this;

        this.setState({loading: true});
        Api.post('invoices/generate', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){
            
                that.props.updateProposals(data.row);
                that.props.onSuccess(data.message);

                that.setState({invoice_items: [{
                    description: '',
                    qty: 1,
                    fixed_cost: 0,
                    time_period: '',
                    amount: 0
                }]});
            
            }else{
                that.props.onError(data.message);
            }
        });
    }

    addInvoiceRow = () => {

        var invoice_items = this.state.invoice_items;
        invoice_items.push({
            description: '',
            qty: 1,
            fixed_cost: 0,
            time_period: '',
            amount: 0
        })
        this.setState({invoice_items: invoice_items});
    }

    calculateTotal = () => {

        var invoice_items = this.state.invoice_items;
        var invoice_tax = this.state.invoice_tax;

        var total = 0;

        for(var i in invoice_items){

            var _qty = invoice_items[i]['qty'];
            var _fixed_cost = invoice_items[i]['fixed_cost'];

            total = parseInt(total) + ( parseInt(_qty) * parseInt(_fixed_cost) );
        }

        if(parseInt(invoice_tax) > 0){

            total = total + parseInt(invoice_tax);
        }

        var data = {invoice_total: total};

        this.setState(data);
        // this.refs.invoice_form.setFieldsValue(data);
    }

    invoiceItemRow = () => {

        var invoice_items = this.state.invoice_items;
        if(invoice_items.length > 0){

            var _invoice_items = [];

            for(var n = 0; n < invoice_items.length; n++){

                var i = n;
                var key = 'invoice_item_' + i;

                _invoice_items.push(
                    <div key={key} className="d-flex form-item-block">
                        <Form.Item className="w-30" name={"invoice_description["+n+"]"} rules={[{required:true, message: 'Required'}]}>
                            <Input value={invoice_items[i]['description']} onChange={(e) => {
                                invoice_items[i]['description'] = e.target.value;
                                this.setState({invoice_items: invoice_items});
                                window.setTimeout(() => {
                                    this.calculateTotal();
                                }, 200);
                            }} />
                        </Form.Item>
                        <Form.Item className="w-15" name={"invoice_qty["+n+"]"} rules={[{required:true, message: 'Required'}]}>
                            <InputNumber value={invoice_items[i]['qty']} onChange={(qty) => {

                                invoice_items[i]['qty'] = qty;
                                var fixed_cost = invoice_items[i]['fixed_cost'];

                                var amount = 0;
                                if(fixed_cost > 0){

                                    amount = parseInt(fixed_cost) * qty;
                                }
                                invoice_items[i]['amount'] = amount;
                                this.setState({invoice_items: invoice_items});
                                window.setTimeout(() => {
                                    this.calculateTotal();
                                }, 200);
                            }} />
                        </Form.Item>
                        <Form.Item className="w-15" name={"invoice_fixed_cost["+n+"]"} rules={[{required:true, message: 'Required'}]}>
                            <InputNumber value={invoice_items[i]['fixed_cost']} onChange={(fixed_cost) => {
                                invoice_items[i]['fixed_cost'] = fixed_cost;

                                var qty = invoice_items[i]['qty'];

                                var amount = 0;
                                if(fixed_cost > 0){

                                    amount = parseInt(fixed_cost) * qty;
                                }
                                invoice_items[i]['amount'] = amount;

                                this.setState({invoice_items: invoice_items});
                                window.setTimeout(() => {
                                    this.calculateTotal();
                                }, 200);
                            }} />
                        </Form.Item>
                        <Form.Item className="w-20" name={"invoice_date["+n+"]"} rules={[{required:true, message: 'Required'}]}>
                            <DatePicker format={this.state.dateFormat} />
                        </Form.Item>

                        <Form.Item className="w-20" label="" name={"invoice_amount["+n+"]"}>
                            <p style={{textAlign:'right'}}>{invoice_items[i]['amount']}</p>
                        </Form.Item>

                        {i > 0 &&
                            <MinusCircleOutlined
                                className="remove-row"
                                style={{ margin: '0 8px' }}
                                onClick={() => {
                                    this.removeRow(i);
                                }}
                            />
                        }
                    </div>
                );
            }
        }

        return _invoice_items;
    }

    removeRow = (i) => {

        var invoice_items = this.state.invoice_items;

        var _invoice_items = [];

        for(var n in invoice_items){

            if(n != i){

                _invoice_items.push(invoice_items[n])
            }
        }
        this.setState({invoice_items: _invoice_items});
    }
    
    sendMessage = (values) => {
        var formData = new FormData();
        formData.append('account_token', this.props.account_token);
        formData.append('project', this.props.project.project_unq_id);

        if(this.props.seller_id){

            formData.append('seller', this.props.seller_id);
        }

        Object.keys(values).forEach((key) => {

            if(values[key]){
                formData.append(key, values[key])
            }
        });
        
        var that = this;

        this.setState({loading: true});
        Api.post('messages/send', formData, function(data){
            
            that.setState({loading: false, message: ''});
            that.refs.send_message_form.setFieldsValue({message: ''});
            
            if(data.status == true){
            
                that.props.updateProposals(data.row);
                that.props.onSuccess(data.message);
            
            }else{
                that.props.onError(data.message);
            }
        });
    }
}

export default Footer;