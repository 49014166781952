import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import { Layout, Button } from 'antd';
// const { Footer } = Layout;

import Api from '../api/Api';

// import FeatherIcon from 'feather-icons-react';

class AppFooter extends Component { 
    constructor(props) {
        super();
        this.state = {
            first_section_heading: '',
            first_sec_categories: [],

            second_section_heading: '',
            second_sec_categories: [],

            third_section_heading: '',
            third_sec_categories: [],

            fourth_section_heading: '',
            fourth_sec_categories: [],

            social_links: []
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        this.setState({account_token: account_token});

        this.loadData();
    }

    renderCategoryLinks = (list, slug) => {

        const linksList = [];
        for(let i = 0; i < list.length; i++) {
            let key = 'c_'+i;
            let link = slug+list[i].link;

            linksList.push(
                <li key={key}><Link to={link}>{list[i].text}</Link></li>
            );
        }
        return linksList;
    }
    
    loadData = () => {

        const formData = new FormData();
        var that = this;

        Api.post('common/footer', formData, function(data){
            
            that.props.updateCategories(data.all_categories);

            that.setState({
                first_section_heading: data.first_section_heading,
                first_sec_categories: data.first_sec_categories,

                second_section_heading: data.second_section_heading,
                second_sec_categories: data.second_sec_categories,

                third_section_heading: data.third_section_heading,
                third_sec_categories: data.third_sec_categories,

                fourth_section_heading: data.fourth_section_heading,
                fourth_sec_categories: data.fourth_sec_categories,

                social_links: data.social_links
            });
        });
    }

    render () {

        return (

            <Layout.Footer>
                <div className="top-block">
                    <div className="container">
                        <div className="footer-block">
                            <div className="block">
                                <div className="heading">{this.state.first_section_heading}</div>
                                <ul className="list-inline">
                                    {this.renderCategoryLinks(this.state.first_sec_categories, '/category/')}
                                </ul>
                            </div>
                            <div className="block">
                                <div className="heading">{this.state.second_section_heading}</div>
                                <ul className="list-inline">
                                    {this.renderCategoryLinks(this.state.second_sec_categories, '')}
                                </ul>
                            </div>
                            <div className="block">
                                <div className="heading">{this.state.third_section_heading}</div>
                                <ul className="list-inline">
                                    {this.renderCategoryLinks(this.state.third_sec_categories, '')}
                                </ul>
                            </div>
                            <div className="block">
                                <div className="heading">{this.state.fourth_section_heading}</div>
                                <ul className="list-inline">
                                    {this.renderCategoryLinks(this.state.fourth_sec_categories, '')}
                                </ul>
                            </div>
                        </div>

                        <div className="copyright">
                            <div className="block d-flex">
                                <div className="left">
                                    <ul className="list-inline d-flex">
                                        {this.state.social_links.fb
                                            ?
                                                <li><a href={this.state.social_links.fb} target="_blank"><i className="ico facebook"></i></a></li>
                                            :
                                                null
                                        }

                                        {this.state.social_links.twitter
                                            ?
                                                <li><a href={this.state.social_links.twitter} target="_blank"><i className="ico twitter"></i></a></li>
                                            :
                                                null
                                        }

                                        {this.state.social_links.linkedin
                                            ?
                                                <li><a href={this.state.social_links.linkedin} target="_blank"><i className="ico linkedin"></i></a></li>
                                            :
                                                null
                                        }

                                        {this.state.social_links.youtube
                                            ?
                                                <li><a href={this.state.social_links.youtube} target="_blank"><i className="ico youtube"></i></a></li>
                                            :
                                                null
                                        }
                                        
                                    </ul>
                                </div>
                                <div className="right">
                                    <div className="text">&copy; Cogniticx. ALL RIGHTS RESERVED. 2020</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </Layout.Footer>
        )
    }
}

export default AppFooter;