import React, { Component } from 'react';

import FeatherIcon from 'feather-icons-react';

import Api from '../../../api/Api';
import bachelorscapimg from '../../../assets/images/education-bg.png';

import moment from 'moment';

import Loader from '../../../components/Loader';

import { Form, Select, Input, InputNumber, Button, Carousel, Popconfirm } from 'antd';

class Education extends Component { 
    constructor(props) {
        super();
        this.state = {
            loading: false,
            list: [],

            remove_loading: false,
            item: false
        }
    }

    componentDidMount = () => {

        window.setTimeout(() => {
            this.loadEducationList(this.props.account_token);
        });
    }

    render () {

        return (

            <>

                {this.props.account_token &&

                    <div style={{position:'relative'}}>
                        <div className="custom-carousel custom-employment-carousel">
                            <Carousel arrows={true}>
                                {this.renderList()}
                            </Carousel>

                        </div>
                        {this.state.remove_loading &&
                            <Loader />
                        }
                    </div>
                }
                {this.props.display
                    ?
                        this.educationForm()
                    :
                        null
                }
            </>
        )
    }

    onEducationSubmit = (values) => {
        var formData = new FormData();
        formData.append('account_token', this.props.account_token);

        if(this.state.item != false){

            formData.append('item_id', this.state.item.id);
        }

        Object.keys(values).forEach((key) => {

            if(values[key]){
                formData.append(key, values[key])
            }
        });
        
        var that = this;

        this.setState({loading: true});
        Api.post('freelancer/profile_update_education', formData, function(data){
            
            that.setState({loading: false, item: false});
            
            if(data.status == true){
            
                that.setState({list: data.list});
                that.props.hide_form();
                that.props.updateUser(data.user);
            
            }else{
                that.props.onError(data.message);
            }
        });
    }

    educationForm = () => {

        const layout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
        };

        const validateMessages = {
            required: '${label} is required!',
            types: {
                email: '${label} is not valid!',
                number: '${label} is not a valid number!',
            },
            number: {
              range: '${label} must be between ${min} and ${max}',
            },
        };

        return (

            <div className={"table-row table-row-employment-form"}>
            
                <Form {...layout} scrollToFirstError={true} name="update_education_details" ref="update_education_details" validateMessages={validateMessages} onFinish={this.onEducationSubmit.bind(this)}>
            
                    <div className="d-flex form-item-block">
                        <Form.Item className="w-100" label="Major" name="major" rules={[{required:true}]}>
                            <Select
                                showSearch
                                placeholder="Choose option"
                                allowClear
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.props.courses_programms_data && this.props.courses_programms_data.length > 0
                                    ?
                                        this.props.courses_programms_data.map((_courses_programms_data) => {
                                            
                                            var key = 'course_pro_' + _courses_programms_data.id;
                                            
                                            return <Select.Option key={key} value={_courses_programms_data.label}>{_courses_programms_data.label}</Select.Option>
                                        })
                                    :
                                        null
                                }
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="d-flex form-item-block">
                        <Form.Item className="w-66" label="College / University" name="university" rules={[{required:true}]}>
                            <Select
                                showSearch
                                placeholder="Choose option"
                                allowClear
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.props.univercity && this.props.univercity.length > 0
                                    ?
                                        this.props.univercity.map((_univercity) => {
                                            
                                            var key = 'univercity_' + _univercity.id;
                                            
                                            return <Select.Option key={key} value={_univercity.name}>{_univercity.name}</Select.Option>
                                        })
                                    :
                                        null
                                }
                            </Select>
                        </Form.Item>

                        {/* <Form.Item className="w-33" label="Year" name="year_of_passing" rules={[{required:true}]}>
                            <InputNumber max={new Date().getFullYear()} />
                        </Form.Item> */}
                        <Form.Item className="w-33" label="Year" name="year_of_passing" rules={[{required:true}]}>
                            <Select
                                showSearch
                                placeholder="Choose option"
                                allowClear
                            >
                                {this.yearDropdown()}
                            </Select>
                        </Form.Item>
                    </div>

                    <div className="text-right buttons">
                        <Button type="secondary" onClick={() => {
                            this.setState({item: false});
                            this.props.hide_form();
                        }}>Cancel</Button>
                        <Button className="ml-10" type="primary" htmlType="submit" loading={this.state.loading}>
                            {this.state.item ? 'Update' : '+ Add'}
                        </Button>
                    </div>
                </Form>
                
            </div>
        )
    }

    yearDropdown = () => {

        var current_year = new Date().getFullYear();

        var min_year = current_year - 50;

        var html = [];

        for(var i = current_year; i >= min_year; i--){

            html.push(<Select.Option key={i} value={i}>{i}</Select.Option>)
        }
        return html;
    }

    renderList = () => {

        if(this.state.list.length > 0){

            return this.state.list.map((education_item, index) => {

                var key = 'education_item_' + education_item.id;
                return (

                    <div key={key} className={"row-employment-slider-"}>

                        <div className="block d-flex justify-content-between">
                            <div className="icon-block">
                                <img className="img-fluid" src={bachelorscapimg} alt={education_item.major} />
                                <span>{index + 1}</span>
                            </div>
                            <div className="text-block ml-20">
                                <div className="sl-title">{education_item.major}</div>
                                <div className="sl-company-name">{education_item.university}</div>
                                <div className="sl-from-to">{education_item.year_of_passing}</div>
                            </div>
                            <div className="sl-buttons">
                                
                                <div className="pointer" onClick={() => {
                                    this.editItem(education_item);
                                }}>
                                    Edit
                                </div>

                                <Popconfirm placement="left" title="Do you really want to remove this item?" onConfirm={() => {
                                    this.setState({item: false});
                                    this.remove(education_item);
                                }} okText="Yes" cancelText="Cancel">
                                    <div className="pointer">
                                        Remove
                                    </div>
                                </Popconfirm>
                            </div>
                        </div>
                    </div>
                )
            })
        }
    }

    editItem = (item) => {

        this.props.display_form();

        window.setTimeout(() => {

            if(item.emp_from != '0000-00-00'){
            
                item.from = moment(item.emp_from);
            }
            if(item.emp_to != '0000-00-00'){
            
                item.to = moment(item.emp_to);
            }

            this.setState({item: item});
            this.refs.update_education_details.setFieldsValue(item);
        });
        
    }

    renderTags = (tags) => {

        if(tags.length > 0){

            return tags.map((tag) => {

                var key = "tag_" + tag;
                return (

                    <span key={key} className="sl-single-tag">{tag}</span>
                )
            })
        }
    }

    remove = (item) => {

        var formData = new FormData();
        
        formData.append('account_token', this.props.account_token);
        formData.append('item_id', item.id);
        
        var that = this;

        this.setState({remove_loading: true});
        
        Api.post('freelancer/remove_education', formData, function(data){
            
            that.setState({remove_loading: false});
            if(data.status){
                that.setState({list: data.list});
            }
        });
    }

    loadEducationList = (account_token) => {
        var formData = new FormData();
        formData.append('account_token', account_token);
        
        var that = this;

        this.setState({loading: true});
        Api.post('freelancer/get_education', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){
            
                that.setState({list: data.list});
            }
        });
    }
}

export default Education;
