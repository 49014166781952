import React, { Component } from 'react';

// import { Link } from "react-router-dom";
// import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { Tabs, Collapse } from 'antd';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';

import bannerbgimg from '../assets/images/projects-bg.jpg';

import Api from '../api/Api';

import Main from '../components/Main';

const { Panel } = Collapse;

const calculateRemaining = (number, divideby) => {
    const remaining = number % divideby;
    return remaining;
}

class HowWeWorkPage extends Component { 
    constructor(props) {
        super();
        this.state = {
            // main_banner: '',
            main_heading: '',

            main_block_heading: '',
            main_block_points: [],
            main_block_image: '',
            main_block_video_code: '',

            client_content_blocks: [],
            sp_content_blocks: [],

            general_faqs: [],
            client_faqs: [],
            sp_faqs: []
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        this.setState({account_token: account_token});

        this.loadData();
    }

    renderMainBlockPoints = () => {

        const blocksList = [];
        for(let i = 0; i < this.state.main_block_points.length; i++) {
            let key = 'main_block_'+i;

            blocksList.push(
                <div className="point" key={key}>
                    <div className="img-block">
                        <img src={this.state.main_block_points[i].image_url} alt={this.state.main_block_points[i].image_alt} />
                    </div>
                    <div className="text-block">
                        <div className="block">
                            <div className="heading">{this.state.main_block_points[i].title}</div>
                            <div dangerouslySetInnerHTML={{ __html: this.state.main_block_points[i].text }} />
                        </div>
                    </div>
                    
                </div>
            );
        }
        return blocksList;
    }

    renderClientBlocks = () => {

        const blocksList = [];
        for(let i = 0; i < this.state.client_content_blocks.length; i++) {
            let key = 'client_block_'+i;

            let remaining_count = calculateRemaining(i, 2);
            
            blocksList.push(
                <div className={(remaining_count== 1)? 'img-text-section': 'img-text-section rev-row'} data={remaining_count} key={key}>
                    <div className="text-block half-container">
                        <div className="block">
                            <div className="num">{i+1}</div>
                            <div className="heading">{this.state.client_content_blocks[i].title}</div>
                            <div dangerouslySetInnerHTML={{ __html: this.state.client_content_blocks[i].text }} />
                        </div>
                    </div>
                    <div className="img-block">
                        <img src={this.state.client_content_blocks[i].image_url} alt={this.state.client_content_blocks[i].image_alt} />
                    </div>
                </div>
            );
        }
        return blocksList;
    }

    renderSpBlocks = () => {

        const blocksList = [];
        for(let i = 0; i < this.state.sp_content_blocks.length; i++) {
            let key = 'sp_block_'+i;

            let remaining_count = calculateRemaining(i, 2);
            
            blocksList.push(
                <div className={(remaining_count== 1)? 'img-text-section': 'img-text-section rev-row'} data={remaining_count} key={key}>
                    <div className="text-block half-container">
                        <div className="block">
                            <div className="num">{i+1}</div>
                            <div className="heading">{this.state.sp_content_blocks[i].title}</div>
                            <div dangerouslySetInnerHTML={{ __html: this.state.sp_content_blocks[i].text }} />
                        </div>
                    </div>
                    <div className="img-block">
                        <img src={this.state.sp_content_blocks[i].image_url} alt={this.state.sp_content_blocks[i].image_alt} />
                    </div>
                </div>
            );
        }
        return blocksList;
    }

    renderGeneralFaqs = () => {

        const blocksList = [];
        for(let i = 0; i < this.state.general_faqs.length; i++) {
            let key = 'general_faqs_'+i;

            blocksList.push(
                <Panel header={this.state.general_faqs[i].question} key={key}>
                    <div className="faq-content">
                        <div dangerouslySetInnerHTML={{ __html: this.state.general_faqs[i].answer }} />
                    </div>
                </Panel>
            );
        }
        return blocksList;
    }

    renderClientFaqs = () => {

        const blocksList = [];
        for(let i = 0; i < this.state.client_faqs.length; i++) {
            let key = 'client_faqs_'+i;

            blocksList.push(
                <Panel header={this.state.client_faqs[i].question} key={key}>
                    <div className="faq-content">
                        <div dangerouslySetInnerHTML={{ __html: this.state.client_faqs[i].answer }} />
                    </div>
                </Panel>
            );
        }
        return blocksList;
    }

    renderSpFaqs = () => {

        const blocksList = [];
        for(let i = 0; i < this.state.sp_faqs.length; i++) {
            let key = 'sp_faqs_'+i;

            blocksList.push(
                <Panel header={this.state.sp_faqs[i].question} key={key}>
                    <div className="faq-content">
                        <div dangerouslySetInnerHTML={{ __html: this.state.sp_faqs[i].answer }} />
                    </div>
                </Panel>
            );
        }
        return blocksList;
    }

    render () {

        return (

            <Main page_title="HowWeWorkPage">
                
                <div className="inner-page pt-0 white-bg">
                    <div>
                        <div className="banner-block orange-bg-img">
                            <img src={bannerbgimg} alt={this.state.main_heading} />
                            <div className="container">
                                <div className="heading">{this.state.main_heading}</div>
                            </div>
                        </div>
                    </div>

                    <div className="main-block">
                        <div className="container">
                            <div className="heading text-center">{this.state.main_block_heading}</div>
                            <div className="blocks">
                                <div className="content-block">
                                    {this.renderMainBlockPoints()}
                                </div>
                                <div className="image-block">
                                    <img src={this.state.main_block_image} alt={this.state.main_block_heading} />
                                </div>
                            </div>

                            <div className="video-block text-center" dangerouslySetInnerHTML={{ __html: this.state.main_block_video_code }} />
                        </div>
                    </div>

                    <div className="container client-sp-block">
                        <Tabs defaultActiveKey="1" centered>
                            <Tabs.TabPane tab="Clients" key="1">
                                {this.renderClientBlocks()}
                            </Tabs.TabPane>

                            <Tabs.TabPane tab="Service Providers" key="2">
                                {this.renderSpBlocks()}
                            </Tabs.TabPane>
                        </Tabs>
                    </div>

                    <div className="container faqs-block">
                        <div className="heading text-center">FAQs</div>
                        <Tabs defaultActiveKey="1" centered>
                            <Tabs.TabPane tab="General" key="1">
                                <Collapse accordion defaultActiveKey={['general_faqs_0']} expandIconPosition="right" expandIcon={({ isActive }) => isActive ? <MinusCircleOutlined /> : <PlusCircleOutlined />}>
                                    {this.renderGeneralFaqs()}
                                </Collapse>
                            </Tabs.TabPane>

                            <Tabs.TabPane tab="Client" key="2">
                                <Collapse accordion defaultActiveKey={['client_faqs_0']} expandIconPosition="right" expandIcon={({ isActive }) => isActive ? <MinusCircleOutlined /> : <PlusCircleOutlined />}>
                                    {this.renderClientFaqs()}
                                </Collapse>
                            </Tabs.TabPane>

                            <Tabs.TabPane tab="Service Provider" key="3">
                                <Collapse accordion defaultActiveKey={['sp_faqs_0']} expandIconPosition="right" expandIcon={({ isActive }) => isActive ? <MinusCircleOutlined /> : <PlusCircleOutlined />}>
                                    {this.renderSpFaqs()}
                                </Collapse>
                            </Tabs.TabPane>
                        </Tabs>
                    </div>
                    
                </div>
            </Main>
        )
    }


    loadData = () => {

        const formData = new FormData();
        var that = this;

        Api.post('cms/how_we_work', formData, function(data){

            that.setState({
                // main_banner: data.main_banner,
                main_heading: data.main_heading,

                main_block_heading: data.main_block_heading,
                main_block_points: data.why_cogniticx_points,
                main_block_image: data.why_cogniticx_img_url,
                main_block_video_code: data.why_cogniticx_video_code,

                client_content_blocks: data.client_content_blocks,
                sp_content_blocks: data.sp_content_blocks,

                general_faqs: data.general_faqs,
                client_faqs: data.client_faqs,
                sp_faqs: data.sp_faqs,
            });
        });
    }
}

export default HowWeWorkPage;