import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import { Select, Rate } from 'antd';

class FreelancerProductBlock extends Component { 

    constructor(props) {
        super();
        this.state = {

            rate_tooltips: ['terrible', 'bad', 'normal', 'good', 'wonderful'],
            size: 'large',
        }
    }
    
    render () {

        var product = this.props.product;

        var product_link = '/product-view/'+product.product_id;
        
        if(product){
            
            return (

                <div className="single-offer">
                    <Link to={product_link} className="top-block">
                        <img src={product.image_full} className="offer-image" alt="Offer Image" />
                        {(product.featured == '1')
                            ?
                                <span className="featured-text">Featured</span>
                            :
                                null
                        }
                        
                        <span className="wishlist-count">
                            <i className="ico heart"></i> {product.likes_count}
                        </span>
                    </Link>
                    <div className="mid-block">
                        <Link to={product_link} className="title">{product.title}</Link>
                        <div className="last-activity">Last activity: {product.last_activity_formatted}</div>
                        <div className="ratings-price">
                            <div className="ratings">
                                <Rate tooltips={this.state.rate_tooltips} size={this.state.size} value={product.reviews_rating_round} />
                                <div className="rating-text">{product.reviews_rating} ({product.reviews_count})</div>
                            </div>
                            <span className="price-text">
                                <span className="text">Price:</span>
                                <span className="price">{product.dollar_price_formatted}</span>
                            </span>
                        </div>
                    </div>
                    <div className="bottom-block">
                        <div className="sold-text">
                            <span className="num">{product.purchase_count}</span>
                            <span className="text">sold</span>
                        </div>
                        <Select size={this.state.size} placeholder="Featured" style={{ width: 120 }}>
                            <Select.Option value="Featured">Featured</Select.Option>
                            <Select.Option value="Resume">Resume</Select.Option>
                        </Select>
                    </div>
                </div>
            )
        }else{

            return null;
        }
    }
}

export default FreelancerProductBlock;