import React, { Component } from 'react';

// add form components
import { Form, Input, InputNumber, Select, Button, Collapse, Popconfirm, Checkbox, Radio, Tooltip } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { QuestionCircleOutlined } from '@ant-design/icons';
import FeatherIcon from 'feather-icons-react';
import Main from '../../../components/Main';

import LayoutHelper from '../../../helpers/LayoutHelper';

import Api from '../../../api/Api';

import Loader from '../../../components/Loader';

import dashboardbg from '../../../assets/images/dashboard-bg.png';

const { Panel } = Collapse;
const { Option } = Select;

class ProductAdd extends Component { 
    constructor(props) {
        super();
        this.state = {

            error_message: '',
            success_message: '',

            active_accordion: '1',
            active_tab: 'all_products',

            delivery_times: [1,2,3,4,5,6,7,8,9,10],
            work_types: [{key: 'remotely', value: 'Remotely'}, {key: 'on_site', value: 'ON-SITE IN New Delhi, IN'}],

            account_token: false,
            user: false,

            categories_list: [],
            subcategories_list: [],
            gallery: [],
            gallery_uploader: false,

            tags: [],

            addons: [{description: '', amount: '', days: ''}],
            deliver_all: 0,
            
            extra_addon_price: '',
            extra_addon_days: '',

            category_id: '',
            subcategory_id: '',

            confirm_check: 0,
            redirectTo: false
        }

        this.product_form = React.createRef();
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');

        var _user = JSON.parse(user);
        this.setState({user: _user});

        if(account_token){
            
            this.setState({account_token: account_token});

            this.getCategories();

            const product_id = this.props.product_id;

            if(product_id && product_id != ''){
                this.getSubCategories();
            }
            
        }else{
            window.location = Api.url + '/logout';
        }
    }


    onFinish = (values) => {
        var formData = new FormData();
        formData.append('account_token', this.state.account_token);

        Object.keys(values).forEach((key) => {

            if(values[key]){
                formData.append(key, values[key])
            }
        });

        var tags = this.state.tags;
        var addons = this.state.addons;
        var deliver_all = this.state.deliver_all;
        var gallery = this.state.gallery;

        formData.append('tags', JSON.stringify(tags));
        formData.append('addons', JSON.stringify(addons));
        formData.append('deliver_all', deliver_all);
        formData.append('gallery', JSON.stringify(gallery));

        if(this.state.confirm_check == '0'){
            LayoutHelper.addErrorMessage(this, 'Please check the confirmation box!!');
            return false;
        }
        
        var that = this;

        this.setState({loading: true});
        Api.post('products/add_product', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){
            
                LayoutHelper.addSuccessMessage(that, data.message);

                setTimeout(function(){
                    that.setState({redirectTo: 'products'});
                }, 3000);
                
            }else{
                LayoutHelper.addErrorMessage(that, data.message);
            }
        });
    }


    renderAddonRows = () => {
        const addons = this.state.addons;

        return addons.map((option, index) => {

            return (

                <div key={`option_${index}`} className="d-flex justify-content-between" data-rel_id={`option_${index}`}>
                    <div className="w-60">
                        <Form.Item className="w-100" label={(index == 0) ? "Earn extra money - Offer optional add - on services to the buyer" : null}>
                            <Input placeholder="Enter Description" type="text" value={option.description} onChange={(e) => {

                                const _addons = [...addons];
                                _addons[index]['description'] = e.target.value;
                                this.setState({addons: _addons});

                            }} />
                        </Form.Item>
                    </div>
                    <div className="w-20">
                        <Form.Item className="w-100" label={(index == 0) ? "Amount" : null}>
                            <InputNumber placeholder="Rs 729.00" type="text" value={option.amount} onChange={(e) => {
                                const _addons = [...addons];
                                _addons[index]['amount'] = e;
                                this.setState({addons: _addons});

                            }} />
                        </Form.Item>
                    </div>
                    <div className="w-20 last d-flex">
                        <Form.Item label={(index == 0) ? "Days" : null}>
                            <InputNumber placeholder="1 Day" type="text" value={option.days} onChange={(e) => {

                                const _addons = [...addons];
                                _addons[index]['days'] = e;
                                this.setState({addons: _addons});

                            }} />
                        </Form.Item>
                        <Button className="btn btn-lgreen remove-addon" onClick={() => {
                            const _addons = [...addons];

                            _addons.splice(index, 1);
                            this.setState({addons: _addons});
                        }}>X</Button>
                    </div>
                </div>
            )
        });
    }

    
    render () {

        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo} />;
        }

        const layout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
        };

        const tailLayout = {
            wrapperCol: { span: 24 },
        };

        const validateMessages = {
            required: '${label} is required!',
            types: {
                email: '${label} is not valid!',
                number: '${label} is not a valid number!',
            },
            number: {
                range: '${label} must be between ${min} and ${max}',
            },
        };

        return (

            <>
                <Main page_title="Manage Products" hide_page_title="true" className="products-add account-bg" page="as_seller" error_message={this.state.error_message} success_message={this.state.success_message}>

                    <div className="banner-block dashboard-bg-img">
                        <img src={dashboardbg} alt="Banner Image" />
                    </div>

                    <div className="page-main-content blue-gr-bg">
                        <div className="heading-container container-lg">
                            <div className="page-heading">Post an Offer</div>

                            <Collapse defaultActiveKey={['1']} expandIconPosition="right" expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />} onChange={(key, event) => {
                            this.setState({active_accordion: key});}}
                            >
                                <Panel header="Freelancer Tips" key="1">
                                    <div>
                                        <ol type="1">
                                            <li>Be original. What’s different about what you have to offer?</li>
                                            <li>Be as specific as you can about the service you are offering and exactly what the buyer will receive if they purchase your offer.</li>
                                            <li>Set a realistic delivery timeframe. Late delivery will lead to unhappy Buyers and will affect your rankings.</li>
                                            <li>Make your offer standout with great quality images or even video illustrating the service that you are offering.</li>
                                            <li>Make sure your offer meets all other <Link to="">posting policies</Link>.</li>
                                        </ol>
                                    </div>
                                </Panel>
                                <Panel header="What to Avoid" key="2">
                                    <div>
                                        <ol type="1">
                                            <li>Be original. What’s different about what you have to offer?</li>
                                            <li>Be as specific as you can about the service you are offering and exactly what the buyer will receive if they purchase your offer.</li>
                                            <li>Set a realistic delivery timeframe. Late delivery will lead to unhappy Buyers and will affect your rankings.</li>
                                            <li>Make your offer standout with great quality images or even video illustrating the service that you are offering.</li>
                                            <li>Make sure your offer meets all other <Link to="">posting policies</Link>.</li>
                                        </ol>
                                    </div>
                                </Panel>
                            </Collapse>
                        </div>

                        <div className="container-lg white-block">

                            <Form className="frm-white-block" {...layout} scrollToFirstError={true} name="product_form" ref="product_form" validateMessages={validateMessages} onFinish={this.onFinish.bind(this)}>

                                <div className="d-flex form-item-block">
                                    <div className="w-60">
                                            
                                        <Form.Item className="w-100 no-label" name="title" label="Description" rules={[{required:true, message:'Please enter description!'}]}>
                                            <Input placeholder="Enter Description" />
                                        </Form.Item>
                                        <div className="note left">e.g. I can design your business card for $60</div>
                                    </div>
                                    <Form.Item className="w-20 no-label" name="price" label="Price" rules={[{required:true, message:'Please enter price!'}]}>
                                        <InputNumber placeholder="Rs 729.00" />
                                    </Form.Item>
                                    <div className="w-20">
                                        <Form.Item className="w-100 no-label" name="dollar_price" label="Dollar Price" rules={[{required:true, message:'Please enter price in dollar!'}]}>
                                            <InputNumber placeholder="$ 10.00" />
                                        </Form.Item>
                                        <Form.Item className="w-100" name="customise_prices">
                                            <Checkbox className="orng-checkbox">Customise Prices</Checkbox>
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className="d-flex form-item-block">
                                    <Form.Item className="w-100" label={
                                        <div>
                                            When will you deliver the offer?{" "}
                                            <Tooltip title="Tooltip with customize">
                                                <QuestionCircleOutlined />
                                            </Tooltip>
                                        </div>} name="delivery_time" rules={[{required:true, message:'Please select delivery time!'}]}>
                                        <Select>
                                            {this.state.delivery_times && this.state.delivery_times.length > 0
                                                ?
                                                    this.state.delivery_times.map((dt) => {
                                                        var key = 'dt_' + dt;
                                                        return <Option key={key} value={dt}>In {dt} days</Option>
                                                    })
                                                :
                                                    null
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>

                                <div className="d-flex form-item-block">
                                    <Form.Item className="w-50" label="Category" name="category" rules={[{required:true, message:'Please select category!'}]}>
                                        <Select
                                            showSearch
                                            placeholder="Select a category"
                                            allowClear
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            onChange={(value) => {
                                                this.setState({category_id: value});
                                                this.refs.product_form.setFieldsValue({ subcategory: undefined });

                                                this.setState({subcategories_list: []});
                                                this.getSubCategories(value);
                                            }}
                                        >
                                            {this.categoriesList()}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item className="w-50" label="Subcategory" name="subcategory" rules={[{required:true, message:'Please select subcategory!'}]}>
                                        <Select
                                            showSearch
                                            placeholder="Select a subcategory"
                                            allowClear
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {this.subcategoriesList()}
                                        </Select>
                                    </Form.Item>
                                </div>

                                <div className="d-flex form-item-block">
                                    <Form.Item className="w-50" label="Add Tags(Max 5)" name="tags" /*rules={[{required:true, type:'array'}]}*/>
                                        <Select
                                            // showSearch
                                            mode="tags"
                                            placeholder="+ Add more tags"
                                            allowClear
                                            // filterOption={(input, option) =>
                                            //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            // }
                                            onChange={(values) => {
                                                // var values = this.refs.product_form.getFieldValue('tags');
                                                if(parseInt(values.length) > 5){
                                                    values.splice(-1, 1);
                                                }
                                                this.setState({tags: values});
                                            }
                                            
                                        }>
                                            {this.state.tags && this.state.tags.length > 0
                                                ?
                                                    this.state.tags.map((tag) => {
                                                        
                                                        var key = 'tag_' + tag;
                                                        
                                                        return <Option key={key} value={tag}>{tag}</Option>
                                                    })
                                                :
                                                    null
                                            }
                                        </Select>
                                        <div className="note left">Can’t find a skill? <Link className="link" to="">Contact us</Link> to add it</div>
                                    </Form.Item>
                                </div>

                                <div className="ant-row ant-form-item">
                                    <div className="ant-col ant-col-24 ant-form-item-label">
                                        <label title="Make it fun: Upload photos, videos!">Make it fun: Upload photos, videos! <Tooltip title="Tooltip with customize"><QuestionCircleOutlined /></Tooltip></label>
                                    </div>
                                    <div className="ant-col ant-col-24 ant-form-item-control">
                                        <div className="ant-form-item-control-input">
                                            <div className="ant-form-item-control-input-content">
                                                <label className="upload-panel">
                                                    <input type="file" className="hidden" onChange={(e) => {this._handleImageChange(e, 'gallery')}} ref={(input)=> this.myinput = input}/>
                                                    {/* <InboxOutlined className="icon" /> */}
                                                    <Button className="btn btn-blue" onClick={(e) => {this.myinput.click()}}>Upload Images</Button>
                                                    <p className="ant-upload-text">Click here or Drop Image to upload here (Maximum upload file size: 1mb)</p>
                                                </label>
                                                <div className="note">Make sure you upload only original content created by you.</div>

                                                <div className="form-gallery-container">
                                                    <div className="form-gallery">

                                                        <ul>
                                                            {this.displayGallery()}
                                                        </ul>
                                                        {this.state.gallery_uploader
                                                            ?   
                                                                <Loader is_small={true} />
                                                            :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex form-item-block">
                                    <Form.Item className="w-100" name="description" label="Provide details for your offer" rules={[{required:true}/*, {max:500}*/]}>
                                        <Input.TextArea /*showCount maxLength={500} */rows={3} placeholder="Explain in more detail what exactly you will delivery to the buyer…" />
                                    </Form.Item>
                                </div>

                                <div className="d-flex form-item-block">
                                    <Form.Item className="w-100" name="short_description" label="Provide a description for your offer, optimized for search results" rules={[{required:true}/*, {max:150}*/]}>
                                        <Input.TextArea /*showCount maxLength={150} */rows={3} placeholder="Briefly explain what you will deliver to the Buyer…" />
                                    </Form.Item>
                                </div>

                                <div className="form-item-block">
                                    <div className="extra-addons">
                                        {this.renderAddonRows()}
                                    </div>

                                    <div className="add-addon-block">
                                        <Button className="btn ant-btn-md" type="primary" onClick={() => {
                                            const addons = this.state.addons;
                                            const _addons = [...addons];

                                            _addons.push({description: '', amount: '', days: ''});
                                            this.setState({addons: _addons});
                                        }}>Add</Button>
                                    </div>

                                    <div className="all-addon-done">
                                        <div className="d-flex justify-content-between">
                                            <div className="w-60">
                                                <Form.Item className="w-100 text-right" name="deliver_all" onClick={(e) => {
                                                    var checked = e.target.checked;
                                                    if(checked){
                                                        this.setState({deliver_all:'1'});
                                                    }else{
                                                        this.setState({deliver_all:'0'});
                                                    }
                                                }}>
                                                    <Checkbox className="orng-checkbox">I can deliver all work for an extra <Tooltip title="Tooltip with customize"><QuestionCircleOutlined /></Tooltip></Checkbox>
                                                </Form.Item>
                                            </div>
                                            <div className="w-20">
                                                <Form.Item className="w-100" name="extra_addon_price">
                                                    <InputNumber placeholder="Rs 729.00" type="text" value={this.state.extra_addon_price} onChange={(e) => {
                                                        this.setState({extra_addon_price: e});
                                                    }} />
                                                </Form.Item>
                                            </div>
                                            <div className="w-20 last d-flex">
                                                <Form.Item className="w-100" name="extra_addon_days">
                                                    <InputNumber placeholder="1 Day" type="text" value={this.state.extra_addon_days} onChange={(e) => {
                                                        this.setState({extra_addon_days: e});
                                                    }} />
                                                </Form.Item>
                                                <Button className="btn btn-lgreen clear-total" onClick={() => {
                                                    //
                                                }}>X</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex form-item-block">
                                    <Form.Item className="w-100" name="requirements" label="What do you need from the buyer to get started?" rules={[{required:true}/*, {max:500}*/]}>
                                        <Input.TextArea /*showCount maxLength={500} */rows={3} placeholder="Explain what you will need from the buyer to deliver the work…" />
                                    </Form.Item>
                                </div>

                                <div className="d-flex form-item-block">
                                    <Form.Item className="w-100" label={
                                        <div>
                                            How are you planning to work with buyer?{" "}
                                            <Tooltip title="Tooltip with customize">
                                                <QuestionCircleOutlined />
                                            </Tooltip>
                                        </div>} name="work_type" rules={[{required:true, message:'Please select work type!'}]}>
                                        <Radio.Group className="d-flex-column">
                                            {this.state.work_types && this.state.work_types.length > 0
                                                ?
                                                    this.state.work_types.map((work_type) => {
                                                        var key = 'wt_' + work_type.key;
                                                        
                                                        return <Radio key={key} value={work_type.key}>{work_type.value}</Radio>
                                                    })
                                                :
                                                    null
                                            }
                                        </Radio.Group>
                                    </Form.Item>
                                </div>

                                <div className="d-flex form-item-block">
                                    <Form.Item className="w-100 no-label" name="confirm_check" label="Confirm Checkbox" onClick={(e) => {
                                            var checked = e.target.checked;
                                            if(checked){
                                                this.setState({confirm_check:'1'});
                                            }else{
                                                this.setState({confirm_check:'0'});
                                            }
                                        }} /*rules={[{required:true, message:'Please confirm!!'}]}*/>
                                        <Checkbox className="orng-checkbox">I confirm that I’m able to deliver this service to buyers within the delivery time specified. I will update or pause my offer if I can no longer meet this delivery time. I understand that late delivery will adversely affect my rankings on PPH and will entitle the Buyer to refund. See <Link className="link" to="" target="_blank">T&Cs</Link></Checkbox>
                                    </Form.Item>
                                </div>

                                <div className="d-flex form-item-block">

                                    <Form.Item {...tailLayout}>
                                        <Link className="btn ant-btn-lg ant-btn-secondary mr-20" to="" target="_blank">EDIT</Link>
                                        <Button className="btn ant-btn-lg" onClick={() => {
                                            this.setState({continue: true})
                                        }} type="primary" htmlType="submit" loading={this.state.loading}>POST OFFER</Button>
                                    </Form.Item>
                                </div>
                            </Form>
                            
                        </div>
                    </div>
                    
                </Main>
            </>
        )
    }


    _handleImageChange = async (e, type) => {
        e.preventDefault();
    
        let reader = new FileReader();
        let file = e.target.files[0];
    
        reader.readAsDataURL(file);

        var image_types = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'];

        var _sizes = {};
        _sizes['product_pic_small'] = [100, 100];
        _sizes['product_pic_medium'] = [300, 300];

        const formData = new FormData();
        formData.append('tmp_file_name', file);
        formData.append('allowed_types', 'jpg,jpeg,png,gif,mp4,mkv,mov,mpeg');
        formData.append('upload_dir', 'products/');
        if(image_types.indexOf(file.type) != -1){   // only if image type
            formData.append('image_sizes', JSON.stringify(_sizes));
        }

        this.setState({gallery_uploader: true});
        var that = this;

        Api.post('imageuploader', formData, function(data){

            that.setState({media_url: data.media_url});

            that.setState({gallery_uploader: false});

            if(data.status == 'success'){

                var _image = 'products/' + data.random_dir + data.main_file_name;
                var _gallery = that.state.gallery;
                
                var _img ={};
                _img['image'] = _image;
                _img['filename'] = data.main_file_name;
                if(image_types.indexOf(file.type) != -1){   // only if image type
                    _img['type'] = 'image';
                }else{
                    _img['type'] = 'video';
                }

                _gallery.push(_img);
                that.setState({gallery: _gallery});
            }
            
        });
    }

    displayGallery = () => {

        var gallery = this.state.gallery;
        var _gallery = [];

        if(gallery && gallery.length > 0){

            for(var i = 0; i < gallery.length; i++){

                var key = 'gallery_item_' + i;
                
                _gallery.push(
                    <li key={key}>
                        
                        <div className="img-block">
                            <img className="preview-image" src={this.state.media_url + gallery[i]['image']} />
                        </div>
                        <div className="text">
                            <Popconfirm title="Are you sure" placement="topRight" onConfirm={() => {
                                gallery.splice(gallery.indexOf(i), 1);
                                this.setState({gallery: gallery});

                            }} okText="Yes" cancelText="No">
                                <button className="close" type="button">
                                    <FeatherIcon icon="x" size={15} />
                                </button>
                            </Popconfirm>

                            <div className="name">{gallery[i]['filename']}</div>
                        </div>
                    </li>
                );
            }

            return _gallery;
        }
        return '';
    }


    categoriesList = () => {

        if((this.state.categories_list && this.state.categories_list.length > 0)){
                                                
            return this.state.categories_list.map((_category) => {

                return (

                    <Option key={"category_" + _category.id} value={_category.id}>{_category.name}</Option>
                )
            })
        }
    }


    subcategoriesList = () => {

        if((this.state.subcategories_list && this.state.subcategories_list.length > 0)){
                                                
            return this.state.subcategories_list.map((_subcategory) => {

                return (

                    <Option key={"subcategory_" + _subcategory.id} value={_subcategory.id}>{_subcategory.name}</Option>
                )
            })
        }
    }


    getCategories = () => {
        
        var formData = new FormData();
        
        var that = this;

        this.setState({loading: true});
        Api.post('categories/top_categories', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){
            
                if(data.categories.length > 0){

                    that.setState({categories_list: data.categories});
                }else{

                    that.setState({categories_list: []});
                }
                
            }else{

                LayoutHelper.addErrorMessage(that, data.message);
            }
        });
    }


    getSubCategories = (_category_id) => {
        
        var formData = new FormData();
        
        var that = this;
        
        if(_category_id){
            formData.append('parent', _category_id);
        }else{
            formData.append('parent', that.state.category_id);
        }
        
        this.setState({loading: true});
        Api.post('categories/top_categories', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){
            
                if(data.categories.length > 0){

                    that.setState({subcategories_list: data.categories});
                }else{

                    that.setState({subcategories_list: []});
                }
            }else{

                LayoutHelper.addErrorMessage(that, data.message);
            }
        });
    }


    loadProduct = (account_token) => {
        
        var formData = new FormData();
        
        formData.append('account_token', account_token);
        
        var that = this;

        this.setState({loading: true});
        Api.post('products', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){
            
                if(data.invitations.length > 0){

                    that.setState({invitations: data.invitations});
                }else{

                    that.setState({invitations: []});
                }
            }else{

                LayoutHelper.addErrorMessage(that, data.message);
            }
        });
    }
}

export default ProductAdd;