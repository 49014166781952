import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

// import PostProject from '../../components/sections/PostProjectPage';
import Main from '../../components/Main';

import { Form, Input, InputNumber, Button, Select, Radio, TimePicker, DatePicker, Steps, Popconfirm, Checkbox } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import moment from 'moment';

import FeatherIcon from 'feather-icons-react';

import Loader from '../../components/Loader';
import Api from '../../api/Api';

import LayoutHelper from '../../helpers/LayoutHelper';

import PostProjectStepOne from './PostProjectStepOne';
import PostProjectStepTwo from './PostProjectStepTwo';
import PostProjectStepThree from './PostProjectStepThree';

const { Option } = Select;

class PostProject extends Component { 
    constructor(props) {
        super();
        this.state = {

            loading: false,

            error_message: '',
            success_message: '',

            initial_values: {
                work_type: 'fixed'
            },

            project_posted: false,
            active_step: 0,
            active_tab: 0,
            percentage: 25,

            account_token: '',

            selected_options: [],

            tools: [],
            languages: [],
            industry: [],
            skills: [],

            categories: [],
            subcategories: [],

            domain_field_status: false,
            tools_field_status: false,
            skills_field_status: false,

            domain_value: [],
            other_domains_value: [],
            other_domain_status: false,
            domain_search: '',

            skill_value: [],
            other_skills_value: [],
            other_skills_status: false,

            tools_value: [],
            other_tools_value: [],
            other_tools_status: false,

            gallery: [],
            gallery_uploader: false,

            selected_location: '',
            custom_questions: false,

            project: false
        }

        this.page = React.createRef();
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        this.setState({account_token: account_token});

        this.loadFields();
        this.loadCategories();
    }

    onFinish = (values) => {
        var formData = new FormData();
        Object.keys(values).forEach((key) => {

            if(values[key]){
                formData.append(key, values[key]);
            }
        });

        var _gallery = this.state.gallery;
        if(_gallery.length){
            formData.append('files', _gallery.join(','));
        }

        formData.append('account_token', this.state.account_token);
        
        var that = this;
        this.setState({loading: true});

        Api.post('projects/post_project', formData, function(data){
            
            that.setState({loading: false});
            if(data.status == true){
                
                LayoutHelper.addSuccessMessage(that, data.message);
                that.setState({active_step: 1, project_posted: true, active_tab: 1, project: data.project});
                that.moveToTop();
            
            }else{

                LayoutHelper.addErrorMessage(that, data.message);
            }
            
        });
    }

    moveToTop = () => {
        this.page.current.scrollIntoView({ behavior: 'smooth' });
    }

    dropdownOptions = (key, pre_fix, search) => {

        var data = this.state[key];

        if(data.length){

            var _data = data;
            // if(this.state[search] != ''){

            //     data.forEach(_row => {

            //         var _label = _row.label.toLowerCase();
            //         if(_label.indexOf(this.state[search].toLowerCase()) > -1){

            //             _data.push(_row);
            //         }
            //     })
            // }else{

            //     _data = data;
            // }

            return _data.map((__data) => {

                var key = pre_fix + __data.id;
                return (

                    <Option key={key} value={__data.id}>{__data.label}</Option>
                )
            })
        }
    }

    render () {

        const layout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
        };

        const tailLayout = {
            wrapperCol: { span: 24 },
        };

        const validateMessages = {
            required: '${label} is required!',
            types: {
              email: '${label} is not valid!',
              number: '${label} is not a valid number!',
            },
            number: {
              range: '${label} must be between ${min} and ${max}',
            },
        };

        const normFile = e => {
            console.log('Upload event:', e);
          
            if (Array.isArray(e)) {
              return e;
            }
          
            return e && e.fileList;
        };

        return (

            <>
                <Main page_title="Get your project done!" page_subtitle="Post a project for free - start receiving proposal within minutes" page="account_post_project" success_message={this.state.success_message} error_message={this.state.error_message}>
                
                    <div ref={this.page}>

                        {this.state.active_tab == 0 &&
                            
                            <div className="container card">
                                <div className="right">
                                    <div className="tips-title d-flex justify-content-center">
                                        <div className="small-white-block">
                                            <span className="top">Useful</span>
                                            <span className="bottom">Tips!</span>
                                        </div>
                                    </div>
                                    <div className="white-block">
                                        <p>1. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed faucibus aliquam lectus sit amet imperdiet. In ut luctus justo. Suspendisse ac vulputate massa. Aenean imperdiet nibh nec ipsum sodales, sit amet rutrum augue euismod. </p>
                                        <p>2. Proin auctor enim quis dolor rutrum convallis. Nam nec tellus sed odio semper rhoncus non sit amet ex. Vivamus gravida cursus augue at accumsan. Sed sem est, tempus eu accumsan ac, imperdiet at lorem.</p>
                                        <p>3. Vestibulum ut lorem eget nisi varius tristique. Phasellus ex ex, scelerisque nec dignissim ac, egestas eget dui. Nunc non viverra augue. Vivamus vehicula ullamcorper mauris, id porttitor nisl pulvinar nec.</p>
                                    </div>
                                </div>
                            </div>
                        }

                        {this.state.project_posted
                            ?

                                <div>

                                    <div className="main-section post-project-extras-page">
                                        <div className="container">

                                            <div className="justify-center">
                                                <Steps className="post-project-steps" size="small" current={this.state.active_step}>
                                                    <Steps.Step title="Project Posted" onClick={() => {
                                                        // this.setState({project_posted: false, active_step: 1, active_tab: 1});
                                                    }} />
                                                    <Steps.Step title="Add Extras" onClick={() => {
                                                        if(this.state.active_step == 2){
                                                            this.setState({active_step: 1, active_tab: 1});
                                                        }
                                                    }} />
                                                    <Steps.Step title="Invite Service Provider" />
                                                </Steps>

                                                <span style={{fontSize:30, color:'#003965', marginLeft:100}}>{this.state.percentage * this.state.active_step}%</span>
                                            </div>

                                            {this.state.active_tab == 1 &&
                                                <div className="post-project-extras-page-1 mt-3">
                                                    <PostProjectStepOne
                                                        project={this.state.project}
                                                        onClick={(selected_options) => {
                                                            this.setState({active_step: 2, active_tab: 2, selected_options: selected_options});
                                                            window.setTimeout(() => {
                                                                this.page.current.scrollIntoView({ behavior: 'smooth' });
                                                            }, 200)
                                                            
                                                        }}
                                                        skip={() => {
                                                            this.setState({active_step: 2, active_tab: 2, selected_options: []});
                                                            window.setTimeout(() => {
                                                                this.page.current.scrollIntoView({ behavior: 'smooth' });
                                                            }, 200)
                                                        }}
                                                    />
                                                </div>
                                            }

                                            {this.state.active_tab == 2 && 
                                                <div className="post-project-extras-page-2 payment">
                                                    <PostProjectStepTwo
                                                        project={this.state.project}
                                                        selected_options={this.state.selected_options}
                                                        onClick={() => {
                                                            this.setState({active_step: 3, active_tab: 3});
                                                            window.setTimeout(() => {
                                                                this.page.current.scrollIntoView({ behavior: 'smooth' });
                                                            }, 200)
                                                        }}
                                                        goBack={() => {
                                                            this.setState({active_step: 1, active_tab: 1});
                                                            window.setTimeout(() => {
                                                                this.page.current.scrollIntoView({ behavior: 'smooth' });
                                                            }, 200)
                                                        }}
                                                    />
                                                </div>
                                            }

                                            {this.state.active_tab == 3 && 
                                                <div className="post-project-extras-page-3">
                                                    <PostProjectStepThree
                                                        project={this.state.project}
                                                        selected_options={this.state.selected_options}
                                                        onClick={() => {
                                                            this.setState({active_step: 2, active_tab: 2});
                                                            window.setTimeout(() => {
                                                                this.page.current.scrollIntoView({ behavior: 'smooth' });
                                                            }, 200)
                                                        }}
                                                        goBack={() => {
                                                            this.setState({active_step: 1, active_tab: 1});
                                                            window.setTimeout(() => {
                                                                this.page.current.scrollIntoView({ behavior: 'smooth' });
                                                            }, 200)
                                                        }}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            :
                                <div className="section container post-project-page d-flex">
                                    
                                    <div className="card mt-3">
                                    
                                        <Form {...layout} scrollToFirstError={true} name="project_form" ref="project_form" initialValues={this.state.initial_values} validateMessages={validateMessages} onFinish={this.onFinish.bind(this)}>
                                            <Form.Item label="What do you need to get it done?" name="title" rules={[{required:true}]} extra="Try to summarise your project in one sentence.">
                                                <Input placeholder="e.g. I need a flyer or poster created" />
                                            </Form.Item>
                                            
                                            <div className="d-flex form-item-block">
                                                <Form.Item className="w-50" label="Category" name="category" rules={[{required:true, message:'Please select category!'}]}>
                                                    <Select onChange={(cat_id) => {
                                                        this.getSubCategory(cat_id);
                                                    }} placeholder="Select a category" allowClear>
                                                        {this.state.categories && this.state.categories.length > 0
                                                            ?
                                                                this.state.categories.map((category) => {
                                                                    
                                                                    var key = 'category_' + category.id;
                                                                    
                                                                    return <Option key={key} value={category.id}>{category.name}</Option>
                                                                })
                                                            :
                                                                null
                                                        }
                                                    </Select>
                                                </Form.Item>

                                                <Form.Item className="w-50" label="Sub Category" name="sub_category" rules={[{required:false}]}>
                                                    <Select placeholder="Select a option and change input text above" allowClear>
                                                        {this.state.subcategories && this.state.subcategories.length > 0
                                                            ?
                                                                this.state.subcategories.map((sub_category) => {
                                                                    
                                                                    var key = 'sub_category_' + sub_category.id;
                                                                    
                                                                    return <Option key={key} value={sub_category.id}>{sub_category.name}</Option>
                                                                })
                                                            :
                                                                null
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>

                                            <div className="d-flex form-item-block">
                                                <Form.Item name="domain" label="Domain" extra="Please choose max 3 domains." className="w-50" rules={[{required: true, message: 'Please select Domain!', type: 'array'}]}>
                                                    <Select value={this.state.domain_value} allowClear={true} onChange={(values) => {
                                                        var other_domains = this.state.other_domains_value.length;
                                                        var value = this.refs.project_form.getFieldValue('domain');
                                                        if((parseInt(other_domains) + parseInt(value.length)) > 3){
                                                            value.splice(-1, 1);
                                                        }
                                                        this.setState({domain_value: value});
                                                    }} mode="multiple" placeholder="Search Domains">

                                                        {this.dropdownOptions('industry', 'industry_', 'domain_search')}
                                                    </Select>
                                                </Form.Item>

                                                <Form.Item label={<><input type="checkbox" onChange={(it) => {
                                                    var _it = it.target.checked;
                                                    if(!_it){
                                                        this.setState({other_domains_value: []});
                                                        this.refs.project_form.setFieldsValue({other_domains: []})   
                                                    }
                                                    window.setTimeout(() => {
                                                        this.setState({other_domain_status: _it});
                                                    }, 300);
                                                }} style={{marginRight:5, marginBottom:0}} />Others</>} name="other_domains" extra="Max 3 values are allowed." className="w-50">
                                                    {/* <Select placeholder="Type and hit enter key" value={this.state.other_domains_value} disabled={!this.state.other_domain_status} tokenSeparators="," mode="tags" onChange={(value) => {
                                                        var domain_size = this.state.domain_value.length;
                                                        var other_domains = this.refs.project_form.getFieldValue('other_domains');
                                                        
                                                        if((parseInt(other_domains.length) + parseInt(domain_size)) > 3){
                                                            other_domains.splice(-1, 1);
                                                        }
                                                        this.setState({other_domains_value: other_domains});
                                                    }}>

                                                    </Select> */}
                                                </Form.Item>
                                            </div>
                                            

                                            <div className="d-flex form-item-block">

                                                <Form.Item name="skills" label="Skills/Expertise" extra="Please choose max 3 skills." className="w-50" rules={[{required: true, message: 'Please select Skills!', type: 'array'}]}>
                                                    
                                                    <Select value={this.state.skill_value} allowClear={true} onChange={(values) => {
                                                        var other_skills = this.state.other_domains_value.length;
                                                        var value = this.refs.project_form.getFieldValue('skills');
                                                        if((parseInt(other_skills) + parseInt(value.length)) > 3){
                                                            value.splice(-1, 1);
                                                        }
                                                        this.setState({skill_value: value});
                                                    }} mode="multiple" placeholder="Search Skills">

                                                        {this.dropdownOptions('skills', 'skill_', 'skill_search')}
                                                    </Select>
                                                </Form.Item>

                                                <Form.Item label={<><input type="checkbox" onChange={(it) => {
                                                    var _it = it.target.checked;
                                                    if(!_it){
                                                        this.setState({other_skills_value: []});
                                                        this.refs.project_form.setFieldsValue({other_skills: []})   
                                                    }
                                                    window.setTimeout(() => {
                                                        this.setState({other_skills_status: _it});
                                                    }, 300);
                                                }} style={{marginRight:5, marginBottom:0}} />Others</>} name="other_skills" extra="Max 3 values are allowed." className="w-50">
                                                    {/* <Select placeholder="Type and hit enter key" value={this.state.other_skills_value} disabled={!this.state.other_skills_status} tokenSeparators="," mode="tags" onChange={(value) => {
                                                        var domain_size = this.state.skill_value.length;
                                                        var other_skills = this.refs.project_form.getFieldValue('other_skills');
                                                        
                                                        if((parseInt(other_skills.length) + parseInt(domain_size)) > 3){
                                                            other_skills.splice(-1, 1);
                                                        }
                                                        this.setState({other_skills_value: other_skills});
                                                    }}>

                                                    </Select> */}
                                                </Form.Item>
                                            </div>

                                            <div className="d-flex form-item-block">

                                                <Form.Item name="tools" label="Tools/Technology" extra="Please choose max 3 tools." className="w-50" rules={[{required: true, message: 'Please select Tools/Technology!', type: 'array'}]}>
                                                    <Select value={this.state.tools_value} allowClear={true} onChange={(values) => {
                                                        var other_tools = this.state.other_tools_value.length;
                                                        var value = this.refs.project_form.getFieldValue('tools');
                                                        if((parseInt(other_tools) + parseInt(value.length)) > 3){
                                                            value.splice(-1, 1);
                                                        }
                                                        this.setState({tools_value: value});
                                                    }} mode="multiple" placeholder="Search Tools">

                                                        {this.dropdownOptions('tools', 'tool_', 'tool_search')}
                                                    </Select>
                                                </Form.Item>

                                                <Form.Item label={<><input type="checkbox" onChange={(it) => {
                                                    var _it = it.target.checked;
                                                    if(!_it){
                                                        this.setState({other_tools_value: []});
                                                        this.refs.project_form.setFieldsValue({other_tools: []})   
                                                    }
                                                    window.setTimeout(() => {
                                                        this.setState({other_tools_status: _it});
                                                    }, 300);
                                                }} style={{marginRight:5, marginBottom:0}} />Others</>} name="other_tools" extra="Max 3 values are allowed." className="w-50">
                                                    {/* <Select placeholder="Type and hit enter key" value={this.state.other_tools_value} disabled={!this.state.other_tools_status} tokenSeparators="," mode="tags" onChange={(value) => {
                                                        var tools_size = this.state.tools_value.length;
                                                        var other_tools = this.refs.project_form.getFieldValue('other_tools');
                                                        
                                                        if((parseInt(other_tools.length) + parseInt(tools_size)) > 3){
                                                            other_tools.splice(-1, 1);
                                                        }
                                                        this.setState({other_tools_value: other_tools});
                                                    }}>

                                                    </Select> */}
                                                </Form.Item>
                                            </div>

                                            <Form.Item label="Project Description" name="description" rules={[{required: true}]} extra="Be descritive, project with good description are more popular with our freelancers.">
                                                <Input.TextArea />
                                            </Form.Item>

                                            <div className="ant-row ant-form-item">
                                                <div className="ant-col ant-col-24 ant-form-item-label">
                                                    <label className="" title="Upload Samples and other helpful material (Optional)">Upload Samples and other helpful material (Optional)</label>
                                                </div>
                                                <div className="ant-col ant-col-24 ant-form-item-control">
                                                    <div className="ant-form-item-control-input">
                                                        <div className="ant-form-item-control-input-content">
                                                            <label className="upload-panel">
                                                                <input type="file" className="hidden" onChange={(e) => {this._handleImageChange(e, 'gallery')}} ref={(input)=> this.myinput = input}/>
                                                                <InboxOutlined className="icon" />
                                                                <p className="ant-upload-text">Click here to upload the files</p>
                                                            </label>

                                                            <div className="form-gallery-container">
                                                                <div className="form-gallery">

                                                                    <ul>
                                                                        {this.displayGallery()}
                                                                    </ul>
                                                                    {this.state.gallery_uploader
                                                                        ?   
                                                                            <Loader is_small={true} />
                                                                        :
                                                                            null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="d-flex form-item-block">
                                                <Form.Item name="work_type" label="Work Type" className="w-33">
                                                    <Radio.Group className="d-flex">
                                                        <Radio value="fixed">Fixed</Radio>
                                                        <Radio value="hourly">Hourly</Radio>
                                                    </Radio.Group>
                                                </Form.Item>

                                                <Form.Item label="Currency" className="w-33" rules={[{required: true, message: 'Please choose currency!'}]}>
                                                    <Form.Item name="currency">
                                                        <Select placeholder="Select Currency">
                                                            <Option value="inr">INR</Option>
                                                            <Option value="usd">USD</Option>
                                                            <Option value="gbp">GBP</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Form.Item>

                                                <Form.Item label="Budget" className="w-33" rules={[{required: true, message: 'Please enter budget!'}]}>
                                                    <Form.Item name="budget">
                                                        <InputNumber step={0.01} min={10} defaultValue={0.00} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')} /*onChange={onChange}*/ />
                                                    </Form.Item>
                                                </Form.Item>
                                            </div>

                                            <div className="d-flex form-item-block">
                                                <Form.Item name="location" label="Where does the freelancer need to work from?" className="w-66" rules={[{required: true, message: 'Please enter Location!'}]}>
                                                    <Select placeholder="Select Currency" onSelect={(value) => {
                                                        this.setState({selected_location: value});
                                                    }}>
                                                        <Option value="r-a">Remotely (anywhere)</Option>
                                                        <Option value="r-c">Remotely (preferred country)</Option>
                                                        <Option value="o-s">On-site</Option>
                                                    </Select>
                                                </Form.Item>

                                                {this.state.selected_location == 'r-c'
                                                    ?
                                                        <Form.Item name="country" label="Country" className="w-33" rules={[{required: true, message: 'Please select country!'}]}>
                                                            <Select placeholder="Select Country">
                                                                <Option value="IN">India</Option>
                                                            </Select>
                                                        </Form.Item>
                                                    :
                                                        null
                                                }
                                            </div>

                                            <div className="d-flex form-item-block">
                                                <Form.Item name="start_date" label="Starting Date (Optional)" className="w-33">
                                                    <DatePicker disabledDate={(current) => {
                                                        return current < moment().endOf('day');
                                                    }} placeholder="Select Date" /*onChange={onChange}*/ />
                                                </Form.Item>

                                                <Form.Item name="start_time" label="Starting Time (Optional)" className="w-33">
                                                    <TimePicker placeholder="Select Time" use12Hours format="h:mm a" />
                                                </Form.Item>

                                                <div className="duration-on-site w-33">
                                                    <Form.Item name="hours" label="Duration On-Site (Optional)">
                                                        <Select placeholder="Hours" className="w-50">
                                                            <Option value="1">1</Option>
                                                            <Option value="2">2</Option>
                                                            <Option value="3">3</Option>
                                                            <Option value="4">4</Option>
                                                            <Option value="5">5</Option>
                                                            <Option value="6">6</Option>
                                                            <Option value="7">7</Option>
                                                            <Option value="8">8</Option>
                                                        </Select>
                                                        <Select name="days" placeholder="Days" className="w-50">
                                                            <Option value="1">1</Option>
                                                            <Option value="2">2</Option>
                                                            <Option value="3">3</Option>
                                                            <Option value="4">4</Option>
                                                            <Option value="5">5</Option>
                                                            <Option value="6">6</Option>
                                                            <Option value="7">7</Option>
                                                            <Option value="8">8</Option>
                                                            <Option value="9">9</Option>
                                                            <Option value="10">10</Option>
                                                            <Option value="11">10+</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <Form.Item name="visibility" label="Visibility of Project Post">
                                                <Radio.Group>
                                                    <Radio value="public"> <FeatherIcon icon="users" /> Public <span className="small-txt fw-300">(All freelancers can view the project post and send proposals)</span></Radio>
                                                    <Radio value="private"> <FeatherIcon icon="lock" /> Private <span className="small-txt fw-300">(only freelancers that you specifically invite can view the project post and send proposals)</span></Radio>
                                                </Radio.Group>
                                            </Form.Item>

                                            <Form.Item className="form-item-block d-flex" name="questions" label="Get to know your freelancer (Optional)">
                                                <Select placeholder="Please choose option" onChange={(e) => {
                                                    this.setState({custom_questions: e})
                                                }}>
                                                    <Option value="yes">Yes</Option>
                                                    <Option value="no">No</Option>
                                                </Select>
                                            </Form.Item>

                                            {this.state.custom_questions == 'yes'
                                                ?
                                                    <Form.Item className="form-item-block d-flex" name="custom_questions[]" label="">
                                                        <Checkbox.Group options={[
                                                            'Question 1',
                                                            'Question 2',
                                                            'Question 3'
                                                        ]} />
                                                    </Form.Item>
                                                :
                                                    null
                                            }

                                            <Form.Item className="form-item-block d-flex" name="estimate_duration" label="Estimate Project Duration (Optional)">
                                                <Select placeholder="Select" className="w-66">
                                                    <Option value="1-day">Less than 1 Day</Option>
                                                    <Option value="1-week">Less than 1 Week</Option>
                                                    <Option value="1-2-week">1-2 Week</Option>
                                                    <Option value="3-4-week">3-4 Weeks</Option>
                                                    <Option value="1-6-month">1-6 Months</Option>
                                                </Select>
                                            </Form.Item>
                                            
                                            <div className="form-item-block d-flex">
                                                <Form.Item {...tailLayout}>
                                                    {/* <Button className="btn-blue-txt" type="ghost" htmlType="button"><FeatherIcon icon="eye-off" /> Hide Advanced Option</Button> */}
                                                </Form.Item>
                                                <Form.Item {...tailLayout}>
                                                    <Button className="btn" htmlType="submit" type="primary" loading={this.state.loading}>Post Project</Button>
                                                </Form.Item>
                                            </div>
                                        </Form>
                                        
                                    </div>
                                    
                                    {this.state.loading &&
                                        <Loader />
                                    }
                                </div>
                        }
                    </div>
                </Main>
            </>
        )
    }

    _handleImageChange = async (e, type) => {
        e.preventDefault();
    
        let reader = new FileReader();
        let file = e.target.files[0];
    
        reader.readAsDataURL(file);

        var _sizes = {};
        _sizes['profile_pic_small'] = [100, 100];
        _sizes['profile_pic_medium'] = [300, 300];

        const formData = new FormData();
        formData.append('tmp_file_name', file);
        formData.append('allowed_types', 'jpg,jpeg,png,gif');
        formData.append('upload_dir', 'project_files/');
        formData.append('image_sizes', JSON.stringify(_sizes));

        this.setState({gallery_uploader: true});
        var that = this;

        Api.post('imageuploader', formData, function(data){

            that.setState({media_url: data.media_url});

            that.setState({gallery_uploader: false});

            if(data.status == 'success'){

                var _image = 'project_files/' + data.random_dir + data.main_file_name;
                var _gallery = that.state.gallery;
                _gallery.push(_image);
                that.setState({gallery: _gallery});
            }
            
        });
    }

    displayGallery = () => {

        var gallery = this.state.gallery;
        var _gallery = [];

        if(gallery && gallery.length > 0){

            for(var i = 0; i < gallery.length; i++){

                var key = 'gallery_item_' + i;
                
                _gallery.push(
                    <li key={key}>
                        <Popconfirm title="Are you sure" placement="topRight" onConfirm={() => {
                            gallery.splice(gallery.indexOf(i), 1);
                            this.setState({gallery: gallery});

                        }} okText="Yes" cancelText="No">
                            <button className="close" type="button">
                                <FeatherIcon icon="x" size={15} />
                            </button>
                        </Popconfirm>
                        
                        <img className="preview-image" src={this.state.media_url + gallery[i]} />
                    </li>
                );
            }

            return _gallery;
        }
        return '';
    }

    getSubCategory = (category_id) => {

        var sub_categories = false;
        var categories = this.state.categories;
        if(categories.length > 0){

            categories.forEach(_category => {

                if(_category.id == category_id){

                    if(_category.hasOwnProperty('childs')){

                        sub_categories = _category.childs;
                    }
                }
            });
        }
        this.setState({subcategories: sub_categories});
    }

    loadCategories = () => {

        const formData = new FormData();
        var that = this;

        Api.post('categories/listing', formData, function(data){
            
            that.setState({
                categories: data.categories
            });
        });
    }

    loadFields = () => {

        const formData = new FormData();
        var that = this;

        Api.post('general/fields', formData, function(data){
            
            that.setState({
                tools: data.tools,
                languages: data.languages,
                industry: data.industry,
                skills: data.skills
            });
        });
    }
}

export default PostProject;