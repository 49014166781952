import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import { Avatar, Typography, Rate } from 'antd';

import { CheckCircleOutlined } from '@ant-design/icons';

import Tags from '../Tags';

class FreelancerRow extends Component { 
    
    render () {

        var user = this.props.freelancer;
        
        if(user){
            
            return (

                <div className="row-item">
                    <div className="left">
                        <div className="profile-image">

                            <Link to={"/freelancers/" + user.slug}>
                                <Avatar className={user.profile_pic_full != '' ? '' : 'empty'} size={120} src={user.profile_pic_full != '' ? user.profile_pic_full : require('../../assets/graphics/user.svg')} shape="square" />

                            </Link>
                            {(user.featured && user.featured == 1) && 
                                    
                                <span className="featured-text">Featured</span>
                            }
                        </div>
                        
                        <Rate disabled value={user.avg_rating} />
                        <div className="rating-text">{user.avg_rating} ({user.reviews_count})</div>
                    </div>
                    <div className="right">
                        <div className="top-section d-flex justify-content-between">
                            <div className="title">
                                <Link to={"/freelancers/" + user.slug}>{user.name}</Link>

                                {(user.verified && (user.verified == 1)) &&
                                
                                    <span className="verified"><CheckCircleOutlined /> Verified</span>
                                }
                            </div>
                            <div className="price-section">

                                {user.price > 0 &&
                                
                                    <div className="price">{user.price_formatted}<span className="normal">/hr</span></div>
                                }
                            </div>
                        </div>
                        <div className="mid-section">
                            <div className="location">{user.city}, {user.country_name}</div>

                            <Typography.Paragraph rows={2} ellipsis>{user.description}</Typography.Paragraph>
                        </div>
                        <div className="bottom-section">
                            <div className="tags">

                                {user.skills_formatted &&
                                    <Tags tags={user.skills_formatted} limit={4} limit_suffex="skills" />
                                }
                            </div>

                            {this.props.source
                                ?
                                    this.props.source == 'proposals'
                                        ?
                                            <Link to={"/project-flow/" + this.props.project.project_unq_id + '/' + user.freelancer_id} className="dark-blue-btn">View</Link>
                                        :
                                            null
                                :
                                    <Link to={"/freelancers/" + user.slug} className="dark-blue-btn">View Profile</Link>
                            }
                        </div>
                    </div>
                </div>

            )
        }else{

            return null;
        }
    }
}

export default FreelancerRow;