import React, { Component } from 'react';

import CountUp from 'react-countup';

// import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { Carousel, Button} from "antd";

import CategoryBlock from '../components/sections/category/CategoryBlock';

import BannerSlider from '../components/sections/common/BannerSlider';
import HomeInfoBlock from './home/HomeInfoBlock';
import SPProjectsTabs from '../components/sections/common/SPProjectsTabs';
import HomeHowWeWork from '../pages/home/HomeHowWeWork';
import HomeMoneyProtection from '../pages/home/HomeMoneyProtection';

import Main from '../components/Main';

import Api from '../api/Api';

const calculateRemaining = (number, divideby) => {
    const remaining = number % divideby;
    return remaining;
}

class Homepage extends Component { 
    constructor(props) {
        super();
        this.state = {
        
            logos: [],
            trusted_partners_title: '',
            trusted_partners: [],

            category_section_title: '',
            categories: [],

            all_loaded: false,

            banners: [],
            stats: {},
            section_one: {},
            section_two: {},

            info_blocks: [],

            sp_tab_blocks: [],
            project_tab_blocks: [],
            products_list: [],
            tab_totals: [],

            how_work: [],
            how_work_left_blocks: [],
            how_work_right_blocks: [],

            money_protection: [],
            money_protection_blocks: [],
        }
    }
    
    
    componentDidMount = () => {
        
        this.loadData();
    }

    renderCategories = () => {

        const categoriesList = [];
        let max = 8;

        if(this.state.categories.length > 0){
        
            if(this.state.all_loaded){

                max = this.state.categories.length;
            }

            for(let i = 0; i < max; i++) {

                let hkey = 'hc_'+i;
                let link = this.state.categories[i].slug;

                categoriesList.push(
                    <CategoryBlock key={hkey} category={this.state.categories[i]} link={link} />
                );
            }
        }

        return categoriesList;
    }

    renderInfoBlocks = () => {

        const infoBlocksList = [];
        for(let i = 0; i < this.state.info_blocks.length; i++) {
            let key = 'info_'+i;

            let remaining_count = calculateRemaining(i, 2);
            
            var classes = '';
            {(remaining_count == 1)
                ?
                    classes = 'img-text-section discover-sp rev-row'
                :
                    classes = 'img-text-section discover-sp'
            }

            infoBlocksList.push(
                <HomeInfoBlock key={key} classes={classes} info_block={this.state.info_blocks[i]} />
            );
        }
        return infoBlocksList;
    }

    renderTabTotals = () => {

        const tabTotalsList = [];
        for(let i = 0; i < this.state.tab_totals.length; i++) {
            let key = 'tt_'+i;
            let link = this.state.tab_totals[i].link;

            tabTotalsList.push(
                <div className="" key={key}>
                    <div className="num">
                        <CountUp separator="," end={this.state.tab_totals[i].num} duration={10} />    
                    </div>
                    
                    <div className="text">{this.state.tab_totals[i].text}</div>
                </div>
            );
        }
        return tabTotalsList;
    }

    loadData = () => {

        var formData = new FormData();
        formData.append('slug', 'home');

        var that = this;

        Api.post('general/initial', formData, function(data){

            that.setState({
                logos: data.logos,
                trusted_partners_title: data.trusted_partners_title,
                trusted_partners: data.trusted_partners,
                category_section_title: data.category_section_title,
                categories: data.categories,
                banners: data.banners,
                stats: data.stats,
                section_one: data.section_one,
                section_two: data.section_two,

                info_blocks: data.info_blocks,

                sp_tab_blocks: data.sp_tab_blocks,
                project_tab_blocks: data.project_tab_blocks,
                products_list: data.products_list,
                tab_totals: data.tab_totals,

                how_work: data.how_work,
                how_work_left_blocks: data.how_work_left_blocks,
                how_work_right_blocks: data.how_work_right_blocks,
                money_protection: data.money_protection,
                money_protection_blocks: data.money_protection_blocks
            });
        });
    }

    render () {
        const props = {
            arrows: true,
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3,
        };

        return (

            <>
                <Main page_title="">
                    <BannerSlider banners={this.state.banners} />

                    <div className="trusted-partners-section">
                        <div className="container">
                            <div className="left-block">
                                <div className="heading">{this.state.trusted_partners_title}</div>
                            </div>
                            <div className="right-block">
                                <div className="trusted-partners-listing">
                                    <Carousel ref={node => (this.carousel = node)} {...props}>

                                        {this.state.logos && this.state.logos.length > 0
                                            ?

                                                this.state.logos.map((_logo, index) => {

                                                    return <img key={"logo_" + index} src={_logo.logo} />
                                                })
                                            :
                                                null
                                        }
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="categories-section text-center">
                        <div className="container">
                            <div className="heading">{this.state.category_section_title}</div>

                            <div className="categories-list">
                                {this.renderCategories()}
                            </div>

                            <Button className="btn btn-blue load-more" onClick={() => {

                                this.setState({all_loaded: true})
                            }}>Load More</Button>

                        </div>
                    </div>

                    {this.renderInfoBlocks()}

                    <SPProjectsTabs sp_tab_blocks={this.state.sp_tab_blocks} project_tab_blocks={this.state.project_tab_blocks} products_list={this.state.products_list} />

                    <div className="tabb-totals-section text-center">
                        <div className="container">
                            {this.state.tab_totals && this.state.tab_totals.length > 0
                                ?
                                    <div className="sp-list-block">
                                        {this.renderTabTotals()}
                                        
                                    </div>
                                :
                                    null
                            }
                        </div>
                    </div>

                    <HomeHowWeWork how_work={this.state.how_work} how_work_left_blocks={this.state.how_work_left_blocks} how_work_right_blocks={this.state.how_work_right_blocks} />

                    <HomeMoneyProtection money_protection={this.state.money_protection} money_protection_blocks={this.state.money_protection_blocks} />

                </Main>
            </>
        )
    }
}

export default Homepage;