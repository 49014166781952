import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

// add form components
import { Table, Tabs, Avatar, Typography, Empty, Rate, Switch, Select, Button } from 'antd';

import LinesEllipsis from 'react-lines-ellipsis'

import FeatherIcon from 'feather-icons-react';
import Main from '../../components/Main';

import LayoutHelper from '../../helpers/LayoutHelper';

// import headimg from '../../assets/images/head.jpg';
import dashboardbg from '../../assets/images/dashboard-bg.png';
import tshirtimage from '../../assets/images/blog/t-shirt.jpg';

import ProductsListComponent from './products/ProductsListComponent';


import Api from '../../api/Api';

class Dashboard extends Component { 
    constructor(props) {
        super();
        this.state = {

            loading: false,
            error_message: '',
            success_message: '',

            account_token: false,
            user: false,

            stats: {
                'seller_active_projects': 0,
                'seller_invitations': 0,
                'seller_recommendations': 0,
                'seller_pending_projects': 0,
                'buyer_active_projects': 0,
                'buyer_pending_proposals': 0,
                'seller_escrow': 0,
                'pending_proposals': 0,
                'invoice_pending_payment': 0,
                'invoice_raised': 0,
            },
            messages: [],

            reviews: [],

            full_view: false,

            userTabChange: false,
            userTab: 'seller_tabs',

            active_tab: 'projects',

            projects: [],
            invitations: [],
            pending_proposals: [],
            recommendations: [],

            buyer_projects: []
        }
    }

    componentDidMount = () => {
        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');
        
        if(account_token){
            
            this.setState({account_token: account_token, user: user})
        }else{
            this.setState({redirect: true});
        }

        if(user){
            this.setState({user: JSON.parse(user)});

            this.loadStats(account_token);
        }
    }


    loadStats = (account_token) => {
        
        var formData = new FormData();
        
        formData.append('account_token', account_token);
        
        var that = this;

        Api.post('dashboard/stats', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){
            
                that.setState({stats: data.stats, messages: data.messages, reviews: data.reviews});
            }else{

                // if(data.code == 'no_account'){
                //     window.location = Api.url + '/logout';
                // }
                LayoutHelper.addErrorMessage(that, data.message);
            }
        });
    }

    onUserTabChange(e){
        var isChecked = e;
        if(isChecked){

            this.loadBuyerProjects(this.state.account_token);
            this.setState({userTab: 'buyer_tabs'});
        }else{

            this.loadProjects(this.state.account_token);
            this.setState({userTab: 'seller_tabs'});
        }

        this.setState({userTabChange: true});
    }

    render () {

        const projectTableColumns = [
            {title: 'Buyer/Client', dataIndex: 'employer_name', key: 'employer_name', render: (name, row) => {
                return (
                    <div>
                        <Avatar size={40} src={row.profile_pic} />
                        <span className="ml-5">{name}</span>
                    </div>
                )
            }},
            {title: 'Project', dataIndex: 'title', key: 'title',},
            {title: 'Start Date', dataIndex: 'start_date_formatted', key: 'start_date_formatted',},
            {title: 'Budget', dataIndex: 'budget_formatted', key: 'budget_formatted',},
            {title: 'Action', key: 'action', render: (name, row) => {

                return (

                    <Link to={"/project-flow/" + row.project_id}>Details</Link>
                )
            }},
        ];

        const tableColumns = [
            {
                title: 'Buyer / Client',
                dataIndex: 'buyer',
                key: 'buyer',
            },
            {
                title: 'Project',
                dataIndex: 'project',
                key: 'project',
            },
            {
                title: 'Total',
                dataIndex: 'total',
                key: 'total',
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
            },
        ];

        const pendingProposalsColumns = [
            {title: 'Buyer/Client', dataIndex: 'employer_name', key: 'employer_name', render: (name, row) => {
                return (
                    <div>
                        <Avatar size={40} src={row.profile_pic} />
                        <span className="ml-5">{name}</span>
                    </div>
                )
            }},
            {title: 'Project', dataIndex: 'title', key: 'title',},
            {title: 'Start Date', dataIndex: 'start_date_formatted', key: 'start_date_formatted',},
            {title: 'Budget', dataIndex: 'budget_formatted', key: 'budget_formatted',},
            {title: 'Action', key: 'action', render: (name, row) => {

                return (

                    <Link to={"/project-flow/" + row.project_id}>Details</Link>
                )
            }},
        ];

        const buyerTableColumns = [
            {title: 'Project', dataIndex: 'title', key: 'title', render: (name, row) => {

                return <Link to={"/project-proposals/" + row.project_unq_id}>{row.title}</Link>
            }},
            {title: 'Proposals', align:'center', dataIndex: 'proposals_count', key: 'proposals_count', render: (name, row) => {

                return <Link to={"/project-proposals/" + row.project_unq_id}>{row.proposals_count}</Link>
            }},
            {title: 'Start Date', dataIndex: 'start_date_formatted', key: 'start_date_formatted',},
            {title: 'Budget', dataIndex: 'budget_formatted', key: 'budget_formatted',},
            {title: 'Visibility', dataIndex: 'visibility', key: 'visibility', },
        ];
        
        return (

            <>
                <Main page_title="Dashboard" page="dashboard" error_message={this.state.error_message} success_message={this.state.success_message}>

                    {this.state.user &&
                        <>
                    
                            <div className="banner-block dashboard-bg-img">
                                {/* <img src={this.state.user.profile_banner_full} alt="Banner Image" /> */}
                                <img src={dashboardbg} alt="Banner Image" />
                                <div className="container-lg">
                                    <div className="page-heading">Dashboard</div>

                                    <div className="profile-details">
                                        <div className="user-details">
                                            <div className="profile-img">
                                                <img src={this.state.user.profile_pic_full} className="profile-image" alt="Profile Image" />
                                            </div>
                                            <div className="profile-basic-details">
                                                <div className="name">{this.state.user ? this.state.user.name : ''}</div>
                                                <div className="location"><FeatherIcon icon="map-pin" />{this.state.user ? ((this.state.user.city !== '' ? this.state.user.city + ', ' : '') + this.state.user.country_name) : ''}</div>
                                                <div className="price">${this.state.user ? this.state.user.price_formatted : ''}/{this.state.user ? (this.state.user.price_type == 'd' ? 'Day' : 'Hr') : ''}</div>
                                            </div>
                                        </div>

                                        <div className="section ratings-section">
                                            <div className="block seller">
                                                <span className="num">{this.state.user ? this.state.user.avg_rating : 0}/<span className="">5</span></span>
                                                <span className="text">Seller Rating</span>
                                            </div>
                                            <div className="block buyer">
                                                <span className="num">{this.state.user ? this.state.user.avg_rating : 0}/<span className="">5</span></span>
                                                <span className="text">Buyer Rating</span>
                                            </div>
                                        </div>

                                        <div className="section details-section">
                                            <div className="small-text">Member since {this.state.user.member_since}</div>
                                            <div className="title">{this.state.user.industry_role}</div>
                                            <div className="text">

                                                {this.state.user.description.length > 200
                                                    ?
                                                        <>
                                                            {this.state.full_view
                                                                ?
                                                                    <p>{this.state.user.description}</p>
                                                                :
                                                                    <>
                                                                        <LinesEllipsis
                                                                            text={this.state.user.description}
                                                                            maxLine='2'
                                                                            ellipsis={<div>...<Button onClick={() => {

                                                                                this.setState({full_view: true})
                                                                            }} type="link" style={{padding:'4px 2px'}}>Read more</Button></div>}
                                                                            trimRight
                                                                            basedOn='letters'
                                                                        />
                                                                    </>
                                                            }
                                                        </>
                                                    :
                                                        <p>{this.state.user.description}</p>
                                                }
                                            </div>
                                        </div>

                                        <div className="section links">
                                            <Link to={`/freelancers/${this.state.user.slug}`}>View</Link>
                                            <Link to="/account/profile-update">Edit</Link>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="page-main-content dashboard-page">
                                
                                <div className="main-content d-flex justify-content-between">
                                    <div className="left-block">
                                        <div className="block-title">
                                            <span className="">Inbox</span>
                                        </div>
                                        <ul className="messages-list">
                                            
                                            {this.state.messages && this.state.messages.length > 0 &&

                                                this.state.messages.map((message) => {

                                                    var key = 'message_row_' + message.ref;
                                                    return (
                                                        this.messageRow(key, message)
                                                    )
                                                })
                                            }
                                        </ul>

                                        <div className="text-center mt-2">
                                            <Link to="/messages">View All</Link>
                                        </div>
                                    </div>

                                    <div className="right-block">
                                        <div className="user-switch">
                                            <span className="dull">Seller (We are Freelancer)</span>
                                            <Switch /*defaultChecked*/ size="small" onChange={this.onUserTabChange.bind(this)} />
                                            <span className="colored">Buyer (We are Client/Project Creator)</span>
                                        </div>

                                        {this.state.userTab && (this.state.userTab == 'seller_tabs')
                                            ?
                                                <div className="details-block seller_tabs">
                                                    <div className="left-section">

                                                        <div className="block-heading">At Glance</div>
                                                        <div className="block color-blocks">
                                                            <div className="sm-block">
                                                                <div className="num">{this.state.stats.seller_active_projects}</div>
                                                                <div className="text">Active Projects</div>
                                                            </div>
                                                            <div className="sm-block">
                                                                <div className="num">{this.state.stats.seller_pending_projects}</div>
                                                                <div className="text">Pending Proposals</div>
                                                            </div>
                                                            <div className="sm-block">
                                                                <div className="num">{this.state.stats.seller_recommendations}</div>
                                                                <div className="text">Recommended Projects</div>
                                                            </div>
                                                            <div className="sm-block">
                                                                <div className="num">{this.state.stats.seller_invitations}</div>
                                                                <div className="text">Project Invites</div>
                                                            </div>
                                                        </div>

                                                        <div className="block-heading mt-2">In Escrow / Invoices</div>
                                                        <div className="block escrow-block">
                                                            <div className="sm-block white-block">
                                                                <div className="num">${this.state.stats.seller_escrow}</div>
                                                                <div className="text">Seller Escrow</div>
                                                            </div>
                                                            <div className="sm-block white-block">
                                                                <div className="num">${this.state.stats.pending_proposals}</div>
                                                                <div className="text">Pending Proposals</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="right-section">
                                                        <div className="block-heading">Manage Projects</div>
                                                        <div className="block white-block">
                                                            
                                                            <div>
                                                                
                                                                <Tabs defaultActiveKey="projects" animated={false} onTabClick={(key, event) => {
                                                                    this.setState({active_tab: key});
                                                                    if(key == 'projects'){

                                                                        this.loadProjects(this.state.account_token);
                                                                    }
                                                                    if(key == 'invitations'){

                                                                        this.loadInvitations(this.state.account_token);
                                                                    }
                                                                    if(key == 'pending_proposals'){

                                                                        this.pendingProposals(this.state.account_token);
                                                                    }
                                                                }}>
                                        
                                                                    <Tabs.TabPane tab={
                                                                        <a>
                                                                            <span>Projects</span>
                                                                        </a>
                                                                        } key="projects">
                                                                        <Table columns={projectTableColumns} dataSource={this.state.projects}>
                                                                            <Empty />
                                                                        </Table>
                                                                    </Tabs.TabPane>

                                                                    <Tabs.TabPane tab={
                                                                        <a>
                                                                            <span>Invitations</span>
                                                                        </a>
                                                                        } key="invitations">
                                                                            <Table dataSource={this.state.invitations} columns={projectTableColumns} />
                                                                    </Tabs.TabPane>

                                                                    <Tabs.TabPane tab={
                                                                        <a>
                                                                            <span>Recommendations</span>
                                                                        </a>
                                                                        } key="3">
                                                                        <Table dataSource={this.state.recommendations} columns={tableColumns} />
                                                                    </Tabs.TabPane>

                                                                    <Tabs.TabPane tab={
                                                                        <a>
                                                                            <span>Pending Proposals</span>
                                                                        </a>
                                                                        } key="pending_proposals">
                                                                        <Table dataSource={this.state.pending_proposals} columns={pendingProposalsColumns} />
                                                                    </Tabs.TabPane>

                                                                </Tabs>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            :

                                                <div className="details-block buyer_tabs">
                                                    <div className="left-section">
                                                        <div className="block-heading">At Glance</div>
                                                        <div className="block color-blocks">
                                                            <div className="sm-block">
                                                                <div className="num">{this.state.stats.buyer_active_projects}</div>
                                                                <div className="text">Active Projects</div>
                                                            </div>
                                                            <div className="sm-block">
                                                                <div className="num">{this.state.stats.buyer_pending_proposals}</div>
                                                                <div className="text">Pending Proposals</div>
                                                            </div>
                                                        </div>

                                                        <div className="block-heading mt-2">In Escrow / Invoices</div>
                                                        <div className="block escrow-block">
                                                            <div className="sm-block white-block">
                                                                <div className="num">{this.state.stats.invoice_pending_payment}</div>
                                                                <div className="text">Pending for Payments</div>
                                                            </div>
                                                            <div className="sm-block white-block">
                                                                <div className="num">{this.state.stats.invoice_raised}</div>
                                                                <div className="text">Invoice Raised</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="right-section">
                                                        <div className="block-heading">Manage Projects</div>
                                                        <div className="block white-block">
                                                            
                                                            <div className="tab-heading">All</div>
                                                            <Table columns={buyerTableColumns} dataSource={this.state.buyer_projects}>
                                                                <Empty />
                                                            </Table>
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                        
                                    </div>
                                    
                                </div>



                                <div className="main-content d-flex justify-content-between">
                                    <div className="left-block">
                                        <div className="block-title">
                                            <span className="">Latest Reviews</span>
                                        </div>
                                        <ul className="messages-list reviews-list">
                                            
                                            {this.state.reviews && this.state.reviews.length > 0 &&

                                                this.state.reviews.map((reviews) => {

                                                    var key = 'reviews_row_' + reviews.ref;
                                                    return (
                                                        this.reviewsRow(key, reviews)
                                                    )
                                                })
                                            }
                                        </ul>

                                        <div className="text-center mt-2">
                                            <Link to="/reviews">View All</Link>
                                        </div>
                                    </div>

                                    <div className="right-block">

                                        <div className="details-block">

                                            <div className="full-section">
                                                <div className="block-heading">Manage Product / Offer</div>
                                                <div className="block">
                                                    
                                                    <div>
                                                        
                                                        <ProductsListComponent />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    
                                </div>

                            </div>
                        </>
                    }
                </Main>
            </>
        )
    }

    messageRow = (key, data) => {

        return (
            <li key={key}>
                <div className="img-block">
                    {this.getUserImage(data)}
                </div>
                <Link to={"/messages/" + data.project_id} className="text">
                    <div className="name">
                        <Typography.Paragraph ellipsis={{rows: 1}}>{this.userName(data)}</Typography.Paragraph>
                        <span className="time" style={{width: 100, textAlign:'right'}}>{data.time}</span>
                    </div>
                    {/* <Typography.Paragraph ellipsis={{rows: 1}} className="title">{data.project_name}</Typography.Paragraph> */}
                    <div className="msg">
                        <Typography.Paragraph ellipsis={{rows: 1}}>{data.message}</Typography.Paragraph>
                        {/* <span className="num">+1</span> */}
                    </div>
                </Link>
            </li>
        )
    }


    reviewRow = (key, data) => {

        return (
            <li key={key}>
                <div className="img-block">
                    {this.getUserImage(data)}
                </div>
                <Link to={"/reviews/" + data.project_id} className="text">
                    <div className="name">
                        <Typography.Paragraph ellipsis={{rows: 1}}>{this.userName(data)}</Typography.Paragraph>
                        <Rate tooltips={this.state.rate_tooltips} size={this.state.sizeLarge} value="4" />
                    </div>
                    <div className="msg">
                        <Typography.Paragraph ellipsis={{rows: 1}}>{data.review}</Typography.Paragraph>
                    </div>
                </Link>
            </li>
        )
    }

    product_offer = () => {
        return (
            <div className="single-offer">
                <Link to="" className="top-block">
                    <img src={tshirtimage} className="offer-image" alt="Offer Image" />
                    <span className="featured-text">Featured</span>
                    <span className="wishlist-count">
                        <i className="ico heart"></i> 10
                    </span>
                </Link>
                <div className="mid-block">
                    <div className="title">Create responsive woocommerce website</div>
                    <div className="last-activity">Last activity: 2 years ago</div>
                    <div className="ratings-price">
                        <div className="ratings">
                            <Rate tooltips={this.state.rate_tooltips} size={this.state.sizeLarge} value="3" />
                            <div className="rating-text">3.2 (120)</div>
                        </div>
                        <span className="price-text">
                            <span className="text">Price:</span>
                            <span className="price">$199</span>
                        </span>
                    </div>
                </div>
                <div className="bottom-block">
                    <div className="sold-text">
                        <span className="num">0</span>
                        <span className="text">sold</span>
                    </div>
                    <Select size={this.state.sizeLarge} placeholder="Featured" style={{ width: 120 }}>
                        <Select.Option value="Featured">Featured</Select.Option>
                        <Select.Option value="Resume">Resume</Select.Option>
                    </Select>
                </div>
            </div>
        )
    }

    getUserImage = (user) => {

        if(user.buyer_profile_pic != ''){

            return <Avatar src={user.buyer_profile_pic} size={45} />
        }else{

            return <Avatar src={user.freelancer_profile_pic} />
        }
    }

    userName = (user) => {

        if(user.buyer_name != ''){

            return user.buyer_name;
        }else{

            return user.freelancer_name;
        }
    }

    loadProjects = (account_token, type) => {
        
        var formData = new FormData();
        
        formData.append('account_token', account_token);
        formData.append('status', 'awarded');
        
        var that = this;

        Api.post('seller/projects', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){
            
                if(data.projects.length > 0){

                    that.setState({projects: data.projects, no_data: false});
                }else{

                    that.setState({no_data: true, projects: []});
                }
            }else{

                // if(data.code == 'no_account'){
                //     window.location = Api.url + '/logout';
                // }
                LayoutHelper.addErrorMessage(that, data.message);
            }
        });
    }
    
    loadInvitations = (account_token) => {
        
        var formData = new FormData();
        
        formData.append('account_token', account_token);
        
        var that = this;

        Api.post('seller/invitations', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){
            
                if(data.invitations.length > 0){

                    that.setState({invitations: data.invitations});
                }else{

                    that.setState({invitations: []});
                }
            }else{

                // if(data.code == 'no_account'){
                //     window.location = Api.url + '/logout';
                // }
                LayoutHelper.addErrorMessage(that, data.message);
            }
        });
    }

    pendingProposals = (account_token) => {
        
        var formData = new FormData();
        
        formData.append('account_token', account_token);
        
        var that = this;

        Api.post('seller/pending_proposals', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){
            
                if(data.projects.length > 0){

                    that.setState({pending_proposals: data.projects, no_data: false});
                }else{

                    that.setState({no_data: true, pending_proposals: []});
                }
            }else{

                // if(data.code == 'no_account'){
                //     window.location = Api.url + '/logout';
                // }
                LayoutHelper.addErrorMessage(that, data.message);
            }
        });
    }

    loadBuyerProjects = (account_token, type) => {
        
        var formData = new FormData();
        
        formData.append('account_token', account_token);
        formData.append('status', type);
        
        var that = this;

        Api.post('buyer/projects', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){
            
                if(data.projects.length > 0){

                    that.setState({buyer_projects: data.projects, no_data: false});
                }else{

                    that.setState({no_data: true, buyer_projects: []});
                }
            }else{

                // if(data.code == 'no_account'){
                //     window.location = Api.url + '/logout';
                // }
                LayoutHelper.addErrorMessage(that, data.message);
            }
        });
    }
}

export default Dashboard;