import React, { Component } from 'react';

import { Link } from "react-router-dom";

import { Rate } from 'antd';

class FreelancersBannerSection extends Component { 
    constructor(props) {
        super();
        this.state = {
            sizeLarge: 'large',
            rate_tooltips: ['terrible', 'bad', 'normal', 'good', 'wonderful']
        }
    }

    render () {

        return (

            <div>
                <div className="banner-block profile-bg-img">

                    {this.props.freelancer.profile_banner_full != '' &&
                
                        <div className="profile-banner" style={{backgroundImage:'url('+this.props.freelancer.profile_banner_full+')'}} />
                    }
                    <div className="container">
                        <div className="profile-details">
                            <div className="profile-img">
                                <img src={this.props.freelancer.profile_pic_full} className="profile-image" alt="Profile Image" />
                            </div>
                            <div className="profile-basic-details">
                                <div className="name">{this.props.freelancer.name} {this.props.freelancer.status && (this.props.freelancer.status == 'Active')? <span className="verified-text"><i className="ico verified"></i> </span>: null } 
                                    {this.props.edit_link
                                        ?
                                            <Link to="/account/profile-update">Edit Profile</Link>
                                        :
                                            null
                                    }
                                </div>
                                
                                <div className="location"><i className="mat-ico">location_on</i>{this.props.freelancer.city}, {this.props.freelancer.country_name}</div>
                                <div className="ratings-reviews">
                                    <Rate tooltips={this.state.rate_tooltips} size={this.state.sizeLarge} value={this.props.freelancer.ratings_count} />
                                    <div className="rating-text">{this.props.freelancer.ratings_count} ({this.props.freelancer.reviews_count})</div>
                                    <Link to="" className="add-review">Review Me</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FreelancersBannerSection;