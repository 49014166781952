import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { Form, Input, InputNumber, Button, Radio, Rate, Checkbox, DatePicker, Tabs } from 'antd';

import paymentmethodimg from '../../assets/images/payment-method.png';

import FeatherIcon from 'feather-icons-react';

import Main from '../../components/Main';

class PostProjectStepTwo extends Component { 
    constructor(props) {
        super();
        this.state = {

            options_text: {
                feature: 'Feature your project',
                urgent: 'Mark your project as Urgent',
                nda: 'Request for NDA (Non disclosure aggrement)',
                scouting: 'Let us do the scouting for you',
            },

            costings: {
                feature: 20,
                urgent: 20,
                nda: 34,
                scouting: 15
            },
        }
    }

    onFinish = (values) => {
        return false;
    }

    calculateSelected = () => {
        
        var costings = this.state.costings;
        var selected_options = this.props.selected_options;

        var _total = 0;

        for(var n in selected_options){

            var _key = (selected_options[n]);
            _total = parseInt(_total) + parseInt(costings[_key]);
        }

        return _total;
    }

    renderSelected = () => {

        var selected_options = this.state.options_text;
        var costings = this.state.costings;

        return this.props.selected_options.map((key) => {

            var _key = "select_option_" + key;
            var text = selected_options[key];
            var cost = costings[key];

            return (
                <div key={_key} className="d-flex">
                    <div className="text">{text}</div>
                    <div className="text num">${cost}</div>
                </div>
            )
        });
    }

    render () {

        return (

            <div className="mt-3 card">

                <div className="justify-center" style={{width:'100%'}}>
                    <div className="left-block" style={{width:'45%'}}>
                        <div className="page-heading">Upgrade your project</div>
                        <form className="" onFinish={this.onFinish.bind(this)}>
                            <div className="payment-blocks">
                                <div className="d-flex form-item-block border-block">
                                    <Form.Item name="paytm">
                                        <label>
                                            <Checkbox /><img className="img-fluid" src={paymentmethodimg} alt="Payment Method" />
                                        </label>
                                    </Form.Item>
                                </div>
                            </div>
                            
                        </form>
                    </div>
                    <div className="right-block extras-payment-block" style={{width:'45%'}}>
                        <div className="summary-block border-bottom">
                            <div className="d-flex">
                                <div className="text bold">Summary</div>
                                <div className="text bold">${this.calculateSelected()}</div>
                            </div>
                        </div>
                        <div className="project-block border-bottom">

                            {this.renderSelected()}
                        </div>

                        <div className="price-block border-bottom">
                            <div className="d-flex">
                                <div className="text">Items Total</div>
                                <div className="text">${this.calculateSelected()}</div>
                            </div>
                            <div className="d-flex">
                                <div className="text">Discount</div>
                                <div className="text">-</div>
                            </div>
                            <div className="d-flex">
                                <div className="text">Promo Code <FeatherIcon icon="arrow-right" /></div>
                                <div className="text"></div>
                            </div>
                        </div>
                        <div className="total-block">
                            <div className="d-flex">
                                <div className="text bold">Total</div>
                                <div className="text bold">${this.calculateSelected()}</div>
                            </div>
                        </div>
                    </div>
                </div>  
                <div className="justify-center" style={{width:'100%', marginTop:40}}>
                    <div className="d-flex form-item-block agree-block" style={{flexDirection:'column'}}>
                        <Form.Item name="tnc">
                            <Checkbox>I agree to the <Link className="link" to='/'>terms and conditions</Link>, <Link className="link" to='/'>refund and cancelation policy</Link>, <Link className="link" to='/'>Fees</Link></Checkbox>
                        </Form.Item>
                        <div className="">
                            <FeatherIcon icon="lock" />
                            <div className="text">
                                <div className="top">Secure Payment</div>
                                <div className="bottom">Your information is protected by 256-bit SSL encryptions.</div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between form-end">
                        
                        <Button type="secondary" onClick={() => {
                            this.props.goBack(this.props.selected_options)
                        }} className="btn btn-xs">Back</Button>
                        <Button type="primary" onClick={() => {
                            this.props.onClick()
                        }} className="btn ml-10">Pay Now</Button>
                    </div>
                </div>  
            </div>
        )
    }
}

export default PostProjectStepTwo;