import React, { Component } from 'react';

import { Tabs } from 'antd';

import BannerSlider from '../../components/sections/common/BannerSlider';
import CategoryBlock from '../../components/sections/category/CategoryBlock';
import SPProjectsTabs from '../../components/sections/common/SPProjectsTabs';

import Api from '../../api/Api';

import Main from '../../components/Main';

class CategoryListingPage extends Component { 
    constructor(props) {
        super();
        this.state = {
            banners: [],

            child_categories: [],
            related_categories: [],

            sp_tab_blocks: [],
            project_tab_blocks: [],
            products_list: [],
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        this.setState({account_token: account_token});

        this.loadData();
    }

    renderChildCategoryTabs = () => {

        const ccategoriesList = [];
        for(let i = 0; i < this.state.child_categories.length; i++) {
            let key = 'cc_'+i;

            ccategoriesList.push(
                <Tabs.TabPane tab={<div><img src={this.state.child_categories[i].thumb} className="img-responsive" alt={this.state.child_categories[i].name} /><div>{this.state.child_categories[i].name}</div></div>} key={key}>
                    <div className="heading">{this.state.child_categories[i].name}</div>
                    <div className="block" dangerouslySetInnerHTML={{ __html: this.state.child_categories[i].description }} />
                </Tabs.TabPane>
            );
        }
        return ccategoriesList;
    }

    renderRelatedCategories = () => {

        const categoriesList = [];
        for(let i = 0; i < this.state.related_categories.length; i++) {
            let key = 'rc_'+i;

            categoriesList.push(
                <CategoryBlock key={key} category={this.state.related_categories[i]} />
            );
        }
        return categoriesList;
    }

    render () {

        return (

            <Main full_content={true}>
                <div className="inner-page white-bg pt-0">

                    <BannerSlider banners={this.state.banners} />

                    <div className="child-category-section">
                        <div className="container">
                            <Tabs defaultActiveKey="cc_1" centered>
                                {this.renderChildCategoryTabs()}
                            </Tabs>
                        </div>
                    </div>

                    <div className="categories-section related text-center">
                        <div className="container">
                            <div className="heading">{this.state.related_section_title}</div>

                            <div className="categories-list">
                                {this.renderRelatedCategories()}
                            </div>

                        </div>
                    </div>

                    <SPProjectsTabs sp_tab_blocks={this.state.sp_tab_blocks} project_tab_blocks={this.state.project_tab_blocks} products_list={this.state.products_list} />
                </div>
            </Main>
            
        )
    }


    loadData = () => {

        const formData = new FormData();
        var that = this;

        formData.append('parent', this.props.cat_slug);

        Api.post('categories/top_single', formData, function(data){
            
            that.setState({

                banners: data.banners,

                child_categories: data.child_categories,

                related_section_title: data.related_section_title,
                related_categories: data.related_categories,

                sp_tab_blocks: data.sp_tab_blocks,
                project_tab_blocks: data.project_tab_blocks,
                products_list: data.products_list
            });
        });
    }
}

export default CategoryListingPage;