import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { connect } from 'react-redux';
import {User} from '../actions/user';

import { Layout, Menu, Select, Avatar, Image } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import logo from '../assets/images/logo.png';

const { SubMenu } = Menu;

class AppHeader extends Component { 
    constructor(props) {
        super();
        this.state = {
            login: false,
            user: false,

            update_profile: false,

            url: 'freelancers-search',

            searched: false,
            keyword: '',

            redirect_to_account: false,
        }
    }

    componentDidMount = () => {
        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');
        
        if(account_token){

            var _user = JSON.parse(user);
            this.setState({user: _user});

            if(this.props.keyword){
                this.setState({keyword: this.props.keyword});
            }

            this.props.User(_user);

            if(_user.profile_progress < 40){

                var page = this.props.page;

                if(page && (page != 'account_update_profile' && page != 'account_profile_view')){

                    if(page.indexOf('account') > -1){

                        this.setState({update_profile: true});
                    }
                }
            }

            this.setState({login: true});
        }
    }

    renderChangeBackground = (e) => {
        e.target.style.background = 'red';
    }

    renderCategories = () => {

        if(this.props.categories && this.props.categories.length){

            return (
                <div className="sub-menu-wrapper">
                    <ul className="sub-menu">
                        {this.props.categories.map((_category) => {

                            return (
                                <li key={"menu_category_" + _category.id} className="nav-link">
                                    <Link to={`/category/${_category.slug}`}>{_category.name}</Link>

                                    {/* {(_category.childs && _category.childs.length > 0) &&
                                    
                                        <ul className="sub-menu-2">

                                            {_category.childs.map((_sub_category) => {

                                                return (
                                                    <li key={"sub_category_" + _sub_category.id} className="nav-link">
                                                        <Link to={`/category/${_sub_category.slug}`}>{_sub_category.name}</Link>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    } */}
                                </li>
                            )
                        })}
                    </ul>
                </div>
            )
        }
    }

    profileText = () => {

        if(this.props.user){

            if(this.props.user.profile_pic_full === ''){

                return <Avatar src={<Image preview={false} src={this.props.user.profile_pic_full} style={{ width: 32 }} />} />
            }else{

                const name = this.props.user.name;

                let username = name.substring(0, 1);

                if(name.indexOf(" ") > -1){

                    const names = name.split(" ");
                    username = names[0].substring(0, 1);
                    username += names[1].substring(0, 1);
                }
                
                return <Avatar style={{ color: '#ee7d22', backgroundColor: '#ffe9d8' }}><strong>{username}</strong></Avatar>
            }
        }else{

            return <Avatar icon={<UserOutlined />} />
        }
    }

    render () {

        if(this.state.redirect_to_account){
            return <Redirect to='/account'/>;
        }

        if(this.state.update_profile){
            return <Redirect to='/account/profile-update'/>;
        }

        if(this.state.searched){

            if(this.state.keyword && (this.state.keyword != '')){
                return <Redirect to={"/header-search-router/" + this.state.url + "/" + this.state.keyword} />;
            }else{
                return <Redirect to={"/header-search-router/" + this.state.url} />;
            }
        }

        return (

            <Layout.Header>
                <div className="header">
                    <div className="container-lg">
                        <div className="header-block">
                            <div className="first-block">
                                <div className="navigation">
                                    <div className="nav-lines">
                                        <div className="line"></div>
                                        <div className="line"></div>
                                        <div className="line"></div>
                                    </div>
                                    <div className="mega-menu">
                                        <div className="bg-white">
                                            <ul className="menu">
                                                <li className="nav-link">
                                                    <Link to="#">Products & Services</Link>

                                                    {this.renderCategories()}
                                                </li>
                                                <li className="nav-link">
                                                    <Link to="/projects-search">Browse Projects</Link>

                                                    {this.renderCategories()}
                                                </li>
                                                <li className="nav-link">
                                                    <Link to="/freelancers-search">Find A Service Provider</Link>

                                                    {this.renderCategories()}
                                                </li>
                                                <li className="nav-link">
                                                    <Link to="#">Talent Sourcing</Link>
                                                </li>
                                                <li className="nav-link">
                                                    <Link to="/about">About Us</Link>
                                                </li>
                                                <li className="nav-link">
                                                    <Link to="/how-we-work">How It Works</Link>
                                                </li>
                                                <li className="nav-link">
                                                    <Link to="/prices">Pricing</Link>
                                                </li>
                                                <li className="nav-link">
                                                    <Link to="/blog">Blog</Link>
                                                </li>
                                                <li className="nav-link">
                                                    <Link to="/our-story">Our Story</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <Link to="/"><img className="logo" src={logo} alt="Logo" /></Link>
                            </div>

                            <div className="second-block">
                                <form onSubmit={this.submitSearch.bind(this)} className="navbar-form srch-frm" method="get">
                                    <span className="block">
                                        <input onChange={(e) => {
                                            this.setState({keyword: e.target.value, searched: false})
                                        }} type="text" value={this.state.keyword} placeholder="I'm looking For" />
                                    </span>
                                    <span className="block">
                                        <Select onChange={(e) => {
                                            
                                            this.setState({url: e})
                                        }} className="form-control" name="search" defaultValue="freelancers-search" size={this.state.sizeLarge} style={{ width: 200 }}>
                                            <Select.Option value="freelancers-search">Service Provider</Select.Option>
                                            <Select.Option value="projects-search">Search Jobs</Select.Option>
                                            <Select.Option value="products-search">Search Products</Select.Option>
                                        </Select>
                                    </span>
                                    <button className="icon">
                                        <i className="mat-ico">search</i>
                                    </button>
                                </form>
                            </div>

                            <div className="third-block">
                                <div className="links">
                                    
                                    {this.state.login
                                        ?
                                            null
                                        :
                                            <Link to="/login" className="btn">Log-in</Link>
                                    }

                                    {this.state.login
                                        ?
                                            <>
                                                <Menu selectedKeys={[this.props.page]} mode="horizontal">

                                                    <Menu.Item key="post_project">
                                                        <Link className="btn btn-fill" to="/account/post-project">Post Project</Link>
                                                    </Menu.Item>
                                                    
                                                    <Menu.SubMenu key="dashboard" title={this.profileText()}>
                                                        <Menu.Item key="account">
                                                            <Link to="/account">View Profile</Link>
                                                        </Menu.Item>
                                                        <Menu.Item key="profile_update">
                                                            <Link to="/account/profile-update">Update Profile</Link>
                                                        </Menu.Item>
                                                        <Menu.SubMenu key="projects" title="Projects">
                                                            <Menu.Item key="as_buyer">
                                                                <Link to="/projects-as-buyer">As Buyer</Link>
                                                            </Menu.Item>
                                                            <Menu.Item key="as_seller">
                                                                <Link to="/projects-as-seller">As Seller</Link>
                                                            </Menu.Item>
                                                        </Menu.SubMenu>
                                                        <Menu.Item key="products">
                                                            <Link to="/account/products">Products</Link>
                                                        </Menu.Item>
                                                        <Menu.Item key="orders">
                                                            <Link to="/project-view">Orders</Link>
                                                        </Menu.Item>
                                                        <Menu.Item key="earnings">
                                                            <Link to="/earnings">Earnings</Link>
                                                        </Menu.Item>
                                                        <Menu.Item key="logout">
                                                            <Link to="/logout">Logout</Link>
                                                        </Menu.Item>
                                                    </Menu.SubMenu>
                                                </Menu>
                                            </>    
                                        :
                                            <Link to="/get-started" className="btn btn-fill">Sign-up</Link>
                                    }

                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </Layout.Header>
        )
    }

    submitSearch = async(event) => {

        event.preventDefault();

        this.setState({searched: true});
    }
}

const mapStateToProps = state => {
	return {
        user: state.user.user
	}
}

export default connect(mapStateToProps, { User } )(AppHeader);