import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

// add form components
import { Form, Input, Button, Checkbox, Alert } from 'antd';

// import FacebookLogin from 'react-facebook-login';

import FeatherIcon from 'feather-icons-react';
import Api from '../../api/Api';

import Loader from '../../components/Loader';

class LoginBlock extends Component { 
    constructor(props) {
        super();
        this.state = {
            loading: false,
            login: false,

            error_message: '',
            success_message: '',

            fb_login: false,
        }
    }

    componentDidMount = () => {
        var account_token = localStorage.getItem('account_token');
        
        // if(account_token){
        //     window.location.href = Api.server_url + "app/landing/" + account_token;
        // }
    }
    
    onFinish = (values) => {
        if(this.state.fb_login){
            return false;
        }

        var formData = new FormData();
        Object.keys(values).forEach(key => formData.append(key, values[key]))
        
        var that = this;

        this.setState({loading: true});

        Api.post('login', formData, function(data){
            
            that.setState({loading: false});

            if(data.status == true){
            
                localStorage.setItem('account_token', data.account_token);
                localStorage.setItem('user', JSON.stringify(data.user));

                that.props.onSuccess(data.account_token);
            
            }else{
                
                if(data.code){
                
                    that.props.onError(data.message, data.code, data.user_code);
                }else{

                    that.props.onError(data.message);
                }
            }
        });
    }

    socialLogin = (response) => {

        if(response.hasOwnProperty('userID') && response.hasOwnProperty('email')){

            var formData = new FormData();

            if(response.hasOwnProperty('picture') && response.picture.hasOwnProperty('data') && response.picture.data.hasOwnProperty('url')){

                formData.append("fb_profile_picture", response.picture.data.url);
            }
            
            formData.append("user_id", response.userID);
            formData.append("email", response.email);
            
            var that = this;

            this.setState({fb_login: true});

            Api.post('login_social', formData, function(data){
                
                if(data.status == true){
                
                    localStorage.setItem('account_token', data.account_token);
                    localStorage.setItem('user', JSON.stringify(data.user));

                    that.props.onSuccess(data.account_token);
                
                }else{

                    that.setState({fb_login: false});
                    
                    if(data.code){
                    
                        that.props.onError(data.message, data.code, data.user_code);
                    }else{

                        that.props.onError(data.message, false, false);
                    }
                }
            });
        }else{

            this.props.onError("FB authentication failed! Please try again after sometime.");
        }
    }

    render () {

        const layout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
        };

        const tailLayout = {
            wrapperCol: { span: 24 },
        };

        const validateMessages = {
            required: '${label} is required!',
            types: {
              email: '${label} is not valid!',
              number: '${label} is not a valid number!',
            },
            number: {
              range: '${label} must be between ${min} and ${max}',
            },
        };

        return (

            <>
                <Form {...layout} name="login_form" initialValues={{ remember: true }} validateMessages={validateMessages} onFinish={this.onFinish.bind(this)}>
                                
                    <Form.Item label="Email Address" name="email" rules={[{required:true}, {type: 'email'}]}>
                        <Input />
                    </Form.Item>
                    
                    <Form.Item label="Password" name="password" rules={[{required:true, message:'Please input your password!'}]}>
                        <Input.Password />
                    </Form.Item>
                    
                    <div className="remember-forgot d-flex justify-content-between">
                        <Form.Item {...tailLayout} name="remember" valuePropName="checked">
                            <Checkbox>Remember me</Checkbox>
                        </Form.Item>
                        <Link to="/forgot-password">Forgot Password</Link>
                    </div>
                    
                    <div className="text-center mt-2">
                        <Button type="danger" htmlType="submit" loading={this.state.loading}>Login</Button>
                    </div>
                </Form>

                <div className="text-center signup-link mt-2">
                    Don't have an account? <Link to="/get-started" className="pink-txt">Sign up</Link>
                </div>

                <div className="text-center">
                    <span className="or-link"><span>Or</span></span>
                </div>

                <div className="social-logins">
                    <div className="text">Welcome back, to keep connected with us please <span className="pink-txt">Login</span> to your account</div>
                    {/* <div className="buttons d-flex">

                        {this.state.fb_login
                            ?
                                <div style={{position:'relative', width:200, height:50}}>
                                    <Loader is_small={true} />
                                </div>
                            :
                                <FacebookLogin
                                    appId="348923879499164"
                                    autoLoad={false}
                                    fields="name,email,picture"
                                    scope="public_profile"
                                    returnScopes={true}
                                    callback={(response) => {
                                        this.socialLogin(response)
                                    }}
                                    onFailure={(response) => {
                                        this.props.onError("FB Login failed! Please try again after sometime.");
                                    }}
                                    cssClass="fb-login-button"
                                    icon={<FeatherIcon icon="facebook" />}
                                />
                        }
                        <button className="btn btn-linked-in"><FeatherIcon icon="linkedin" />Login with Linkedin</button>
                    </div> */}
                </div>
            </>
        )
    }
}

export default LoginBlock;