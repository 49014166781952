import React, { Component } from 'react';

import { Row, Col, Divider, Form, Select, Input, Tag, List, Button, Pagination, Checkbox, Switch, Slider } from 'antd';

import FeatherIcon from 'feather-icons-react';

import Main from '../../components/Main';
import Api from '../../api/Api';
// import ProductBlock from '../../components/products/ProductBlock';
import SingleProduct from '../../components/products/FreelancerProductBlock';

import bannerbgimage from '../../assets/images/projects-bg.jpg';

class ProductsSearchPage extends Component { 
    constructor(props) {
        super();
        this.state = {

            user: false,
            account_token: false,

            loading: false,
            current_page: 1,

            products: [],
            total: 0,
            per_page: 0,
            total_pages:0,

            search_keyword: '',
            search_category: '',

            checkedCategories: [],
            checkedDeliveryTimes: [],
            checkedRatings: [],
            checkedTypes: [],
            price_start_range: 0,
            price_end_range: 100000,

            categories: [],
            delivery_times: [1,2,3,4,5,6,7,8,9,10],
            ratings: [{key:1, value:'1 Star'}, {key:2, value:'2 Star'}, {key:3, value:'3 Star'}, {key:4, value:'4 Star'}, {key:5, value:'5 Star'}],
            product_types: [{key:'featured', value:'Featured'}, {key:'trending', value:'Trending'}, {key:'top_sales', value: 'Top Sales'}],
        }
    }

    componentDidMount = () => {

        var user = localStorage.getItem('user');
        var account_token = localStorage.getItem('account_token');
        
        if(account_token){

            this.setState({account_token: account_token});
        }

        if(user){
            user = JSON.parse(user);
            this.setState({user: user});
        }

        this.loadData();

        if(this.props.keyword){
            this.setState({
                search_keyword: this.props.keyword
            }, () => {
                this.loadProducts(this.state.current_page, false, this.props.keyword);
            });
        }else{
            this.loadProducts(this.state.current_page, false, false);
        }
    }

    loadData = () => {

        var formData = new FormData();
        formData.append('slug', 'products');

        var that = this;

        Api.post('general/initial', formData, function(data){
            
            that.setState({
                categories: data.categories,
            });

        });
    }

    loadProducts = (page, values, keyword, category) => {

        var formData = new FormData();
        formData.append('page', page);

        var account_token = localStorage.getItem('account_token');
        if(account_token){
            formData.append('account_token', account_token);
        }

        if(keyword){
            formData.append('keyword', keyword);
        }else if(this.props.keyword){
            formData.append('keyword', this.props.keyword);
        }

        if(category){
            formData.append('category_id', category);
        }

        if(values){
            Object.keys(values).forEach((key) => {

                if(values[key]){
                    formData.append(key, values[key]);
                }
            });
        }

        if(this.state.checkedCategories.length > 0){
            formData.append('category_ids', this.state.checkedCategories);
        }

        if(this.state.checkedRatings.length > 0){
            formData.append('ratings', this.state.checkedRatings);
        }

        if(this.state.checkedDeliveryTimes.length > 0){
            formData.append('delivery_times', this.state.checkedDeliveryTimes);
        }

        if(this.state.checkedTypes.length > 0){
            formData.append('types', this.state.checkedTypes);
        }

        if(this.state.price_start_range != ''){
            formData.append('price_start_range', this.state.price_start_range);
        }

        if(this.state.price_end_range != '' && this.state.price_end_range != '100000'){
            formData.append('price_end_range', this.state.price_end_range);
        }

        var that = this;
        this.setState({loading: true});

        Api.post('products/listing', formData, function(data){
            
            that.setState({loading: false});

            that.setState({
                products: data.results,
                total: data.total,
                per_page: data.per_page,
                current_page: data.page
            });
        });
        that.setState({loading: false});
    }

    applyFilters = (values) => {

        this.loadProducts(1, values, this.state.search_keyword, this.state.search_category);
    }

    submitSearch = (values) => {

        this.setState({current_page: 1});
        this.loadProducts(1, false, this.state.search_keyword, this.state.search_category);
    }

    filterContainer = () => {

        return (

            <div className="filters">

                <div className="header">
                    <div className="main-title">Filter by</div>
                </div>
                <div className="body">

                    <Form ref="filter_form_ref" labelCol={{span: 24}} wrapperCol={{span: 24}} name="products_filter" onFinish={this.applyFilters.bind(this)}>

                        <div className="filter-heading">Category</div>
                        <div className="filter-list category">
                            <div className="single">
                                {this.state.categories && this.state.categories.length &&

                                    this.state.categories.map((category_item) => {

                                        var key = 'category_item_' + category_item.id;
                                        return (
                                            <Checkbox key={key} value={category_item.id} /*checked={this.state.checkedCategories.get(category_item.id)}*/ onChange={this.onFilterCategoriesChange.bind(this)}>
                                                {category_item.name}
                                            </Checkbox>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        <div className="filter-heading">Ratings</div>
                        <div className="filter-list ratings">
                            <div className="single">
                                {this.state.ratings && this.state.ratings.length &&

                                    this.state.ratings.map((rating_item) => {

                                        var key = 'rating_item_' + rating_item.key;
                                        return (
                                            <Checkbox key={key} value={rating_item.key} /*checked={this.state.checkedCategories.get(rating_item.id)}*/ onChange={this.onFilterRatingsChange.bind(this)}>
                                                {rating_item.value}
                                            </Checkbox>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        <div className="filter-heading">Delivery Time</div>
                        <div className="filter-list domains">
                            <div className="single">
                                {this.state.delivery_times && this.state.delivery_times.length &&

                                    this.state.delivery_times.map((dt, index) => {

                                        var key = 'dt_' + dt;
                                        if(index == 0){
                                            var value = 'Within ' + dt + ' Day';
                                        }else{
                                            var value = 'Within ' + dt + ' Days';
                                        }
                                        
                                        return (
                                            <Checkbox key={key} value={dt} onChange={this.onFilterDeliveryTimesChange.bind(this)}>
                                                {value}
                                            </Checkbox>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        <div className="filter-heading">Type</div>
                        <div className="filter-list type">
                            <div className="single">
                                {
                                this.state.product_types && this.state.product_types.length &&
                                    
                                    this.state.product_types.map((type_item) => {

                                        var key = 'type_item_' + type_item.key;
                                        return (
                                            <Checkbox key={key} value={type_item.key} onChange={this.onFilterTypesChange.bind(this)}>
                                                {type_item.value}
                                            </Checkbox>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        <Divider>Or set a range:</Divider>
                        <div className="filter-row slider">
                            <Form.Item label="" name="price_range" rules={[{required:false}]}>
                                <Slider
                                    range
                                    defaultValue={[0, 100000]}
                                    tooltipVisible={true}
                                    tooltipPlacement="left"
                                    min={1}
                                    max={100000}
                                    marks={{
                                        1: '1',
                                        100000: '100000+'
                                    }}
                                    onChange={this.onPriceRangeChange.bind(this)}
                                />
                            </Form.Item>
                        </div>

                        <div className="filter-row mt-1">
                            <div className="d-flex justify-content-between">
                                <Form.Item>
                                    <Button className="btn purple solid btn-sm" type="submit" htmlType="submit" onClick={this.applyFilters.bind(this)}>Filter</Button>
                                </Form.Item>
                                <Form.Item>
                                    <Button className="btn outlined purple btn-sm" type="button" htmlType="button" onClick={() => {
                                        this.refs.filter_form_ref.resetFields();
                                    }}>Reset</Button>
                                </Form.Item>
                            </div>
                        </div>
                        
                    </Form>
                </div>
            </div>
        )
    }

    onCategoryChange(value) {
    
        this.setState({
            search_category: value
        });
    }

    onKeywordChange(value) {
    
        this.setState({
            search_keyword: value
        });
    }

    onFilterCategoriesChange(e){
        let newArray = [...this.state.checkedCategories, e.target.value];
        if (this.state.checkedCategories.includes(e.target.value)) {
            newArray = newArray.filter(_category => _category !== e.target.value);
        } 
        this.setState({
            checkedCategories: newArray
        });

        this.refs.filter_form_ref.submit();
    }

    onFilterRatingsChange(e){
        let newArray = [...this.state.checkedRatings, e.target.value];
        if (this.state.checkedRatings.includes(e.target.value)) {
            newArray = newArray.filter(_rating => _rating !== e.target.value);
        } 
        this.setState({
            checkedRatings: newArray
        });

        this.refs.filter_form_ref.submit();
    }

    onFilterDeliveryTimesChange(e){
        let newArray = [...this.state.checkedDeliveryTimes, e.target.value];
        if (this.state.checkedDeliveryTimes.includes(e.target.value)) {
            newArray = newArray.filter(_category => _category !== e.target.value);
        } 
        this.setState({
            checkedDeliveryTimes: newArray
        });

        this.refs.filter_form_ref.submit();
    }

    onFilterTypesChange(e){
        let newArray = [...this.state.checkedTypes, e.target.value];
        if (this.state.checkedTypes.includes(e.target.value)) {
            newArray = newArray.filter(_category => _category !== e.target.value);
        } 
        this.setState({
            checkedTypes: newArray
        });

        this.refs.filter_form_ref.submit();
    }

    onPriceRangeChange(e){
        this.setState({price_start_range: e[0]});
        this.setState({price_end_range: e[1]});

        this.refs.filter_form_ref.submit();
    }

    render () {

        return (

            <Main page_title="ProductsSearchPage" hide_page_title={true} full_content={true} keyword={this.props.keyword}>
                <div className="products-search-section inner-page pt-0 listing-page">
                    <div className="banner-block orange-bg-img">
                        <img src={bannerbgimage} alt="Banner Image" />
                        <div className="container">
                            <div className="heading">Products and Offers</div>
                        </div>
                    </div>

                    <div className="container">

                        <Form onFinish={this.submitSearch.bind(this)} className="search-frm">
                            <div className="search-block">
                                <Row className="search-row" gutter={16}>
                                    <Col className="gutter-row" span={6}>
                                        <Select className="filter-dropdown" name="category_id" placeholder="Category" onChange={this.onCategoryChange.bind(this)}>
                                            <Select.Option value="">Category</Select.Option>
                                            {this.state.categories && this.state.categories &&

                                                this.state.categories.map((category_item) => {

                                                    var key = 'category_item_' + category_item.id;
                                                    return (
                                                        <Select.Option key={key} value={category_item.id}>{category_item.name}</Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Col>
                                    <Col className="gutter-row" span={18}>
                                        <input name="search" placeholder="Search for products" value={this.state.search_keyword} onChange={(t) => {
                                            this.setState({search_keyword: t.target.value});
                                            this.onKeywordChange.bind(this);
                                        }} />

                                        <button type="button" className="search-button" onClick={this.submitSearch.bind(this)}>
                                            <FeatherIcon icon="arrow-right" className="search-btn-icon" />
                                        </button>
                                    </Col>
                                </Row>
                                
                            </div>
                        </Form>
                    </div>

                    <div className="container">

                        <Row gutter={16}>
                            <Col className="gutter-row" span={17}>
                                <div className="listing-section white-bg">
                                    <div className="top-filters d-flex justify-content-between">
                                        <div className="search-count">{this.state.total} Products Found</div>

                                        <Select size={this.state.sizeLarge} placeholder="Sort By" style={{ width: 150 }}>
                                            <Select.Option value="">Sort By</Select.Option>
                                            <Select.Option value="Latest">Latest</Select.Option>
                                            <Select.Option value="Ending Soon">Ending Soon</Select.Option>
                                        </Select>
                                    </div>

                                    <div className="listing products_offers">
                                        
                                        <List
                                            // header={this.header()}
                                            className="products-list"
                                            itemLayout="horizontal"
                                            size="large"
                                            loading={this.state.loading}
                                            pagination={{
                                                onChange: page => {
                                                    // this.props.onLoad();
                                                    window.setTimeout(() => {
                                                        this.loadProducts(page, false, false);
                                                    }, 200);
                                                },
                                                pageSize: this.state.per_page,
                                                total: this.state.total,
                                                size: "small",
                                                // itemRender: this.pagingItemRender
                                            }}
                                            dataSource={this.state.products}
                                            renderItem={item => (
                                                // <ProductBlock
                                                //     product={item}
                                                //     user={this.state.user}
                                                // />
                                                <SingleProduct product={item} type="front" user={this.state.user} />
                                            )}
                                        />

                                    </div>

                                </div>
                            </Col>

                            <Col className="gutter-row" span={7}>
                                <div className="right-section white-bg">

                                    {this.filterContainer()}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                
            </Main>
            
        )
    }
}

export default ProductsSearchPage;