import React, { Component } from 'react';

import { Rate } from 'antd';

class SingleReview extends Component { 
    constructor(props) {
        super();
        this.state = {
            sizeSmall: 'small',
            sizeLarge: 'large',
        }
    }
    

    render () {

        var review = this.props.review;
        var replies = this.props.review.replies;

        if(review){
            return (
                <>
                    <div className="block question">
                        <div className="img-block">
                            <img src={review.profile_pic_full} className="profile-image" alt="Profile Image" />
                        </div>
                        <div className="text-block">
                            <div className="name">
                                <div className="text">{review.freelancer_name}</div>
                                <div className="ratings-reviews">
                                    <Rate disabled tooltips={review.rate_tooltips} size="large" value={review.reviews_rating_round} />
                                    <div className="rating-text">{review.reviews_rating} ({review.reviews_count})</div>
                                </div>
                            </div>
                            <div className="location">{review.freelancer_city}, {review.freelancer_country}</div>
                            <div className="text">{review.review}</div>
                        </div>
                        <div className="added-block">
                            <span>
                                <i className="ico clock"></i>
                                <span className="num">{review.added_on_formatted}</span>
                            </span>
                        </div>
                    </div>
                    

                    {replies && replies.length > 0
                        ?
                            <>
                                {replies.map((reply, index) => {
                                        var key = 'rep_' + index;

                                        return (
                                            <div key={key} className="block question reply">
                                                <div className="img-block">
                                                    <img src={reply.profile_pic_full} className="profile-image" alt="Profile Image" />
                                                </div>
                                                <div className="text-block">
                                                    <div className="name">
                                                        <div className="text">{reply.freelancer_name}</div>
                                                        <div className="ratings-reviews">
                                                            <Rate disabled tooltips={reply.rate_tooltips} size="large" value={reply.reviews_rating_round} />
                                                            <div className="rating-text">{reply.reviews_rating} ({reply.reviews_count})</div>
                                                        </div>
                                                    </div>
                                                    <div className="location">{reply.freelancer_city}, {reply.freelancer_country}</div>
                                                    <div className="text">{reply.review}</div>
                                                </div>
                                                <div className="added-block">
                                                    <span>
                                                        <i className="ico clock"></i>
                                                        <span className="num">{reply.added_on_formatted}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </>
                                    
                        :
                            null
                    }
                                                                            
                </>
            )
        }else{

            return null;
        }
    }
}

export default SingleReview;